import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import {
  getEventCategory,
  getEventSubCategory,
} from "../../../services/api/events-auth.api";
import Select from "react-select";
import Datetime from "react-datetime";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loading from "../../../common/Loading";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
function EventInfo({ InputEventHook, eventInfoDetails }) {
  const [catList, setCatList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subCatList, setSubCatList] = useState([]);
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [isSubCategoryDisabled, setIsSubCategoryDisabled] = useState(false);
  const today = new Date();
  const [approval] = useState([
    { label: "Yes", value: true },
    { label: "No", value: false },
  ]);
  const schema = yup.object().shape({
    eventTitle: yup
      .string()
      .min(5, "Event title Must be at least 5 characters")
      .max(100, "Event title Must be at least 100 characters")
      .required("Event title is required")
      .typeError("Event title is required"),
    fromDate: yup
      .string()
      .required("From date is required")
      .typeError("From date is required"),
    toDate: yup
      .string()
      .required("To date is required")
      .nullable()
      .when("fromDate", (fromDate, schema) => {
        return (
          fromDate &&
          schema.test(
            "is-later",
            "To date must be greater than From date",
            function (value) {
              const { path, createError } = this;
              // If toDate is not provided, return no error
              if (!value) return true;

              const fromDateObj = new Date(fromDate);
              const toDateObj = new Date(value);

              // Compare the dates
              if (toDateObj <= fromDateObj) {
                return createError({
                  path,
                  message: "To date must be greater than From date",
                });
              }
              return true;
            }
          )
        );
      }),
    // eventCategoryId: yup
    //   .number()
    //   .required("Category is required")
    //   .typeError("Category is required"),
    // eventSubCategoryId: yup.number().nullable(),
    eventDescription: yup
      .string()
      .required("Event description is required")
      .min(1)
      .max(500, "Event title Must be at upto 500 characters")
      .typeError("Event description is required"),
    isAuto: yup
      .boolean()
      .required("Field is required")
      .typeError("Field is required")
      .nullable(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      isAuto: eventInfoDetails?.isAuto || false,
    },
  });

  const yesterday = moment().subtract(1, "day");
  const valid = function (current) {
    return current.isAfter(yesterday);
  };
  const getCategoryList = async () => {
    try {
      const data = await getEventCategory();
      const list = data.map((option) => ({
        label: option.name,
        value: option.id,
      }));
      setCatList(list);
    } catch (error) {
      console.error(error);
    }
  };
  const getSubCatList = async (id) => {
    try {
      const subData = await getEventSubCategory(id);
      if (subData) {
        const list = subData.map((option) => ({
          label: option.name,
          value: option.id,
        }));
        setSubCatList(list);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const nextSubmit = (formData) => {
    const stepId = 1;
    setLoading(true);
    InputEventHook(formData, stepId);
  };
  const handleCategory = async (val) => {
    setValue("eventCategoryId", val);
    clearErrors("eventCategoryId");
    await getSubCatList(val);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([getCategoryList()]);
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        if (eventInfoDetails) {
          const from = moment(eventInfoDetails.fromDate, moment.ISO_8601, true);
          const to = moment(eventInfoDetails.toDate, moment.ISO_8601, true);
          if (from.isValid() && to.isValid()) {
            setSelectedFromDate(from.toDate()); // Convert to JavaScript Date for DatePicker
            setSelectedToDate(to.toDate());
          } else {
            console.error("Invalid fromDate or toDate in eventInfoDetails");
          }
          getSubCatList(eventInfoDetails.eventCategoryId);
          const fields = [
            "eventTitle",
            "fromDate",
            "toDate",
            "eventCategoryId",
            "eventSubCategoryId",
            "eventDescription",
            "isAuto",
          ];
          fields.forEach((field) => setValue(field, eventInfoDetails[field]));
        }
      }
    };
    fetchData();
  }, [eventInfoDetails]);

  const formatDate = (date) => {
    return moment(date).format("DD MMM YYYY hh:mm A");
  };

  const handleFromDateChange = (date) => {
    if (date && moment(date).isValid()) {
      const formattedDate = formatDate(date);
      setValue("fromDate", formattedDate);
      setSelectedFromDate(date);
      clearErrors("fromDate");
      trigger("fromDate");
    } else {
      console.error("Invalid date selected");
    }
  };

  const handleToDateChange = (date) => {
    if (date && moment(date).isValid()) {
      const formattedDate = formatDate(date);
      setValue("toDate", formattedDate);
      setSelectedToDate(date);
      clearErrors("toDate");
      trigger("toDate");
    } else {
      console.error("Invalid date selected");
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit(nextSubmit)}>
        <div id="pills-tab1">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="create-event-tab">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label input-label">
                        Event Title
                        <sup className="req">*</sup>
                      </label>
                      <input
                        type="text"
                        {...register("eventTitle")}
                        className="form-control"
                        placeholder="French Cup Cake Workshop with a Masterchef in his Private Atelier"
                      />
                      {errors?.eventTitle && (
                        <span className="form-error">
                          {errors.eventTitle.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="mb-3">
                      <label className="form-label input-label">
                        From Date
                        <sup className="req">*</sup>
                      </label>
                      <DatePicker
                        selected={selectedFromDate}
                        onChange={handleFromDateChange}
                        showTimeSelect
                        timeIntervals={15}
                        dateFormat="dd MMM yyyy hh:mm aa"
                        timeInputLabel="Edit Time:"
                        className="form-control "
                        wrapperClassName="react-datepicker__wrapper"
                        minDate={today}
                      />
                      <input type="hidden" {...register("fromDate")} />
                      {errors?.fromDate && (
                        <span className="form-error">
                          {errors.fromDate.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="mb-3">
                      <label className="form-label input-label">To Date</label>
                      <DatePicker
                        selected={selectedToDate}
                        onChange={handleToDateChange}
                        showTimeSelect
                        timeIntervals={15}
                        dateFormat="dd MMM yyyy hh:mm aa"
                        timeInputLabel="Edit Time:"
                        className="form-control"
                        wrapperClassName="react-datepicker__wrapper"
                        minDate={today}
                      />
                      <input type="hidden" {...register("toDate")} />
                      {errors?.toDate && (
                        <span className="form-error">
                          {errors.toDate.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label className="form-label input-label">
                        Enable Approval for Guests booking?
                      </label>
                      <p className="guest__Label">
                        if you enable Approval for Guests, you get to pick and
                        choose the people for your meeting. This option is
                        recommended for first time hosters and also for those
                        hosting at their own private spaces
                      </p>
                      <div className="radio-btn">
                        <ButtonGroup className="mb-2 d-flex align-items-center gap-3">
                          {approval.map((item, index) => (
                            <ToggleButton
                              key={index}
                              id={`radio-${index}`}
                              type="radio"
                              variant="outline-primary toggle-btn"
                              name="isAuto"
                              value={item.value}
                              {...register("isAuto")}
                              checked={watch("isAuto") === item.value}
                              onClick={() => setValue("isAuto", item.value)}
                            >
                              {item.label}
                            </ToggleButton>
                          ))}
                        </ButtonGroup>
                        {errors?.isAuto && (
                          <span className="form-error">
                            {errors.isAuto.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label className="form-label input-label">
                        Event Description
                        <sup className="req">*</sup>
                      </label>
                      <textarea
                        className="form-control textarea-ht"
                        {...register("eventDescription")}
                        placeholder="Jean-Yves is a professional chef..."
                        id="floatingTextarea2"
                      ></textarea>
                      {errors?.eventDescription && (
                        <span className="form-error">
                          {errors.eventDescription.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="submit-btn next-btn justify-content-end">
                <div className="submit-btn next-btn text-end">
                  <div>
                    <button className="btn" type="submit">
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {loading && <Loading />}
    </>
  );
}

export default EventInfo;
