import React from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "../events.scss";
import { GoAlert } from "react-icons/go";
import { useNavigate } from "react-router-dom";

function LoginModal(props) {
  const navigate = useNavigate();
  const redirectToLogin = () => {
    navigate("/login");
    props.onHide();
  };
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <div className="row delete-section">
          <div className="booked-section">
            <div className="icon-wrapper mb-2">
              <GoAlert />
            </div>
            <p className="text-muted mb-0">Please login to book an event!</p>
          </div>
          <div className="col-12">
            <div className="text-center submit-btn w-100 align-items-center d-flex gap-2 mt-2 justify-content-center create-event-modal-btn">
              <Button onClick={redirectToLogin} className="btn-login">
                Login
              </Button>
              <Button onClick={() => props.onHide()} className="btn-delete">
                Close
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default LoginModal;
