import React, { useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Loading from "../../../common/Loading";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
function BookingInfo({ prevHook, InputEventHook, eventInfoDetails }) {
  console.log(eventInfoDetails, "eventInfoDetails");
  const [loading, setLoading] = useState(false);
  const [waitingCount, setWaitingCount] = useState(""); // Managing input value
  const [waitingValue, setWaitingValue] = useState();
  const [isWaitingVisible, setIsWaitingVisible] = useState(false);
  const [guestvalue, setGuestValue] = useState();
  const [isGuestVisible, setIsGuestVisible] = useState(false);

  const radios = [
    { name: "yes", value: true },
    { name: "no", value: false },
  ];
  const toggleVisibility = (value) => {
    setWaitingValue(value);
    setValue("enableWaitingList", value);
    if (value === false) {
      setValue("waitingCount", 0);
    }
  };
  const guestVisibility = (value) => {
    setGuestValue(value);
    setValue("isEnableCost", value);
  };
  const prev = () => {
    prevHook();
  };
  const schema = yup.object().shape({
    numberOfGuests: yup
      .number()
      .nullable()
      .required("Number of guest is required")
      .typeError("Number of guest is required"),
    waitingCount: yup
      .number()
      .nullable()
      .required("waiting count is required")
      .typeError("waiting count is required"),
    gustPerCost: yup
      .number()
      .nullable()
      .when("postPay", {
        is: true,
        then: yup
          .number()
          .required("Cost is required")
          .typeError("Cost is required"),
        otherwise: yup.number().nullable(),
      }),
    postPay: yup.boolean(),
    enableWaitingList: yup.boolean(),
    isEnableCost: yup.boolean(),
  });
  const [payment] = useState([
    { label: "Prepaid", value: true },
    { label: "Pay at the event", value: false },
  ]);
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      postPay: eventInfoDetails?.postPay || false,
      enableWaitingList: eventInfoDetails?.enableWaitingList || false,
      isEnableCost: eventInfoDetails?.isEnableCost || false,
    },
  });

  const nextSubmit = (formData) => {
    setLoading(true);
    const stepId = 2;
    console.log(formData, "formData");
    InputEventHook(formData, stepId);
  };

  useEffect(() => {
    if (eventInfoDetails) {
      const fields = [
        "numberOfGuests",
        "waitingCount",
        "gustPerCost",
        "postPay",
        "enableWaitingList",
        "isEnableCost",
      ];
      fields.forEach((field) => setValue(field, eventInfoDetails[field]));
    }
  }, [eventInfoDetails]);
  const postPay = watch("postPay");
  return (
    <>
      <form onSubmit={handleSubmit(nextSubmit)}>
        <div id="pills-tab2">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="create-event-tab">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="mb-3">
                      <label className="form-label input-label">
                        Number Of Guest
                        <sup className="req">*</sup>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        {...register("numberOfGuests")}
                        placeholder="Enter number of guests"
                      />
                      {errors?.numberOfGuests && (
                        <span className="form-error">
                          {errors.numberOfGuests.message}
                        </span>
                      )}
                    </div>
                  </div>

                  {/* 1 */}
                  <div className="d-flex align-items-start  col-lg-4 flex-column">
                    <label className="form-label input-label">
                      Enable Waiting List ?
                    </label>
                    <ButtonGroup className="mb-2 gap-3">
                      {radios.map((radio, idx) => (
                        <ToggleButton
                          key={idx}
                          id={`radio-${idx}`}
                          type="radio"
                          style={{
                            backgroundColor:
                              waitingValue === radio.value
                                ? "#ff7903"
                                : "transparent",
                            color:
                              waitingValue === radio.value
                                ? "#ffffff"
                                : "#000000",
                            borderColor: "#ff7903",
                          }}
                          name="waitingRadio"
                          value={radio.value}
                          {...register("enableWaitingList")}
                          checked={waitingValue === radio.value}
                          onClick={() => toggleVisibility(radio.value)}
                          variant="outline-primary toggle-btn"
                        >
                          {radio.name}
                        </ToggleButton>
                      ))}
                    </ButtonGroup>
                  </div>
                  <div className="col-lg-4">
                    {waitingValue && (
                      <div>
                        <div className="mb-3">
                          <label className="form-label input-label">
                            Waiting Count
                            <sup className="req">*</sup>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            {...register("waitingCount")}
                            placeholder="Enter Waiting Count"
                          />
                          {errors?.waitingCount && (
                            <span className="form-error">
                              {errors.waitingCount.message}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  {/* 2 */}
                  {/* new ui Toggle Button */}
                  <div className="mb-3 col-lg-4 d-flex align-items-start flex-column">
                    <label className="form-label input-label">
                      Is there a cost per guest?
                    </label>
                    <ButtonGroup className="mb-2 gap-3">
                      {radios.map((radio, idx) => (
                        <ToggleButton
                          key={idx}
                          id={`radio-${idx}`}
                          type="radio"
                          style={{
                            backgroundColor:
                              guestvalue === radio.value
                                ? "#ff7903"
                                : "transparent",
                            color:
                              guestvalue === radio.value
                                ? "#ffffff"
                                : "#000000",
                            borderColor: "#ff7903",
                          }}
                          variant="outline-primary toggle-btn"
                          name="guestRadio"
                          {...register("isEnableCost")}
                          value={radio.value}
                          checked={guestvalue === radio.value}
                          onClick={() => guestVisibility(radio.value)}
                        >
                          {radio.name}
                        </ToggleButton>
                      ))}
                    </ButtonGroup>
                  </div>
                  <div className="col-lg-4">
                    {guestvalue && (
                      <div>
                        <div className="mb-3">
                          <label className="form-label input-label">
                            Pay As You Go With
                          </label>
                          <div className="radio-btn">
                            <ButtonGroup className="mb-2 gap-3">
                              {payment.map((item, index) => (
                                <ToggleButton
                                  key={index}
                                  id={`radio-${index}`}
                                  type="radio"
                                  variant="outline-primary toggle-btn"
                                  name="radioPostPay"
                                  value={item.value}
                                  {...register("postPay")}
                                  checked={watch("postPay") === item.value}
                                  onClick={() =>
                                    setValue("postPay", item.value)
                                  }
                                >
                                  {item.label}
                                </ToggleButton>
                              ))}
                            </ButtonGroup>
                            {/* {payment.map((item, index) => (
                            <div className="form-check" key={index}>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="postPay"
                                {...register("postPay")}
                                checked={watch("postPay") === item.value}
                                onChange={() => setValue("postPay", item.value)}
                                value={item.value}
                                id={`flexRadio${index}`}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`flexRadio${index}`}
                              >
                                {item.label}
                              </label>
                            </div>
                          ))} */}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-4">
                    {postPay ? (
                      <div>
                        <div className="mb-3">
                          <label className="form-label input-label">
                            Cost Per Guest ({eventInfoDetails?.currency})
                            <sup className="req">*</sup>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            {...register("gustPerCost")}
                            placeholder="Enter cost"
                          />
                          {errors?.gustPerCost && (
                            <span className="form-error">
                              {errors.gustPerCost.message}
                            </span>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="submit-btn next-btn justify-content-end">
                <div className="submit-btn next-btn text-end">
                  <div>
                    <button className="prev-arrow" onClick={prev}>
                      <FaArrowLeftLong />
                    </button>
                  </div>
                  <div>
                    <button className="btn" type="submit">
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {loading && <Loading />}
    </>
  );
}

export default BookingInfo;
