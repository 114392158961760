import React, { useEffect, useState } from "react";
import { Button, Modal, NavDropdown } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

function HostTypeModal(props) {
  const [applicationStatus, setApplicationStatus] = useState([]);
  const [applicationValue, setApplicationValue] = useState("");
  const status = [
    { value: 1, label: "Start a fresh application" },
    { value: 2, label: "Resume the application" },
  ];
  useEffect(() => {
    setApplicationStatus(status);
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const submitApplication = (data) => {
    props.selectedOption(data.applicationStatusId);
    props.onHide();
  };

  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <form onSubmit={handleSubmit(submitApplication)}>
            <div className="col-12">
              <div className="mb-3">
                {/* <label className="form-label input-label">
                  languages you speak
                </label> */}
                <h1 className="host-title">What Would You Like to Do?</h1>
                <div className="selectbox-block">
                  <Controller
                    defaultValue={applicationValue?.value || 1}
                    className="form-select form-control"
                    name="applicationStatusId"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={applicationStatus}
                        value={applicationStatus.find(
                          (option) => option.value === field.value
                        )}
                        defaultValue={applicationStatus.find(
                          (option) => option.value === 1
                        )}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption?.value);
                          setApplicationValue(selectedOption?.value);
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end">
              <Button type="submit" className="btn-host">
                Submit
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default HostTypeModal;
