import { createSlice } from "@reduxjs/toolkit";

const userMailSlice = createSlice({
  name: "userMail",
  initialState: {
    value: "",
    userId: "",
  },
  reducers: {
    setEmail: (state, action) => {
      state.value = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
  },
});

export const { setEmail } = userMailSlice.actions;
export const { setUserId } = userMailSlice.actions;

export default userMailSlice.reducer;
