import React from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "../events.scss";
import { FaRegCheckCircle } from "react-icons/fa";

function SuccessPaymentModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="row delete-section">
          <div className="col-lg-12">
            <div className="booked-section">
              <FaRegCheckCircle className="success" />
              <p>
                Your booking has been successfully approved. Thank you for
                choosing us. We look forward to providing you with an
                exceptional experience
              </p>
            </div>
          </div>
          <div className="col-12">
            <div className="text-center submit-btn w-100 d-flex mt-2 justify-content-center create-event-modal-btn">
              <Button onClick={() => props.onHide()} className="delete-btn">
                Close
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SuccessPaymentModal;
