import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
 
const containerStyle = {
  width: '100%',
  height: '560px',
};
 
const Map = ({latitude,longitude, zoom,address}) => {
  const [coordinates, setCoordinates] = useState(null);
  const [loading, setLoading] = useState(true);
  const libraries = ["places"];
  const options = { componentRestrictions: { country: ['in', 'pl'] } };
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyBZw1zL4TH3Kzt0eEbt5YQ1wLY0THVk4pc',
    libraries
  });

  useEffect(() => {
    const geoCoordinates = async (latitude,longitude) => {
       await setCoordinates({
            lat: latitude,
            lng: longitude
        });
        setLoading(false);
    };
    const geocodeAddress = async (address) => {
        const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=AIzaSyBZw1zL4TH3Kzt0eEbt5YQ1wLY0THVk4pc`;
 
        try {
          const response = await fetch(geocodeUrl);
          const data = await response.json();
          if (data.status === 'OK') {
            const location = data.results[0].geometry.location;
            setCoordinates(location);
            setLoading(false);
          } else {
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
        }
      };
    if (latitude && longitude) {
      setLoading(true);
      geoCoordinates(latitude,longitude);
    }else{
        setLoading(true);
        geocodeAddress(address);
    }
  }, [latitude,longitude,address]);
 
  const onUnmount = useCallback(() => {
    setCoordinates(null);
  }, []);
 
  const onLoad = useCallback(
    (map) => {
      if (coordinates) {
        map.panTo(coordinates);
      }
    },
    [coordinates]
  );
 
  const memoizedMap = useMemo(() => {
    return (
      coordinates && (
        <GoogleMap
          onLoad={onLoad}
          mapContainerStyle={containerStyle}
          center={coordinates}
          zoom={zoom}
          onUnmount={onUnmount}
        >
          <MarkerF
            position={coordinates}
            icon={{ url: 'https://meet-at-table-admin.scopethinkers.com/HTML/image/map-marker.png' }}
          />
        </GoogleMap>
      )
    );
  }, [coordinates, zoom, onLoad, onUnmount]);
 
  if (!isLoaded || loading) {
    return <p>Loading map...</p>;
  }
 
  return <>{memoizedMap}</>;
};
 
export default Map;