import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FaCheck } from "react-icons/fa6";
import Button from "react-bootstrap/Button";
import "../edit-event.scss";
import { cancelBooking } from "../../../../services/api/events-auth.api";
import Toaster from "../../../../common/Toaster";

function CancelBookingModal(props) {
  const [reason, setReason] = useState("");
  const handleChange = async (e) => {
    setReason(e.target.value);
  };
  const handleSubmit = async () => {
    const payload = {
      bookingId: props.data.id,
      email: props.data.email,
      reason: reason || "",
    };
    try {
      const res = await cancelBooking(payload);
      Toaster("Booking cancelled successfully", "success");
      props.onHide();
      props.onConfirm();
    } catch (error) {
      Toaster(error.response.data, "error");
    }
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="row delete-section px-5 py-3 cancel-booking">
          <div className="col-lg-3">
            <label>Reject Reason :</label>
          </div>
          <div className="col-lg-9">
            <textarea
              type="text"
              className="form-control"
              placeholder="Enter the reason....."
              onChange={handleChange}
            />
          </div>
          <div className="col-12 mt-4">
            <div className="text-center submit-btn w-100 d-flex justify-content-center create-event-modal-btn">
              <Button onClick={() => props.onHide()} className="delete-btn">
                Cancel
              </Button>
              <Button className="btn" onClick={handleSubmit}>
                Send Email
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CancelBookingModal;
