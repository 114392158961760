import React, { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  login,
  getHostDetails,
  getCheckProfileStatus,
} from "../../services/api/user-auth.api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  saveAccessToken,
  saveHostId,
  addEmail,
  googleRes,
} from "../../util/auth";
import img1 from "../../assets/image/sign-in-bg.png";
import img5 from "../../assets/image/instagram-icon.png";
import logo from "../../assets/image/logo-dark.svg";
import { PiEyeClosedLight } from "react-icons/pi";
import { PiEyeThin } from "react-icons/pi";
import Loading from "../../common/Loading";
import Toaster from "../../common/Toaster";
import { AiOutlineHome } from "react-icons/ai";
import "../login/login.scss";
import { useDispatch } from "react-redux";
import { setEmail } from "../../redux/slice/userMail";
import { setLogout } from "../../redux/slice/enableLogoutSlice";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { getGoogleAuth } from "../../../src/services/api/user-auth.api";
import FacebookLogin from "react-facebook-login";
import AuthProfileModal from "../modal/authProfile";
import { setGoogleLogin, setFacebookLogin } from "../../redux/slice/tokenSlice";
import TermsConditionModal from "../modal/TermsConditionModal";
import { setLoginToken } from "../../redux/slice/tokenSlice";
function Login() {
  const navigate = useNavigate();
  const [showPswd, setShowPswd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [loginData, setLoginData] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();

  let schema = yup.object().shape({
    email: yup.string().required("Email or Username is required"),
    password: yup.string().required("Password is required"),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const loginSubmit = async (formData) => {
    setIsLoading(true);
    try {
      const data = await login(formData);
      await setLoginData(data);
      if (data.acceptedTermsAndConditions === false) {
        await saveAccessToken(data.token);
        setShowModal2(true);
      } else {
        loginUpdate(data);
      }
    } catch (error) {
      const errorMessage =
        typeof error?.response?.data === "string"
          ? error?.response?.data
          : JSON.stringify(error?.response?.data);
      Toaster(errorMessage, "error");
    } finally {
      setIsLoading(false);
    }
  };
  const loginUpdate = async (data) => {
    dispatch(setLogout(false));
    await addEmail(data?.email);
    dispatch(setEmail(data?.email));
    reset();
    await saveAccessToken(data.token);
    // await getHost();
    Toaster("Login successfully", "success");
    navigate("/");
  };

  const getHost = async () => {
    try {
      const hostData = await getHostDetails();
      if (hostData) {
        await saveHostId(hostData.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkProfile = async () => {
    try {
      const status = await getCheckProfileStatus();
      if (status) {
        dispatch(setGoogleLogin(true));
        navigate("/");
      } else {
        await setShowModal(true);
      }
    } catch (error) {}
  };

  const handleGoogleLogin = async (credentialResponse) => {
    const user = jwtDecode(credentialResponse.credential);
    const email = user.email;
    const userName = user.given_name;
    try {
      const res = await getGoogleAuth(email, userName);
      await saveAccessToken(res.token);
      await googleRes(res);
      await checkProfile();
    } catch (error) {
      Toaster("Login failed,Please try again", "error");
    }
  };
  const handleFacebookLogin = async (response) => {
    const { email, name } = response;
    try {
      const res = await getGoogleAuth(email, name);
      await saveAccessToken(res.token);
    } catch (error) {
      console.log(error, "error");
      Toaster("Login failed,Please try again", "error");
    }
  };
  const handleLoginError = (error) => {
    console.log("Login Failed:", error);
  };

  const approvedLogin = async (approved) => {
    if (approved) {
      loginUpdate(loginData);
    }
  };

  return (
    <>
      <div className="login-wrapper">
        <form onSubmit={handleSubmit(loginSubmit)}>
          <div className="container-fluid sign-in">
            <div className="sign-in-bg">
              {/* <img src={img1} alt="meet-at-table" /> */}
            </div>
            <div className="container h-100 z-1 position-relative">
              <div className="row sign-in-row h-100">
                <div className="col-lg-8 col-xl-6 mx-auto">
                  <div className="row">
                    <div className="col-md-10 mx-auto">
                      <div className="sign-in-form">
                        <div className="mb-3 form-title d-flex align-items-center justify-content-between">
                          <p className="fw-bold  login-title">Login</p>
                          <Link to="/">
                            <AiOutlineHome className="home-icon" />
                          </Link>
                        </div>
                        <div>
                          <div className="mb-1">
                            <label className="form-label input-label">
                              Your email or username{" "}
                              <sup className="req">*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Email / username"
                              {...register("email")}
                            />
                            {errors?.email && (
                              <span className="form-error">
                                {errors.email.message}
                              </span>
                            )}
                          </div>
                          <div className="mb-1">
                            <label className="form-label input-label">
                              Password <sup className="req">*</sup>
                            </label>
                            <div className="position-relative">
                              <input
                                type={showPswd ? "text" : "password"}
                                className="form-control"
                                placeholder="Password"
                                {...register("password")}
                              />
                              {errors?.password && (
                                <span className="form-error">
                                  {errors.password.message}
                                </span>
                              )}
                              <div
                                className="password-icon"
                                onClick={() => setShowPswd(!showPswd)}
                              >
                                {!showPswd && <PiEyeClosedLight />}
                                {showPswd && <PiEyeThin />}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end my-2 align-items-center flex-wrap">
                          <div className="forgot-txt">
                            <p className="mb-0">
                              <Link
                                to="/forgot-password"
                                className="forgot-link form__check"
                              >
                                Forgot your password?
                              </Link>
                            </p>
                          </div>
                        </div>
                        <div>
                          <button className="btn sign-in-btn" type="submit">
                            Login
                          </button>
                        </div>
                        {/* Divider */}
                        <div className="my-3 d-flex align-items-center">
                          <div className="divide-border"></div>
                          <div className="divide-text">OR</div>
                          <div className="divide-border"></div>
                        </div>
                        <div className="d-flex justify-content-center flex-column flex-md-row align-items-center gap-md-3">
                          {/* New google btn */}
                          <div className="mb-4 d-flex justify-content-center">
                            <GoogleLogin
                              // width="353px"
                              onSuccess={handleGoogleLogin}
                              onError={handleLoginError}
                              redirectUri="https://meet-at-table.scopethinkers.com"
                            />
                          </div>
                          <div className="mb-4 d-flex justify-content-center facebook-btn">
                            <FacebookLogin
                              appId="974545881214342"
                              autoLoad={false}
                              fields="name,email,picture"
                              callback={handleFacebookLogin}
                              onFailure={handleLoginError}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <AuthProfileModal show={showModal} onHide={() => setShowModal(false)} />
      <TermsConditionModal
        show={showModal2}
        onHide={() => setShowModal2(false)}
        accepted={approvedLogin}
      />
      {isLoading && <Loading />}
    </>
  );
}

export default Login;
