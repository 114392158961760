import React, { useEffect, useState } from "react";
import { MdStar, MdStarBorder } from "react-icons/md";

import ratingProfile from "../../../assets/image/rating-profile.png";
import calender from "../../../assets/image/calender.png";
import Select from "react-select";
import {
  getHostReviews,
  isBookedEvent,
} from "../../../services/api/events-auth.api";
import { useParams } from "react-router-dom";
import ReviewModal from "../modal/ReviewModal";
import { getAccessToken } from "../../../util/auth";
import Toaster from "../../../common/Toaster";
import Pagination from "../../../common/Pagination";
import { useMemo } from "react";
import ReviewsGallery from "./ReviewsGallery";

function Reviews({ eventDetails, reviewsRefresh }) {
  const [showModal, setShowModal] = useState(false);
  const [contentShow, setContentShow] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isBook, setIsBook] = useState(false);
  const [isPastEvent, setIsPastEvent] = useState();
  const [expandedReviews, setExpandedReviews] = useState({});

  const toggleContentShow = (index) => {
    setExpandedReviews((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  const handleOpen = () => {
    if (getAccessToken()) {
      setShowModal(true);
    } else {
      Toaster("Please login to write a reviews", "error");
    }
  };
  const handleClose = () => setShowModal(false);
  const { id } = useParams();
  const ReviewItems = [
    { label: "Oldest first", value: "oldestfirstt" },
    { label: "Highest rating", value: "highestrating" },
    { label: "Lowest rating", value: "lowestrating" },
  ];
  const [reviewPayload, setReviewPayload] = useState({
    eventId: id,
    sort: "",
    pageId: 1,
    pageSize: 6,
  });
  const maxRating = 5;
  const [reviews, setReviews] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const transformDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "long",
      year: "numeric",
    });
    return formattedDate;
  };
  const handleFilter = (option) => {
    setReviewPayload((prev) => ({
      ...prev,
      sort: option.value,
    }));
  };
  const getReviews = async () => {
    try {
      reviewPayload.hostId = eventDetails.hostId;
      const res = await getHostReviews(reviewPayload);
      setTotalCount(res.eventCount);
      setReviews(res.eventsViewModel);
    } catch (error) {
      console.log(error);
    }
  };

  const capitalize = (str) => {
    if (!str || str.length < 2) return str.toUpperCase();
    return str.substring(0, 2).toUpperCase();
  };
  useEffect(() => {
    getReviews();
    // HostReviews();
  }, [id, reviewPayload, eventDetails]);
  useEffect(() => {
    const eventDate = new Date(eventDetails?.fromDate);
    const currentDate = new Date();
    if (eventDate < currentDate) {
      setIsPastEvent(true);
    } else {
      setIsPastEvent(false);
    }
    isEventBooked(id);
  }, [id, eventDetails]);
  const isEventBooked = async (id) => {
    try {
      const res = await isBookedEvent(id);
      setIsBook(res);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    isEventBooked(id);
  }, [id]);
  return (
    <>
      <div className="position-relative">
        <div className="container">
          <div className="row g-3">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center wht-expt flex-wrap">
                <div>
                  <h4>What to expect</h4>
                  <div>
                    {reviews.length > 0 && (
                      <div>
                        <div className="d-flex justify-content-between expt-revw align-items-center">
                          <div className="expt-revw-num mt-auto">
                            <p className="mb-0">5</p>
                          </div>
                          <div className="mt-auto revw-rat">
                            <p className="mb-0">Out of 5</p>
                            <p className="mb-0">(94 reviews)</p>
                          </div>
                          <div className="mt-auto">
                            <MdStar color="white" />
                            <MdStar color="white" />
                            <MdStar color="white" />
                            <MdStar color="white" />
                            <MdStar color="white" />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex gap-1 align-items-center flex-wrap">
                  {reviews.length > 0 ? (
                    <div className="review-dropdown">
                      <Select
                        options={ReviewItems}
                        placeholder="Newest"
                        onChange={(selectedOption) =>
                          handleFilter(selectedOption)
                        }
                      />
                    </div>
                  ) : null}
                  {isBook && isPastEvent && (
                    <div className="bkng-con-btn ">
                      <button
                        className="btn m-0 review-btn"
                        onClick={handleOpen}
                      >
                        Write a Review
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {reviews.length > 0 ? (
              reviews.map((item, index) => {
                const content = item.reiviewsDescripition || "";
                const contentLength = content.length;
                const isExpanded = expandedReviews[index] || false;
                return (
                  <div className="col-12 col-sm-6 col-xl-4" key={index}>
                    <div className="see-menu-sec">
                      <div className="d-flex justify-content-between align-items-center see-menu-sec-header">
                        <div className="d-flex">
                          <div className="rating-profile rating2-profile">
                            {/* <img src={ratingProfile} alt="meet-at-table" /> */}
                            <div className="blank-image-text">
                              <span>{capitalize(item.userName)}</span>
                            </div>
                          </div>
                          <div className="test-ratng ms-3">
                            <p className="mb-0">{item.userName}</p>
                            <div className="rating-stars">
                              {[...Array(maxRating)].map((_, index) => (
                                <span key={index}>
                                  {index < item.totalReviews ? (
                                    <MdStar color="orange" size={24} />
                                  ) : (
                                    <MdStarBorder color="orange" size={24} />
                                  )}
                                </span>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex ratng-date align-items-center">
                          <div className="evnt-feat-img">
                            <img src={calender} alt="meet-at-table" />
                          </div>
                          <div className="mt-auto">
                            <p>{transformDate(item.createdDate)}</p>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div>
                        {contentLength < 120 ? (
                          <p className="text-break">{content}</p>
                        ) : !isExpanded ? (
                          <>
                            <p className="mb-0 text-break">
                              {content.substring(0, 120)}...
                            </p>
                            <p
                              className="read-more-link"
                              onClick={() => toggleContentShow(index)}
                            >
                              Read More
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="text-break">{content}</p>
                            <p
                              className="read-more-link"
                              onClick={() => toggleContentShow(index)}
                            >
                              Read Less
                            </p>
                          </>
                        )}
                      </div>
                      {item.reviewDocuments.length > 0 && (
                        <div>
                          <ReviewsGallery review={item.reviewDocuments} />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="empty-reviews">
                <p className="not-found not__found">Reviews not found</p>
              </div>
            )}
            {reviews.length > 0 && (
              <Pagination
                className="pagination-bar"
                currentPage={reviewPayload.pageId}
                totalCount={totalCount}
                pageSize={reviewPayload.pageSize}
                onPageChange={(page) =>
                  setReviewPayload((prev) => ({ ...prev, pageId: page }))
                }
              />
            )}
            <ReviewModal
              show={showModal}
              onHide={() => setShowModal(false)}
              onConfirm={reviewsRefresh}
              event={eventDetails}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Reviews;
