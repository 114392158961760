import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../events.scss";
import { IoClose } from "react-icons/io5";
import { MdOutlineStar } from "react-icons/md";
import StarRating from "../../../common/StarRating";
import {
  createEventReviews,
  uploadReviewsDoc,
} from "../../../services/api/events-auth.api";
import { getEventId } from "../../../util/auth";
import { useParams } from "react-router-dom";
import Toaster from "../../../common/Toaster";
import { v4 as uuidv4 } from "uuid";
import { FaRegTrashCan } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
function ReviewModal(props) {
  const { id } = useParams();
  const [valueOfMoney, setValueOfMoney] = useState(0);
  const [food, setFood] = useState(0);
  const [atmosphere, setAtmosphere] = useState(0);
  const [cleanliness, setCleanliness] = useState(0);
  const [reiviewsDescripition, setReviewsDescription] = useState("");
  const docUploadRef = useRef(null);
  const [eventDocs, setEventDocs] = useState([]);
  const [storeDocs, setStoreDocs] = useState([]);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const resetForm = () => {
    setValueOfMoney(0);
    setFood(0);
    setAtmosphere(0);
    setCleanliness(0);
    setReviewsDescription("");
    setMessage("");
    setStoreDocs([]);
    setEventDocs([]);
  };

  const countWords = (text) => {
    return text.trim().split(/\s+/).filter(Boolean).length;
  };

  const handleDescriptionChange = (e) => {
    const newDesc = e.target.value;
    const wordCount = countWords(newDesc);
    if (wordCount <= 200) {
      setReviewsDescription(newDesc);
      setError("");
    } else {
      setError("Review cannot exceed 200 words!");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      valueOfMoney === 0 ||
      food === 0 ||
      atmosphere === 0 ||
      cleanliness === 0 ||
      reiviewsDescripition === ""
    ) {
      setMessage("Please rate all categories before submitting.");
      return;
    }

    try {
      const payload = {
        valueOfMoney,
        food,
        atmosphere,
        cleanliness,
        reiviewsDescripition,
        eventId: id,
        hostId: props.event.hostId,
      };
      const res = await createEventReviews(payload);
      await handleUpload(res);
      if (eventDocs.length === 0) {
        props.onHide();
        resetForm();
        props.onConfirm();
      }
      Toaster("Reviews added successfully", "success");
    } catch (err) {
      console.error(err);
      setMessage("Failed to submit your review. Please try again.");
      return Toaster(err.response.data, "error");
    }
  };
  useEffect(() => {
    resetForm();
  }, [props]);

  const handleDocUpload = (event) => {
    event.preventDefault();
    docUploadRef.current.click();
  };
  const uploadDocDisplay = async () => {
    const files = Array.from(docUploadRef.current.files);
    const validFiles = [];
    const cachedURLs = [];

    for (const file of files) {
      if (file.size > 1024 * 1024) {
        Toaster("File size should not exceed 1 MB.", "error");
      } else {
        const fileData = {
          file: file,
          id: uuidv4(),
        };
        validFiles.push(fileData);
        cachedURLs.push({
          url: URL.createObjectURL(file),
          type: "preview",
          id: fileData.id,
        });
      }
    }
    setEventDocs((prevState) => [...prevState, ...cachedURLs]);
    setStoreDocs((prevState) => [...prevState, ...validFiles]);
  };

  const handleUpload = async (reviewId) => {
    if (eventDocs.length === 0) {
      Toaster(
        "Please upload at least one document before proceeding.",
        "warning"
      );
      return;
    }
    if (eventDocs.length <= 50) {
      const formData = new FormData();
      storeDocs.forEach((data) => {
        formData.append(`eventDocuments`, data.file);
      });

      try {
        await uploadReviewsDoc(formData, id, reviewId);
        Toaster("Photos uploaded successfully", "success");
        setEventDocs([]);
        setStoreDocs([]);
        props.onHide();
        resetForm();
        props.onConfirm();
      } catch (error) {
        Toaster("Creation failed", "error");
      }
    } else {
      return Toaster("Upload maximum of 50 photos", "error");
    }
  };

  const removeDocument = async (doc) => {
    setEventDocs((prev) => prev.filter((item) => item.id !== doc.id));
    setStoreDocs((prev) => prev.filter((item) => item.id !== doc.id));
  };

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vright"
        centered
        className="view-menu-modal "
      >
        <Modal.Header closeButton>
          <Modal.Title>Rate your experience</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message && <p className="form-error">{message}</p>}
          <form onSubmit={handleSubmit}>
            <div className="view-menu-modal-body">
              <div className="">
                <StarRating
                  category="Value of Money"
                  selectedStar={valueOfMoney}
                  setSelectedStar={setValueOfMoney}
                />
                <StarRating
                  category="Food"
                  selectedStar={food}
                  setSelectedStar={setFood}
                />
                <StarRating
                  category="Atmosphere"
                  selectedStar={atmosphere}
                  setSelectedStar={setAtmosphere}
                />
                <StarRating
                  category="Cleanliness"
                  selectedStar={cleanliness}
                  setSelectedStar={setCleanliness}
                />
                <label>Review Description</label>
                <div className="mt-3">
                  <textarea
                    className="form-control p-3 rounded-2"
                    value={reiviewsDescripition}
                    onChange={handleDescriptionChange}
                    placeholder="Write your review here..."
                  ></textarea>
                  {error && (
                    <div className="text-danger mt-2 error-message">
                      {error}
                    </div>
                  )}
                </div>
              </div>
              <div className="events-wrapper-main">
                <div className="btn bkng-con-btn">
                  <button className="btn" onClick={handleDocUpload}>
                    Upload Image
                  </button>
                </div>
                <input
                  type="file"
                  ref={docUploadRef}
                  onChange={uploadDocDisplay}
                  hidden
                  multiple
                  id="event-photo-input"
                  name="event_documents"
                  accept="image/png, image/jpeg"
                />
                <div
                  className={`g-3 mt-3 review-container ${
                    eventDocs.length > 12 ? "review__container" : ""
                  }`}
                >
                  {eventDocs.map((doc, index) => (
                    <div className="position-relative review-card" key={index}>
                      <div
                        className="uploaded-review-img position-relative"
                        key={index}
                      >
                        <img src={doc.url} alt={`Uploaded ${index}`} />
                      </div>
                      <div
                        className="review-trash-icon"
                        onClick={() => removeDocument(doc)}
                      >
                        <RiDeleteBin6Line />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <Modal.Footer className="d-block p-0 mt-3">
              <div className="events-wrapper-main d-flex justify-content-center">
                <div className="btn bkng-con-btn">
                  <button className="btn">Submit</button>
                </div>
              </div>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ReviewModal;
