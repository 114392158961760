import React from "react";
import img1 from "../../assets/image/becomehost-pattern.png";
import img2 from "../../assets/image/becmehost-img.png";
import img3 from "../../assets/image/tick.png";
import "../host/host.scss";

function WelcomeHost({ nextHook }) {
  const next = () => {
    nextHook();
  };
  return (
    <div className="host-wrapper">
      <div className="host-wrapper__inner">
        <div className="become-host-sec">
          <div className="container h-100">
            {/* <div className="d-flex justify-content-end">
                            <div className="bcm-host-btn">
                                <button>Save & Exit</button>
                            </div>
                            <div className="bcm-host-btn">
                                <button>Delete my Application</button>
                            </div>
                        </div> */}
            <div className="row h-100 align-items-center gy-3 mt-2">
              <div className="col-lg-6 col-md-12 my-auto">
                <div className="becomehost-img">
                  <img src={img2} alt="meet-at-table" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="becomehost-txt">
                  <div>
                    <div>
                      <h3>
                        Ready to serve up good times? - Steps to be a Meet@Table
                        Host
                      </h3>
                      <h4>
                        Welcome! Your meet@table journey is about to start
                      </h4>
                      <p>
                        As a Meet@Table host,you'll create unforgettable moments
                        by bringing people together over great food, drinks and
                        exciting themes.Whether you're hosting a cosy lunch or a
                        lively feast,you'll make new friends and create memories
                        to cherish. Let the cook in you shine! Not much of a
                        chef or don't run a restaurant? No problem! Use your
                        awesome organizing skills to arrange the food, set the
                        scene, and step into the spotlight as the host
                      </p>
                    </div>
                    <div>
                      <h4>How to Sign Up as a Host</h4>
                      <p className="mb-0">
                        Becoming a Meet@Table host is quick and simple—just 10
                        minutes! Share a bit about yourself, your experience,
                        ideas, and some photos. Our Community Team will review
                        your application and approve it if it meets our
                        standards. If not, don’t worry — we’ll guide you with
                        tips to help you improve it. Make the updates, and we’ll
                        review it again in no time. ! Once approved, you’re
                        ready to create your first experience and start hosting!
                      </p>
                    </div>
                    <div className="next-btn d-flex justify-content-center">
                      <a type="button" onClick={next}>
                        Let's Start
                      </a>
                    </div>

                    {/* Lets start */}
                    <div className="bcm-list">
                      <p>What's in it for you?</p>
                      <div>
                        <ul>
                          <li>
                            <div className="d-flex gap-2">
                              <div className="tick-img">
                                <img src={img3} alt="meet-at-table" />
                              </div>
                              <div>
                                <p>
                                  Honey… oops we meant Money - As Meet@Table
                                  Host, you may charge the guest for your time,
                                  the food and drinks and your organising
                                  efforts. We know - No free lunches, right 🙂
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex gap-2">
                              <div className="tick-img">
                                <img src={img3} alt="meet-at-table" />
                              </div>
                              <div>
                                <p>
                                  Connect and feast with like-minded people,
                                  enjoy those table chats
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex gap-2">
                              <div className="tick-img">
                                <img src={img3} alt="meet-at-table" />
                              </div>
                              <div>
                                <p>
                                  Show off your skills - Time to shine with your
                                  hosting or cooking or both talents.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex gap-2">
                              <div className="tick-img">
                                <img src={img3} alt="meet-at-table" />
                              </div>
                              <div>
                                <p>
                                  Heal the World, make a difference one meal at
                                  a time, remember you are offering a real life
                                  experience and getting people to move out and
                                  about, reach your event and interact with
                                  others. It’s a great detox from the online
                                  world and a boost for overall well being.
                                  Bravo!
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WelcomeHost;
