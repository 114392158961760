import React, { useEffect, useState } from "react";
import img2 from "../../assets/image/badge.png";
import img3 from "../../assets/image/silver.png";
import img4 from "../../assets/image/tick.png";
import {
  getAllPlans,
  getHostDetails,
  updateHostPlan,
} from "../../services/api/user-auth.api";

function UpgradePlan() {
  const [plans, setPlans] = useState([]);
  const [hostDetail, setHostDetail] = useState();
  const handlePlan = async (plan) => {
    try {
      // const res = await updateHostPlan(hostData.id, plan.id);
    } catch (error) {}
  };
  useEffect(() => {
    const getPlans = async () => {
      try {
        const res = await getAllPlans();
        setPlans(res);
      } catch (error) {
        console.log(error);
      }
    };
    getPlans();
    getHost();
  }, []);
  const yearConversion = (day) => {
    const years = Math.floor(day / 365);
    const remainingDays = day % 365;
    const months = Math.floor(remainingDays / 30);
    const days = remainingDays % 30;
    const yearText = `${years > 0 ? years + " " + "year" : ""}`;
    const monthText = `${months > 0 ? months + " " + "month" : ""}`;
    const daysText = `${days > 0 ? days + " " + "day" : ""}`;
    return `Valid for ${yearText} ${monthText} ${daysText}`;
  };
  const getHost = async () => {
    try {
      const host = await getHostDetails();
      if (host) {
        await setHostDetail(host);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="host-wrapper">
      <div className="become-host-sec">
        <div className="container">
          <div className="row gy-5 mt-3">
            {plans.length > 0 &&
              plans.map((plan) => (
                <div className="col-lg-4 col-md-6" key={plan.id}>
                  <div
                    className={`becomehost-txt ${
                      plan?.id === hostDetail?.membershipId ? "host-active" : ""
                    }`}
                  >
                    <div className="badge">
                      <div className="badge-img">
                        <img src={img2} alt="meet-at-table" />
                        <div className="badge-dlr">
                          <p>${plan.price}</p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column h-100">
                      <div className="plan-image">
                        <img
                          src={img3}
                          alt="meet-at-table"
                          className="mw-100 mh-100"
                        />
                      </div>
                      <div className="d-flex flex-column h-100">
                        {plan.promotionalOffer !== null ? (
                          <p className="plan-offer plan__offer">
                            Promotional Offer
                          </p>
                        ) : (
                          <p className="plan-offer plan__offer invisible">
                            Test offer
                          </p>
                        )}
                        <h3
                          className={`promo__offer ${
                            plan.promotionalOffer !== null ? "strike" : ""
                          } `}
                        >
                          {plan.name}
                        </h3>
                        <div className="bcm-list">
                          <ul>
                            <li>
                              <div className="d-flex mb-3 align-items-center gap-2">
                                <div className="tick-img">
                                  <img src={img4} alt="meet-at-table" />
                                </div>
                                <div className="chk-txt">
                                  <p className="mb-0 bcm-text">
                                    {yearConversion(plan.validDays)}
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex mb-3 align-items-center gap-2">
                                <div className="tick-img">
                                  <img src={img4} alt="meet-at-table" />
                                </div>
                                <div className="chk-txt">
                                  <p className="mb-0 bcm-text">
                                    {plan.maxNumberOfGuest} Members allowed for
                                    events
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="submit-btn next-btn text-center">
                          <div>
                            <button
                              className={`btn ${
                                plan?.id === hostDetail?.membershipId
                                  ? "host-active-btn"
                                  : ""
                              }`}
                              type="submit"
                              onClick={() => handlePlan(plan)}
                            >
                              Select
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpgradePlan;
