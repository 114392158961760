import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../handpicked-events.scss";
import { getSpeakLanguages } from "../../../services/api/user-auth.api";
import MultiRangeSlider from "multi-range-slider-react";
import {
  getAllFacilities,
  getFilterEvents,
  getSearchEvents,
} from "../../../services/api/events-auth.api";
import { getCuisines } from "../../../services/api/site-info.api";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { useDispatch } from "react-redux";
import {
  advancedValues,
  advancedSearch,
  resetAdvancedSearch,
  resetBoolean,
} from "../../../redux/slice/searchSlice";

function ApplyFilterModal(props) {
  const [minValue, set_minValue] = useState(25);
  const [maxValue, set_maxValue] = useState(75);
  const [typeOfEvent, setTypeOfEvent] = useState();
  const [selectHostLanguage, setSelectHostLanguage] = useState();
  const [selectedCuisine, setSelectedCuisine] = useState();
  const [accomodations, setAccomodations] = useState([]);
  const [cusiniesList, setCusiniesList] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [activeAccomodation, setActiveAccomodation] = useState([]);
  const dispatch = useDispatch();
  const { control, reset } = useForm();

  const [searchEvents, setSearchEvents] = useState({
    PageId: 1,
    PageSize: 10,
  });

  const handleInput = (e) => {
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
    setSearchEvents((prev) => ({
      ...prev,
      StartGuestPerCast: e.minValue,
      EndGuestPerCast: e.maxValue,
    }));
  };

  const getLangs = async () => {
    try {
      const data = await getSpeakLanguages();
      setLanguages(data);
    } catch (err) {}
  };

  const getAccomodations = async () => {
    const data = await getAllFacilities();
    setAccomodations(data);
  };

  const getCusiniesList = async () => {
    const data = await getCuisines();
    if (data) {
      const list = data.map((option) => ({
        label: option.cuisineName,
        value: option.cuisineID,
      }));
      setCusiniesList(list);
    }
  };

  const onPublicEvent = (value) => {
    if (value.isTrusted) {
      setTypeOfEvent(true);
      setSearchEvents((prev) => ({
        ...prev,
        TypeOfEvent: true,
      }));
    }
  };

  const onPrivateEvent = (value) => {
    if (value.isTrusted) {
      setTypeOfEvent(false);
      setSearchEvents((prev) => ({
        ...prev,
        TypeOfEvent: false,
      }));
    }
  };

  const selectLanguage = (language) => {
    if (language) {
      setSelectHostLanguage(language);
      setSearchEvents((prev) => ({
        ...prev,
        HostLanguage: language,
      }));
    }
  };

  const selectCuisines = (cuisines) => {
    if (cuisines) {
      setSelectedCuisine(cuisines);
      setSearchEvents((prev) => ({
        ...prev,
        EventCuisine: cuisines.value,
      }));
    }
  };

  const selectAccomodations = async (accommodation, index) => {
    const updatedAccommodations = activeAccomodation.includes(index)
      ? activeAccomodation.filter((i) => i !== index)
      : [...activeAccomodation, index];
    setActiveAccomodation(updatedAccommodations);
    const selectedAccommodationIDs = updatedAccommodations.map(
      (i) => accomodations[i].id
    );
    setSearchEvents((prev) => ({
      ...prev,
      Accomodation: selectedAccommodationIDs,
    }));
  };

  const advanceSearch = async () => {
    const data = await getSearchEvents(searchEvents);
    dispatch(advancedValues(data));
    dispatch(advancedSearch(true));
    props.onHide();
  };
  useEffect(() => {
    if (searchEvents.Sort != undefined || searchEvents.Sort === null) {
      advanceSearch();
    }
  }, [searchEvents.Sort]);

  const resetFields = async () => {
    set_minValue(25);
    set_maxValue(75);
    setTypeOfEvent(null);
    setSelectHostLanguage(null);
    dispatch(advancedSearch(false));
    dispatch(resetBoolean(true));
    setSelectedCuisine(null);
    setActiveAccomodation([]);
    setSearchEvents({
      PageId: 1,
      PageSize: 10,
    });
    const allData = await getFilterEvents({
      Sort: null,
      PageId: 1,
      PageSize: 10,
      EventCategory: null,
    });
    dispatch(resetAdvancedSearch(allData));
    props.onHide();
    reset({});
  };

  useEffect(() => {
    if (props.show === true) {
      getAccomodations();
      getLangs();
      getCusiniesList();
    }
    if (
      (props.sortData != undefined && props.sortData != "") ||
      props.sortData === null
    ) {
      setSearchEvents((prev) => ({
        ...prev,
        Sort: props.sortData,
      }));
    }
  }, [props]);
  return (
    <>
      <div>
        {/* <WishlistEvents/> */}
        <Modal
          {...props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="handpicked-main-block"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <div className="d-flex justify-content-between view-menu p-0 border-0">
                <h5 className="modal-title border-0">Advance Search</h5>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0">
            <div className="view-menu-modal-body border-0">
              <div className="filter-popup-body-sec p-0">
                <div className="p-evnt-sec">
                  <div className="title-radio-btn">
                    <p>Price $</p>
                    <MultiRangeSlider
                      min={0}
                      max={100}
                      step={5}
                      minValue={minValue}
                      maxValue={maxValue}
                      onInput={(e) => {
                        handleInput(e);
                      }}
                    />
                  </div>
                </div>

                <div className="p-evnt-sec evnt-type-radio-btn">
                  <div className="title-radio-btn">
                    <p>Type of Event</p>
                  </div>
                  <div className="bcm-radio-btn-sec d-flex">
                    <div className="form-check radio-btn">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault1"
                        id="dinner"
                        onChange={onPublicEvent}
                        value={true}
                        checked={typeOfEvent === true}
                      />
                      <label className="form-check-label" htmlFor="dinner">
                        Public event
                      </label>
                    </div>
                    <div className="form-check radio-btn">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault1"
                        id="cookingclass"
                        onChange={onPrivateEvent}
                        value={false}
                        checked={typeOfEvent === false}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="cookingclass"
                      >
                        Private event
                      </label>
                    </div>
                  </div>
                </div>
                <div className="p-evnt-sec evnt-type-radio-btn">
                  <div className="title-radio-btn">
                    <p>Host Language</p>
                  </div>
                  <div className="bcm-radio-btn-sec d-flex">
                    {languages.map((data) => (
                      <div className="form-check radio-btn">
                        <>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault2"
                            id="dinner"
                            onChange={() => selectLanguage(data.languageName)}
                            checked={selectHostLanguage === data.languageName}
                          />
                          <label className="form-check-label" htmlFor="dinner">
                            {data.languageName}
                          </label>
                        </>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="p-evnt-sec evnt-type-radio-btn">
                  <div className="col-lg-4">
                    <div className="mb-3">
                      <label className="form-label input-label">
                        Food Sub Categories
                      </label>
                      <div>
                        <Controller
                          control={control}
                          name="cusiniesList"
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={cusiniesList}
                              value={selectedCuisine}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption.value);
                                selectCuisines(selectedOption);
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="p-evnt-sec mb-0">
                  <div className="title-radio-btn">
                    <p>Accomodation</p>
                  </div>
                  <div className="acm">
                    {accomodations.map((data, index) => (
                      <>
                        <div
                          className="acm-sec"
                          key={data.id}
                          onClick={() => selectAccomodations(data, index)}
                        >
                          <a
                            href="#"
                            className={
                              activeAccomodation.includes(index)
                                ? "btn active"
                                : "btn"
                            }
                          >
                            <p>{data.name}</p>
                          </a>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
              <div className="text-center se-re-btn-ch w-100 d-flex justify-content-center my-3">
                <Button
                  onClick={() => advanceSearch()}
                  className="btn search-btn"
                >
                  Search
                </Button>
                <Button
                  onClick={() => {
                    resetFields();
                  }}
                  className="btn reset-btn"
                >
                  Reset
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default ApplyFilterModal;
