import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { addWishList } from "../../../services/api/user-auth.api";
import Toaster from "../../../common/Toaster";

function WishlistModal(props) {
    const { id } = useParams();
    const [error, setError] = useState(false);
    const [wishListEvents, setWishListEvents] = useState([]);
    const [wishListPayload,setwishListPayload] = useState(
        {
        eventId: id,
        whishlistName: ''
        }
    );
    const handleAddToWishlist = async()=>{
      if(wishListPayload.whishlistName===''){
        Toaster("Title is required", "error")
        return;
      }
        try{
            const res = await addWishList(wishListPayload);
            Toaster("Wishlist added successfully", "success")
            props.onHide();
            props.onConfirm();
        }catch (error){
            Toaster(error?.response?.data, "error");
        }
    }
    const wishListTitle = async(e)=>{
       const value = e.target.value;
       setwishListPayload(prev => ({...prev, whishlistName: value}));
    //    setwishListPayload({whishlistName: ""});
      if(value!=''){
        setError(false);
      }
    }
   
  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="user-wrapper"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="view-menu">
              <h5 className="modal-title">
                Create a new list of favorite events
              </h5>
            </div>
          </Modal.Title>
          <button onClick={props.onHide} className="close-icon">
            <IoClose />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="wishlist-popup-body-sec">
            <div className="mb-0">
              <label className="form-label input-label">
                Title of Your list
              </label>
              <input
                type="text"
                onChange={wishListTitle}
                className="form-control"
                placeholder="My wishlist"
              />
            </div>
            <div className="text-center wishlist-create-btn">
              <Button  className="btn" onClick={handleAddToWishlist}>
                Create list
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
 
export default WishlistModal;