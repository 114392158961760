import React, { useEffect, useState } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { FaArrowLeftLong } from "react-icons/fa6";
import CreateMenuModal from "./modal/CreateMenuModal";
import DeleteModal from "./modal/DeleteModal";
import {
  getMenuEvent,
  getAllFacilities,
  createEventFacility,
  getFacilityEvent,
} from "../../../services/api/events-auth.api";
import { getEventId } from "../../../util/auth";
import Toaster from "../../../common/Toaster";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import Loading from "../../../common/Loading";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

function FoodInfo({ nextHook, prevHook, eventInfoDetails, InputEventHook }) {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);
  const [menuList, setMenuList] = useState([]);
  const [menuDeleteId, setMenuDeleteId] = useState(null);
  const [facilityList, setFacilityList] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState([]);
  const [loading, setLoading] = useState(false);
  const schema = yup.object().shape({
    menuVisibility: yup.boolean().required("Menu visibility is required"),
    menuReason: yup
      .string()
      .nullable()
      .when("menuVisibility", {
        is: false,
        then: yup
          .string()
          .required("Reason is required")
          .min(5, "Minimum length is 5 characters")
          .max(15, "Maximum length is 15 characters")
          .typeError("Reason is required"),
        otherwise: yup.string().nullable().notRequired(),
      }),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      menuVisibility: eventInfoDetails?.menuVisibility || false,
      menuReason: null,
    },
  });
  const [optionsList] = useState([
    { label: "Yes", value: true },
    { label: "No", value: false },
  ]);
  const prev = () => {
    prevHook();
  };
  const nextSubmit = async (formData) => {
    const eventId = await getEventId();
    const stepId = 5;
    if (menuVisibility && menuList.length === 0) {
      setLoading(false);
      Toaster("Please add atleast one menu item for this event", "warning");
      return;
    } else {
      try {
        if (selectedFacility.length > 0) {
          setLoading(false);
          await createEventFacility(selectedFacility, eventId);
        }
        setLoading(true);
        await InputEventHook(formData, stepId);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const getMenus = async () => {
    const eventId = await getEventId();
    try {
      const menus = await getMenuEvent(eventId);
      setMenuList(menus);
    } catch (error) {
      console.log(error);
    }
  };
  const deleteMenus = async (id) => {
    await setModalShow2(true);
    await setMenuDeleteId(id);
  };
  const closePopup = () => {
    setModalShow(false);
    setModalShow2(false);
    getMenus();
  };
  const getFacilities = async () => {
    const eventId = await getEventId();
    try {
      const facility = await getFacilityEvent(eventId);
      if (facility) {
        const res = facility.map((item) => item.id);
        setSelectedFacility(res);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const numericValue = Number(value);
    if (checked) {
      setSelectedFacility((prev) => [...prev, numericValue]);
    } else {
      setSelectedFacility((prev) => prev.filter((id) => id !== numericValue));
    }
  };
  const getData = async () => {
    try {
      const res = await getAllFacilities();
      setFacilityList(res);
      await getFacilities();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getMenus();
    getData();
    const fields = ["menuReason", "menuVisibility"];
    fields.forEach((field) => setValue(field, eventInfoDetails[field]));
  }, [eventInfoDetails]);
  const menuVisibility = watch("menuVisibility");
  return (
    <div>
      <form onSubmit={handleSubmit(nextSubmit)}>
        <div id="pills-tab5">
          {/* <!-- tab5 -->  */}
          <div className="row create-event-tab">
            <div className="col-md-12 mx-auto">
              <div className="check-facility mb-3">
                <p className="text-dark event-facility-title">
                  Event & Venue Features
                </p>
                <div className="row g-3">
                  {facilityList.length > 0 ? (
                    facilityList.map((item) => (
                      <div className="col-md-3">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={item.id}
                            checked={selectedFacility.includes(item.id)}
                            onChange={handleCheckboxChange}
                            id="flexCheckChecked"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            {item.name}
                          </label>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="not-found">Facilities not found</p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="mb-3">
                <label className="form-label input-label">
                  Would you like to display the menu options ?
                </label>
                <div className="radio-btn">
                  <ButtonGroup className="mb-2 gap-3">
                    {optionsList.map((item, idx) => (
                      <ToggleButton
                        key={idx}
                        type="radio"
                        name="menuVisibility"
                        variant="outline-primary toggle-btn"
                        {...register("menuVisibility")}
                        checked={watch("menuVisibility") === item.value}
                        onChange={() => setValue("menuVisibility", item.value)}
                        value={item.value}
                        id={`flexRadio${idx}`}
                      >
                        {item.label}
                      </ToggleButton>
                    ))}
                  </ButtonGroup>
                  {/* {optionsList.map((item, index) => (
                    <div className="form-check" key={index}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="menuVisibility"
                        {...register("menuVisibility")}
                        checked={watch("menuVisibility") === item.value}
                        onChange={() => setValue("menuVisibility", item.value)}
                        value={item.value}
                        id={`flexRadio${index}`}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`flexRadio${index}`}
                      >
                        {item.label}
                      </label>
                    </div>
                  ))} */}
                </div>
              </div>
            </div>
            {menuVisibility ? (
              <div className="col-md-12 mx-auto">
                <div>
                  <p className="text-dark event-facility-title">Event Menus</p>
                  <div className="row">
                    <div className="col-12">
                      <div className="text-end">
                        <button
                          className="btn add-btn"
                          type="button"
                          onClick={() => setModalShow(true)}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                    <div className="col-12">
                      {menuList.length > 0 ? (
                        <div className="table-item">
                          <table className="table mb-0">
                            <thead>
                              <tr>
                                <th scope="col">Item Name</th>
                                <th scope="col">Item Description</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {menuList.length > 0 &&
                                menuList.map((item) => (
                                  <tr key={item.id}>
                                    <td className="item-name">
                                      <p>{item.itemName}</p>
                                    </td>
                                    <td>
                                      <p>{item.itemDescription}</p>
                                    </td>
                                    <td>
                                      <span className="trash-icon-ch">
                                        <button
                                          type="button"
                                          onClick={() => deleteMenus(item.id)}
                                        >
                                          <RiDeleteBinLine />
                                        </button>
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <p className="not-found">Food menus not found</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-lg-12">
                <div className="mb-3">
                  <label className="form-label input-label">
                    Please Provide A Reason For Not Displaying The Menu Options:
                    <sup className="req">*</sup>
                  </label>
                  <p>
                    You can share an idea of what kind of food to expect for the
                    meet. Do note that while surprise menus are interesting,
                    only a few enthusiasts would like to take the plunge and
                    could have lesser sign ups
                  </p>
                  <textarea
                    className="form-control textarea-ht"
                    {...register("menuReason")}
                    placeholder="Enter here...."
                    id="floatingTextarea2"
                  ></textarea>
                  {errors?.menuReason && (
                    <span className="form-error">
                      {errors.menuReason.message}
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="col-md-12 mx-auto">
            <div className="submit-btn next-btn justify-content-end">
              <div className="submit-btn next-btn text-end">
                <div>
                  <button className="prev-arrow" onClick={prev}>
                    <FaArrowLeftLong />
                  </button>
                </div>
                <div>
                  <button className="btn" type="submit">
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <CreateMenuModal show={modalShow} onHide={closePopup} />
      <DeleteModal
        show={modalShow2}
        onHide={() => setModalShow2(false)}
        menuId={menuDeleteId}
        onConfirm={closePopup}
      />
      {loading && <Loading />}
    </div>
  );
}

export default FoodInfo;
