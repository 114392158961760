import React, { useEffect } from "react";
import { registerForm } from "../../services/api/user-auth.api";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { PiEyeClosedLight } from "react-icons/pi";
import { PiEyeThin } from "react-icons/pi";
import Loading from "../../common/Loading";
import Toaster from "../../common/Toaster";
import { AiOutlineHome } from "react-icons/ai";
import "../register/register.scss";
import { doc, setDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { auth, db } from "../../firebase";
import profile from "../../assets/image/profile.png";
import profile1 from "../../assets/image/profile1.jpg";
import profile2 from "../../assets/image/profile2.jpg";
import profile3 from "../../assets/image/profile3.png";
import Select from "react-select";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import {
  PRIVACY_POLICY,
  TERMS_AND_CONDITION,
} from "../../const/route-url.const";
import { getCountries } from "../../services/api/site-info.api";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { MdOutlinePhone } from "react-icons/md";

function Register() {
  const navigate = useNavigate();
  const [showPswd, setShowPswd] = useState(false);
  const [confirmPswd, setConfirmPswd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);

  const profiles = [
    { image: profile },
    { image: profile1 },
    { image: profile2 },
    { image: profile3 },
  ];
  let schema = yup.object().shape({
    firstName: yup
      .string()
      .required("First name is required")
      .min(3, "First name must be at least 3 characters")
      .max(25, "First name must be 25 characters")
      .matches(
        /^[a-zA-Z\s.,!?_-]*$/,
        "Numbers and Special characters are not allowed"
      ),
    lastName: yup
      .string()
      .required("Last name is required")
      .max(25, "Last name must be 25 characters")
      .matches(
        /^[a-zA-Z\s.,!?_-]*$/,
        "Numbers and Special characters are not allowed"
      ),
    username: yup
      .string()
      .matches(/^[a-zA-Z0-9.,!?_-]*$/, "Special characters are not allowed")
      .min(3, "Username must be at least 3 characters")
      .max(25, "Username must be 25 characters")
      .required("User name is required"),
    email: yup.string().required("Email is required").email("Invalid email"),
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/\d/, "Password must contain at least one number")
      .matches(
        /[@$!%*?&]/,
        "Password must contain at least one special character"
      )
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .required("Confirm password is required")
      .oneOf([yup.ref("password"), null], "Password must match"),
    phoneNumber: yup
      .string()
      .matches(/^[0-9]{10}$/, "Phone number must be 10 digits"),
    country: yup.string(),
    optedInForMarketingEmails: yup.boolean(),
    acceptedTermsAndConditions: yup
      .boolean()
      .oneOf([true], "You must accept the terms and conditions")
      .required("Terms acceptance is required"),
    countryCode: yup.string(),
  });
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const regSubmit = async (formData) => {
    setIsLoading(true);
    const displayName = formData?.username;
    const { email } = formData;
    const updatedProfile = Math.floor(Math.random() * profiles.length);
    try {
      const data = await registerForm(formData);
      const res = await createUserWithEmailAndPassword(auth, email, email);
      const response = await fetch(profiles[updatedProfile].image);
      if (!response.ok) {
        throw new Error("Failed to fetch image");
      }
      const blob = await response.blob();

      const storage = getStorage();
      const date = new Date().getTime();
      const storageRef = ref(storage, `${displayName + date}`);
      await uploadBytes(storageRef, blob);

      const downloadURL = await getDownloadURL(storageRef);

      await updateProfile(res.user, {
        displayName,
        photoURL: downloadURL,
      });

      await setDoc(doc(db, "users", res.user?.uid), {
        uid: res.user?.uid,
        displayName,
        email,
        photoURL: downloadURL,
      });

      await setDoc(doc(db, "userChats", res.user?.uid), {});

      reset();
      setIsLoading(false);
      Toaster("Register successfully", "success");
      navigate("/login");
    } catch (error) {
      const errorData = error.response.data;
      setIsLoading(false);
      Toaster(errorData, "error");
      console.error("Error during register:", error.response.data);
    }
  };

  // contry //
  const getCountryData = async () => {
    try {
      const res = await getCountries();
      const list = res.map((option) => ({
        label: `${option.name} ${option.countryCode}`,
        value: option.countryCode,
        countryName: option.name,
      }));
      setCountryList(list);
      setValue("countryCode", "+91");
      setValue(
        "country",
        list.find((c) => c.value === "+91")?.countryName || ""
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCountryData();
  }, []);
  const selectedCountryCode = watch("countryCode");

  useEffect(() => {
    if (selectedCountryCode) {
      const selectedCountry = countryList.find(
        (country) => country.value === selectedCountryCode
      );
      setValue("country", selectedCountry?.countryName || "");
    }
  }, [selectedCountryCode, countryList, setValue]);
  return (
    <>
      <div className="register-wrapper">
        <form onSubmit={handleSubmit(regSubmit)}>
          <div className="container-fluid sign-in register">
            <div className="sign-in-bg">
              {/* <img src={img1} alt="meet-at-table" /> */}
            </div>
            <div className="container position-relative z-2 h-100">
              <div className="row sign-in-row h-100">
                <div className="col-lg-6 mx-auto">
                  <div className="row">
                    <div className="col-md-12 mx-auto">
                      <div className="sign-in-form">
                        <div className="mb-3 form-title d-flex align-items-center justify-content-between">
                          <p className="fw-bold  login-title mb-0">
                            Create your Account as a Guest
                          </p>
                          <Link to="/">
                            <AiOutlineHome className="home-icon" />
                          </Link>
                        </div>
                        <div className="host-create text-center">
                          <p className="mb-1">
                            Want to become a Host instead? Easy, peasy -{" "}
                            <Link to="/host">click and create</Link>
                          </p>
                        </div>
                        <div className="forgot-txt-msg text-center">
                          <p className="mb-1">
                            Hey! It's nice that you're joining the Meet@Table
                            community! Let's get started.
                          </p>
                        </div>
                        <div>
                          <div className="d-flex mb-3 click-signup align-items-center justify-content-center">
                            <p className="mb-1">Already have an account?</p>
                            <p className="mb-1">
                              <Link to="/login">Login now</Link>
                            </p>
                          </div>
                          <div>
                            <div className="row">
                              <div className="mb-1 col-12 col-md-6">
                                <label className="form-label input-label">
                                  First name <sup className="req">*</sup>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="First name"
                                  {...register("firstName")}
                                />
                                {errors?.firstName && (
                                  <span className="form-error">
                                    {errors.firstName.message}
                                  </span>
                                )}
                              </div>
                              <div className="mb-1 col-12 col-md-6">
                                <label className="form-label input-label">
                                  Last name <sup className="req">*</sup>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Last name"
                                  {...register("lastName")}
                                />
                                {errors?.lastName && (
                                  <span className="form-error">
                                    {errors.lastName.message}
                                  </span>
                                )}
                              </div>
                              <div className="mb-1 col-12 ">
                                <label className="form-label input-label">
                                  Your Meet@Table Profile Name
                                  <sup className="req">*</sup>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Get creative, choose a name or stick with your own - it's for your profile"
                                  {...register("username")}
                                />
                                {errors?.username && (
                                  <span className="form-error">
                                    {errors.username.message}
                                  </span>
                                )}
                              </div>
                              <div className="mb-1 col-12">
                                <label className="form-label input-label">
                                  Phone Number
                                </label>
                                <InputGroup className="mb-3 phone-block phone__block">
                                  <InputGroup.Text id="basic-addon1">
                                    <MdOutlinePhone />
                                  </InputGroup.Text>
                                  <div className="col-md-5 selectbox-block border">
                                    {countryList?.length && (
                                      <Controller
                                        name="countryCode"
                                        control={control}
                                        render={({ field }) => (
                                          <Select
                                            {...field}
                                            options={countryList.map(
                                              (code) => ({
                                                value: code.value,
                                                label: code.label,
                                              })
                                            )}
                                            value={countryList.find(
                                              (option) =>
                                                option.value === field.value
                                            )}
                                            onChange={(selectedOption) => {
                                              field.onChange(
                                                selectedOption?.value
                                              );
                                            }}
                                          />
                                        )}
                                      />
                                    )}
                                  </div>
                                  <Form.Control
                                    placeholder=""
                                    aria-label=""
                                    aria-describedby="basic-addon1"
                                    type="number"
                                    {...register("phoneNumber")}
                                    onKeyDown={(e) =>
                                      ["e", "E", "+", "."].includes(e.key) &&
                                      e.preventDefault()
                                    }
                                  />
                                </InputGroup>
                                {errors?.phoneNumber && (
                                  <span className="form-error">
                                    {errors.phoneNumber.message}
                                  </span>
                                )}
                              </div>

                              <div className="mb-1 col-12 col-md-12">
                                <label className="form-label input-label">
                                  Email address <sup className="req">*</sup>
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="Email address"
                                  {...register("email")}
                                />
                                {errors?.email && (
                                  <span className="form-error">
                                    {errors.email.message}
                                  </span>
                                )}
                              </div>
                              <div className="mb-1 col-12 col-md-6">
                                <label className="form-label input-label">
                                  Password <sup className="req">*</sup>
                                </label>
                                <div className="position-relative">
                                  <input
                                    type={showPswd ? "text" : "password"}
                                    className="form-control"
                                    placeholder="Password"
                                    {...register("password")}
                                  />
                                  {errors?.password && (
                                    <span className="form-error">
                                      {errors.password.message}
                                    </span>
                                  )}
                                  <div
                                    className="password-icon"
                                    onClick={() => setShowPswd(!showPswd)}
                                  >
                                    {!showPswd && <PiEyeClosedLight />}
                                    {showPswd && <PiEyeThin />}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-1 col-12 col-md-6">
                                <label className="form-label input-label">
                                  Confirm Password <sup className="req">*</sup>
                                </label>
                                <div className="position-relative">
                                  <input
                                    type={confirmPswd ? "text" : "password"}
                                    className="form-control"
                                    placeholder="Confirm Password"
                                    {...register("confirmPassword")}
                                  />
                                  {errors?.confirmPassword && (
                                    <span className="form-error">
                                      {errors.confirmPassword.message}
                                    </span>
                                  )}
                                  <div
                                    className="password-icon"
                                    onClick={() => setConfirmPswd(!confirmPswd)}
                                  >
                                    {!confirmPswd && <PiEyeClosedLight />}
                                    {confirmPswd && <PiEyeThin />}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="my-1 checkbox-terms">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              {...register("optedInForMarketingEmails")}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Yes! I want to receive exclusive Meet@table
                              offers, travel inspo and al the food in my inbox
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className={`form-check-input ${
                                errors?.acceptedTermsAndConditions
                                  ? "border-danger"
                                  : ""
                              }`}
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              {...register("acceptedTermsAndConditions")}
                            />

                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              By signing up, I agree to{" "}
                              <span>
                                <Link
                                  to="/terms-and-conditions"
                                  target="_blank"
                                >
                                  Terms & Conditions
                                </Link>
                              </span>{" "}
                              and{" "}
                              <span>
                                <Link to="/privacy-policy" target="_blank">
                                  Privacy Policy
                                </Link>
                              </span>
                              .
                            </label>
                          </div>
                          {errors?.acceptedTermsAndConditions && (
                            <span className="form-error">
                              {errors.acceptedTermsAndConditions.message}
                            </span>
                          )}
                        </div>
                        <div>
                          <button className="btn sign-in-btn" type="submit">
                            Let's go
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {isLoading && <Loading />}
    </>
  );
}

export default Register;
