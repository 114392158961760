import React from "react";
import { Modal } from "react-bootstrap";
import { FaCheck } from "react-icons/fa6";
import Button from "react-bootstrap/Button";
import Toaster from "../../../common/Toaster";
import { bookingEvent } from '../../../services/api/events-auth.api';
import "../events.scss";
import { getHostId } from "../../../util/auth";

function ConfirmBookingModal(props) {
    const confirmOfflineBooking = async() =>{
        try{
          const res = await bookingEvent(props.payload);
          props.onConfirm('offlineBooked');
          Toaster("Event Booked Successfully, Waiting for approval", "success");
        }catch (error) {
            Toaster(error?.response?.data?.title, "error");
        }
    }
    const confirmOnlineBooking = async() =>{
      try{
        const res = await bookingEvent(props.payload);
        props.onConfirm('razorBooked');
      }catch (error) {
          Toaster(error?.response?.data?.title, "error");
      }
    }
  
    const booking = async() =>{
      if(props.type === 'razorpay'){
        await confirmOnlineBooking();
      }
      if(props.type === 'offlinepay'){
        await confirmOfflineBooking();
      }
      if(props.type === 'razorpayAuto'){
        props.onConfirm('razorAutoBooked');
      }
      if(props.type === 'bookingAppproval')
      {
        props.onHide('confirm')
      }
    }
  return (
    <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Body>
      <div className="row delete-section">
          <div className="col-lg-12">
              <div className="delete-text">
                <span>
                <FaCheck />
                </span>
                  <p>Are you sure want to confirm the booking !</p>
              </div>
          </div>
          <div className="col-12">
          <div className="text-center submit-btn w-100 d-flex mt-2 justify-content-center create-event-modal-btn">
            <Button onClick={()=> props.onHide()} className="delete-btn">
                  Cancel
            </Button>
            <Button onClick={booking} className="btn">
                  Confirm
            </Button>
          </div>
          </div>
      </div>
    </Modal.Body>
  </Modal>
  )
}

export default ConfirmBookingModal