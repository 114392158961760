import React, { useEffect, useState } from "react";
import { transformImageUrl } from "../../../util/dataTransform";
import LightGallery from "lightgallery/react";
import fjGallery from "flickr-justified-gallery";
import lgZoom from "lightgallery/plugins/zoom";
import lgThumbnail from "lightgallery/plugins/thumbnail";

function ReviewsGallery({ review }) {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (review.length > 0) {
      const imageData = review.map((item) =>
        transformImageUrl(item.documentURL)
      );
      setData(imageData);
    }
  }, [review]);
  return (
    <>
      <div className="gallery-section review-section">
        <LightGallery
          plugins={[lgZoom, lgThumbnail]}
          mode="lg-fade"
          pager={false}
          thumbnail={true}
          elementClassNames={"review_gallery"}
          mobileSettings={{
            controls: false,
            showCloseIcon: false,
            download: false,
            rotate: false,
          }}
        >
          {data.map((image, index) => (
            <a
              key={index}
              className={`review_gallery__item ${
                data.length > 5 ? "hidden see-more" : ""
              }`}
              data-src={image}
            >
              <img
                className="img-responsive"
                src={image}
                alt={`Gallery item ${index + 1}`}
              />
              {data.length > 5 && index === 4 ? (
                <p className="seeMore">See more...</p>
              ) : null}
            </a>
          ))}
        </LightGallery>
      </div>
    </>
  );
}

export default ReviewsGallery;
