import React from "react";
import { Col, Row } from "react-bootstrap";
import { MdOutlineStar } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { EVENTS } from "../../../const/route-url.const";
import { transformImageUrl } from "../../../util/dataTransform";
import noPhoto from "../../../assets/image/noPhoto.png";
import Slider from "react-slick";
import blank from "../../../assets/image/blank-image.webp";

function EventsSlider({ eventItem }) {
  const navigate = useNavigate();
  const redirectToEvent = (event) => {
    navigate(`${EVENTS}/${event.id}`);
  };
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    loop: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    margin: "0px",
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const fillEventList = (events) => {
    const totalEvents = events.length;
    if (totalEvents >= 4) return events;
    return [...events, ...events.slice(0, 4 - totalEvents)];
  };

  const eventImageSetup = (arrDoc) => {
    if (arrDoc?.length > 0) {
      const docUrl = transformImageUrl(arrDoc[0]?.eventDocumentPath);
      return docUrl;
    } else {
      return noPhoto;
    }
  };
  const errorImageHandler = (e) => {
    e.target.src = noPhoto;
  };
  const eventsToDisplay = fillEventList(eventItem);
  return (
    <div>
      <div className="tab-content p-0">
        <div id="all" className="tab-pane active">
          <br />
          <Slider {...settings}>
            {eventsToDisplay?.length > 0
              ? eventsToDisplay.map((eventItems, itemIndex) =>
                  eventItems.map((event, itemIndex) => (
                    <div className="p-3" key={itemIndex}>
                      <div
                        className="en-card-list"
                        onClick={() => redirectToEvent(event)}
                      >
                        <div className="col-12 my-auto">
                          <div className="en-card">
                            <img
                              src={eventImageSetup(event?.eventDocuments)}
                              className="card-img-top"
                              alt="images"
                              onError={errorImageHandler}
                            />
                          </div>
                        </div>
                        <div className="col-12 my-auto">
                          <div className="en-card-txt mx-0 mt-3">
                            <div className="d-flex gap-1 align-items-center">
                              <p className="fd text-truncate flex-grow-1">
                                {event.eventCategory}
                              </p>
                              <p className="dlr">
                                {event.currency} {event.gustPerCost || 0}
                              </p>
                            </div>
                            <div className="rating">
                              <p className="text-elipsis">{event.eventTitle}</p>
                              <p className="rating-count">
                                <span>
                                  <MdOutlineStar />
                                </span>
                                <span>4.5</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )
              : Array.from({ length: 4 }).map((_, index) => (
                  <div className="slick-slide" key={index}>
                    <div className="card h-100 shadow border-0">
                      <div className="position-relative">
                        <div className="tab-img shadow-none border-0">
                          <img
                            src={blank}
                            className="card-img-top"
                            alt="meet-at-table"
                          />
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="blank-text"></div>
                        <div className="blank-text-2"></div>
                      </div>
                    </div>
                  </div>
                ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default EventsSlider;
