import { createSlice } from '@reduxjs/toolkit';

const searchData = createSlice({
    name: 'search',
    initialState: {
      sendEvents:[], 
      sendEventValues : [],
      advancedValues : [],
      advancedSearch:false,
      resetAdvancedSearch:[],
      resetBoolean:false,
      searchBoolean:false
    },
    reducers: {
      sendEvents: (state, action) => {
        state.sendEvents= action.payload
      },
  
      sendEventValues : (state , action) =>{
        state.sendEventValues= action.payload
      },
      advancedValues : (state , action) =>{
        state.advancedValues = action.payload
      },
      advancedSearch : (state , action) =>{
        state.advancedSearch = action.payload
      },
      resetBoolean : (state , action) =>{
        state.resetBoolean = action.payload
      },
      resetAdvancedSearch : (state , action)=>{
        state.resetAdvancedSearch= action.payload
      },
     
      searchBoolean:(state,action)=>{
        state.searchBoolean= action.payload
      }
    },
  });
  
  
  export const { sendEvents } = searchData.actions;
  export const {sendEventValues} = searchData.actions;
  export const {advancedValues} = searchData.actions;
  export const {advancedSearch} = searchData.actions;
  export const {resetAdvancedSearch} = searchData.actions;
  export const {resetBoolean} = searchData.actions;
  export const {searchBoolean} = searchData.actions;
  
  export default searchData.reducer;
  