import React, { useEffect, useState, useRef } from "react";
import { Carousel, Col, Row } from "react-bootstrap";
import { CiSquareChevRight, CiSquareChevLeft } from "react-icons/ci";
import { getRelatedEvents } from "../../../services/api/events-auth.api";
import { transformImageUrl } from "../../../util/dataTransform";
import noPhoto from "../../../../src/assets/image/noPhoto.png";
import { auth } from "../../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import ChatModal from "../modal/ChatModal";
import { useSelector } from "react-redux";
import { getEmail } from "../../../../src/util/auth";
import Toaster from "../../../common/Toaster";
import { hostEmailGet } from "../../../redux/slice/hostMailSlice";
import { useDispatch } from "react-redux";
import { baseURL } from "../../handPickedEvents/HandPickedEvents";
import { MdOutlineStar } from "react-icons/md";

function RelatedEvents({ eventDetails }) {
  const carouselRef = useRef(null);
  const dispatch = useDispatch();
  const [eventData, setEventData] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const userEmail = useSelector((selector) => selector?.userEmail?.value);
  const [params, setParams] = useState({
    pageId: 1,
    pageSize: 10,
    hostId: null,
  });
  const goToPrevSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };
  const goToNextSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };
  const eventImageSetup = (arrDoc) => {
    if (arrDoc[0]?.eventDocumentPath) {
      const docUrl = transformImageUrl(arrDoc[0]?.eventDocumentPath);
      if (!docUrl.includes(baseURL)) {
        return noPhoto;
      } else {
        return docUrl;
      }
    }
  };

  const errorImageHandler = (e) => {
    e.target.src = noPhoto;
  };
  useEffect(() => {
    setParams((prev) => ({
      ...prev,
      hostId: eventDetails?.hostId,
    }));
  }, [eventDetails?.hostId]);
  useEffect(() => {
    const getRelated = async () => {
      try {
        const response = await getRelatedEvents(params);
        const res = response.eventsViewModel;
        dispatch(hostEmailGet(res[0]?.hostEmail));
        const formattedRes = chunkArray(res, 3);
        setEventData(formattedRes);
      } catch (error) {
        console.error("Failed to fetch related events", error);
      }
    };
    if (params?.hostId !== null) {
      getRelated();
    }
  }, [params]);
  const loginSingleChatHandler = async () => {
    try {
      let mail = getEmail();
      mail = userEmail && userEmail !== "" ? userEmail : mail;
      await signInWithEmailAndPassword(auth, mail, mail);
      setModalShow(true);
    } catch (error) {
      Toaster("Please login with your profile", "error");
    }
  };
  return (
    <>
      <div className="row g-3">
        <div className="col-12">
          <div className="see-menu-sec">
            <div>
              <h4>Other options</h4>
              <p>
                This event is public, so other guests will be sharing this
                experience with you.
              </p>
              <div className="see-menu-sec-btn">
                <button className="btn" onClick={loginSingleChatHandler}>
                  Contact the host with specific requests for this event!
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row g-3">
            {eventData.length > 0 && (
              <div className="col-12">
                <div className="other-exp d-flex justify-content-between align-items-center">
                  <div>
                    <h4>
                      Other experiences by {eventDetails?.firstName}{" "}
                      {eventDetails?.lastName}
                    </h4>
                  </div>
                  <div className="d-flex">
                    <span onClick={goToPrevSlide}>
                      <CiSquareChevLeft
                        color="orange"
                        style={{
                          width: "30px",
                          height: "30px",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                    <span onClick={goToNextSlide}>
                      <CiSquareChevRight
                        color="orange"
                        style={{
                          width: "30px",
                          height: "30px",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
            )}
            <div className="col-md-12">
              <Carousel
                indicators={false}
                interval={null}
                controls={false}
                ref={carouselRef}
              >
                {eventData.length > 0 ? (
                  eventData.map((eventItem, chunkIndex) => (
                    <Carousel.Item key={chunkIndex}>
                      <Row className="slider-event-row">
                        {eventItem.map((item, itemIndex) => (
                          <Col key={itemIndex}>
                            <div className="en-card-list">
                              <div className="row">
                                <div className="col-md-4 col-sm-4 my-auto">
                                  <div className="en-card">
                                    <img
                                      src={eventImageSetup(item.eventDocuments)}
                                      alt="images"
                                      onError={errorImageHandler}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-8 col-sm-8 my-auto">
                                  <div className="en-card-txt">
                                    <div className="d-flex align-items-center">
                                      <p className="fd">
                                        {item?.eventCategory}
                                      </p>
                                      <p className="dlr">
                                        {item.currency} {item.gustPerCost || 0}
                                      </p>
                                    </div>
                                    <div className="rating">
                                      <p>{item.eventTitle}</p>
                                      <p className="d-flex align-items-center gap-1">
                                        <MdOutlineStar color="#ffa500" />{" "}
                                        <span>4.5</span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Carousel.Item>
                  ))
                ) : (
                  <div className="no-content">
                    <p className="not-found">No related events for this host</p>
                  </div>
                )}
              </Carousel>
            </div>
          </div>
        </div>
        <ChatModal show={modalShow} onHide={() => setModalShow(false)} />
      </div>
    </>
  );
}

export default RelatedEvents;
