import React from "react";
import "./activation.scss";
import { FiUserCheck } from "react-icons/fi";
import { Link } from "react-router-dom";

function ActivationSuccess() {
  return (
    <main>
      <section>
        <div className="verify-sec">
          <div className="container h-100">
            <div className="row h-100 align-items-center justify-content-center">
              <div className="col-lg-6 col-md-9 mx-auto">
                <div className="verify-section">
                  <FiUserCheck className="success" />
                  <h3>Activation Link Verified</h3>
                  <p>You can now login using your credentials</p>
                  <Link to="/login">
                    <button className="btn">Back to Login</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ActivationSuccess;
