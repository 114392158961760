import React, { useEffect, useState, useRef } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import seeMenu from "../../assets/image/see-menu.png";
import TheHost from "./theHost/TheHost";
import AllExperiences from "./allExperiences/AllExperiences";
import MenuModal from "./modal/MenuModal";
import { useParams } from "react-router-dom";
import {
  getEventById,
  getMenuEvent,
  getFacilityEvent,
} from "../../services/api/events-auth.api";
import "../events/events.scss";
import Datetime from "react-datetime";
import Loading from "../../common/Loading";
import RelatedEvents from "./relatedEvents/RelatedEvents";
import Reviews from "./reviews/Reviews";
import Gallery from "./gallery/Gallery";
import Map from "./Map";
import { LoadScript } from "@react-google-maps/api";
import guestIcon from "../../assets/image/guest-icon.png";
import BookingEvent from "./bookingEvent/BookingEvent";
import { useLocation, useNavigate } from "react-router-dom";
import { getAccessToken } from "../../util/auth";

function Events() {
  const [modalShow, setModalShow] = React.useState(false);
  const { id } = useParams();
  const [eventData, setEventData] = useState(null);
  const [menuData, setMenuData] = useState([]);
  const [facilityData, setFacilityData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [concatAddress, setConcatAddress] = useState("");
  const [guestCounts, setGuestCounts] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const getFacilities = async () => {
    try {
      const facility = await getFacilityEvent(id);
      if (facility) {
        setFacilityData(facility);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getMenu = async () => {
    try {
      const menu = await getMenuEvent(id);
      if (menu) {
        setMenuData(menu);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getIconFacility = (item) => {
    const svgImage = item?.toLowerCase().replace(/\s+/g, "");
    let imagePath;
    try {
      imagePath = require(`../../assets/image/facilityImages/${svgImage}.png`);
    } catch (error) {
      console.log(error);
    }
    return imagePath;
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await getEventById(id);
      if (res) {
        setEventData(res);
        const fullAddress = `${res?.primaryAddress}, ${res?.secondaryAddress}, ${res?.city},${res?.country}`;
        setConcatAddress(fullAddress);
        const guestOptions = Array.from(
          { length: res.numberOfGuests },
          (_, i) => i + 1
        );
        const data = guestOptions.map((option) => ({
          label: option,
          value: option,
        }));
        setGuestCounts(data);
        await getMenu();
        await getFacilities();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (token) {
      const user = getAccessToken();
      if (!user) {
        navigate(
          `/login?redirect=${encodeURIComponent(
            location.pathname + location.search
          )}`
        );
      }
    }
    fetchData();
  }, []);
  const onRefresh = () => {
    fetchData();
  };
  return (
    <>
      <div className="events-wrapper-main events-bg">
        <div className="events-bg-overlay"></div>
        <div className="events-bg-content">
          {/* Booking section */}
          <BookingEvent
            eventDetails={eventData}
            guests={guestCounts}
            getQueryParams={queryParams}
          />
          {/* <!--gallery--> */}
          <div className="container mt-3">
            <Gallery event={eventData} eventRefresh={onRefresh} />
          </div>
          {/* // <!--main section--> */}
          <main>
            <div className="p-s1">
              {/* <!--section--> */}
              <section>
                <div className="evnt-con position-relative overflow-hidden">
                  <div className="shape-1"></div>
                  <div className="container">
                    <div className="row">
                      <div className="evnt-title">
                        <h1>{eventData?.eventTitle}</h1>
                        <p>
                          Hosted by{" "}
                          <b className="text-dark">
                            {eventData?.firstName} {eventData?.lastName}
                          </b>{" "}
                          in <b className="text-dark">{eventData?.city}</b>
                        </p>
                      </div>
                    </div>
                    {/* <!--section--> */}
                    <div className="row g-3 mb-3">
                      <div className="col-md-12">
                        <div className="menu-tab evnt-tab">
                          {/* <!--tab--> */}
                          <Tabs
                            defaultActiveKey="All Experiences"
                            id="controlled-tab-example"
                            className="nav nav-tabs justify-content-center"
                          >
                            {/* <!--tab content--> */}
                            <Tab
                              eventKey="All Experiences"
                              className="nav-item "
                              title="All Experiences"
                            >
                              <AllExperiences event={eventData} />
                            </Tab>
                            <Tab
                              eventKey="The Host"
                              className="nav-item "
                              title="The Host"
                            >
                              <TheHost event={eventData} />
                            </Tab>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/* <!--section--> */}
              <section>
                <div className="position-relative bg2-img-con overflow-hidden">
                  <div className="shape-3"></div>
                  <div className="container">
                    <div className="row gy-3 mb-5">
                      <div className="col-lg-7">
                        <div className="row g-3">
                          <div className="col-12">
                            <div className="see-menu-sec">
                              <div className="row g-3">
                                <div className="col-md-2">
                                  <div className="see-menu-img">
                                    <img src={seeMenu} alt="meet-at-table" />
                                  </div>
                                </div>
                                <div className="col-md-5 mt-auto">
                                  <div className="see-menu-p-txt">
                                    <h4>See the menu</h4>
                                    {eventData?.menuVisibility ? (
                                      <p className="mb-0">
                                        The menu is a list of food and beverages
                                        offered to customers and the prices.
                                      </p>
                                    ) : (
                                      <p className="mb-0">
                                        {eventData?.menuReason}
                                      </p>
                                    )}
                                  </div>
                                </div>
                                {eventData?.menuVisibility && (
                                  <div className="col-md-4 mt-auto">
                                    <div className="see-menu-sec-btn d-flex justify-content-end mt-2">
                                      <button
                                        className="btn"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal"
                                        onClick={() => setModalShow(true)}
                                      >
                                        Click to view the menu
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="see-menu-sec">
                              <div className="row g-3">
                                <div className="col-md-12">
                                  <div>
                                    <h4>The Place</h4>
                                    <p>
                                      The full address will be provided once the
                                      booking is confirmed
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="see-menu-sec-btn">
                                    {eventData?.privateAddress ? (
                                      <Map
                                        latitude={eventData?.latitude}
                                        longitude={eventData?.longitude}
                                        zoom={14}
                                        address={concatAddress}
                                      />
                                    ) : (
                                      <Map
                                        latitude={eventData?.privateLatitude}
                                        longitude={eventData?.privateLongitude}
                                        zoom={14}
                                        address={concatAddress}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5">
                        <RelatedEvents eventDetails={eventData} />
                      </div>
                      <div className="col-md-12">
                        <div className="useful-info see-menu-sec ">
                          <div>
                            <h4>Useful Information</h4>
                          </div>
                          <div className="row g-3">
                            {facilityData.length > 0 ? (
                              facilityData.map((item, index) => (
                                <div
                                  className="col-lg-2 col-md-6"
                                  key={item.id}
                                >
                                  <div className="evnt-feat d-flex">
                                    <div className="evnt-feat-img">
                                      <img
                                        src={getIconFacility(item.name)}
                                        alt="meet-at-table"
                                      />
                                    </div>
                                    <div>
                                      <p className="mb-0">{item.name}</p>
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <p className="not-found">No facility found</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="shape-2"></div>
                </div>
              </section>

              {/* <!--section--> */}
              <section>
                <Reviews eventDetails={eventData} reviewsRefresh={onRefresh} />
              </section>
            </div>
          </main>
        </div>
        {/* <!--popup--> */}
        <MenuModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          menus={menuData}
        />
      </div>
      {isLoading && <Loading />}
    </>
  );
}

export default Events;
