import React, { useEffect, useState } from "react";
import img2 from "../../../assets/image/breadcrumb-image.png";
import { FaArrowLeftLong } from "react-icons/fa6";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "../editEvent/edit-event.scss";
import EventInfoTab from "./EventinfoTab";
import BookingInfoTab from "./BookingInfoTab";
import LocationTab from "./LocationTab";
import ChefHostTab from "./ChefHostTab";
import FoodInfoTab from "./FoodInfoTab";
import PhotosTab from "./PhotosTab";
import BookingDetailsTab from "./BookingDetailsTab";
import { useParams } from "react-router-dom";
import { getEventById } from "../../../services/api/events-auth.api";
import Loading from "../../../common/Loading";
import { useLocation } from "react-router-dom";

function EditEvent() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [eventData, setEventData] = useState(null);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const param1 = queryParams.get("upcoming") || queryParams.get("past");
  const upcomingParam = queryParams.get("upcoming");
  useEffect(() => {
    if (id != undefined) {
      fetchData();
    }
  }, []);
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await getEventById(id);
      if (res) {
        setEventData(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="edit-event-block">
      <div className="breadcrumb-image">
        <img src={img2} alt="meet-at-table" />
        <div className="breadcrumb-overlay"></div>
        <div className="breadcrumb-title">
          <div className="breadcrumb-header">
            <h4>Edit Event </h4>
          </div>
        </div>
      </div>

      <main>
        <section>
          <div className="position-relative overflow-hidden p-s edit-event">
            <div className="shape-1"></div>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="row g-3 mb-3">
                    <div className="col-md-12">
                      <div className="menu-tab evnt-tab edit-event-tab">
                        <Tabs
                          defaultActiveKey="edit-info"
                          id="controlled-tab-example"
                          className="nav nav-tabs justify-content-between"
                        >
                          <Tab
                            eventKey="edit-info"
                            className="nav-item "
                            title="Event Info"
                          >
                            <EventInfoTab
                              event={eventData}
                              refreshDetails={fetchData}
                              inActive={param1}
                            />
                          </Tab>
                          <Tab
                            eventKey="booking-info"
                            className="nav-item "
                            title="Booking Info"
                          >
                            <BookingInfoTab
                              event={eventData}
                              refreshDetails={fetchData}
                              inActive={param1}
                            />
                          </Tab>
                          <Tab
                            eventKey="location"
                            className="nav-item "
                            title="Location"
                          >
                            <LocationTab
                              event={eventData}
                              refreshDetails={fetchData}
                              inActive={param1}
                            />
                          </Tab>
                          <Tab
                            eventKey="chef-host"
                            className="nav-item "
                            title="Chef & Host"
                          >
                            <ChefHostTab
                              event={eventData}
                              refreshDetails={fetchData}
                              inActive={param1}
                            />
                          </Tab>
                          <Tab
                            eventKey="food-info"
                            className="nav-item "
                            title="Food Info"
                          >
                            <FoodInfoTab
                              event={eventData}
                              refreshDetails={fetchData}
                              inActive={param1}
                            />
                          </Tab>
                          <Tab
                            eventKey="photos"
                            className="nav-item "
                            title="Photos"
                          >
                            <PhotosTab event={eventData} inActive={param1} />
                          </Tab>
                          {upcomingParam && (
                            <Tab
                              eventKey="Bookings"
                              className="nav-item "
                              title="Bookings"
                            >
                              <BookingDetailsTab event={eventData} />
                            </Tab>
                          )}
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="shapefaq"></div>
          </div>
        </section>
      </main>
      {isLoading && <Loading />}
    </div>
  );
}

export default EditEvent;
