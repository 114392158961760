import React, { useEffect, useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import userInfo from "../../../assets/image/user-info1.png";
import Select from "react-select";
import money from "../../../assets/image/money.png";
import guestDateIcon from "../../../assets/image/guest-date-icon.png";
import { getAccessToken, getHostId, getEmail } from "../../../util/auth";
import Toaster from "../../../common/Toaster";
import {
  checkBookingStatus,
  getStripeLink,
} from "../../../services/api/events-auth.api";
import {
  getUserByToken,
  getProfile,
} from "../../../services/api/user-auth.api";
import ConfirmBookingModal from "../modal/confirmBookingModal";
import OnlineBookingModal from "../modal/onlineBookingModal";
import Loading from "../../../common/Loading";
import SuccessPaymentModal from "../modal/SuccessPaymentModal";
import {
  bookingEvent,
  ischeckguestcount,
} from "../../../services/api/events-auth.api";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripePayment from "./StripePayment";
import PopupModal from "../../../common/Popup";
import LoginModal from "../modal/loginModal";

const stripePromise = loadStripe(
  "pk_test_51Q7WHIP1K64Pnlyo7dQDsZiz7YZmSiV2R6z2cg2T53gHMof26Ia96Fp2DWwmP41jUoEVM8q9HH1ESNIcGcx9vsjm00yYhhVJ7v"
);

function BookingEvent({ eventDetails, guests, getQueryParams }) {
  const [guestsCount, setGuestsCount] = useState(null);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow1, setModalShow1] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);
  const [modalShow3, setModalShow3] = React.useState(false);
  const [modalShow4, setModalShow4] = React.useState(false);
  const [amount, setAmount] = useState(null);
  const checkToken = getQueryParams.get("token");
  const stripeBookingId = getQueryParams.get("bookingId" || "BookingId");
  const [bookingData, setBookingData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [bookingType, setBookingType] = useState("");
  const [userData, setUserData] = useState(null);
  const [hasOpenedStripeLink, setHasOpenedStripeLink] = useState(false);
  const [errorModalShow, setErrorModalShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (getAccessToken()) {
      if (guestsCount === null) {
        Toaster("Please select a guest", "error");
      } else {
        setIsLoading(true);
        try {
          const userId = await getUser();
          if (!userId) {
            setIsLoading(false);
            Toaster("User not found. Please log in again.", "error");
            return;
          }
          const bookdata = {
            bookingId: 1,
            userId: userId || "",
            eventId: eventDetails?.id,
            noOfBookingSheets: guestsCount,
            bookingInformation: "",
            email: getEmail(),
            transactionId: null,
          };

          if (eventDetails.postPay && !eventDetails.isAuto) {
            bookdata.amount = amount;
            await setBookingData(bookdata);
            await setBookingType("razorpay");
            await setModalShow(true);
          }
          if (eventDetails.postPay && eventDetails.isAuto) {
            bookdata.amount = amount;
            await setBookingData(bookdata);
            await setBookingType("razorpayAuto");
            await setModalShow(true);
          }
          if (
            (!eventDetails.postPay && eventDetails.isAuto) ||
            (!eventDetails.postPay && !eventDetails.isAuto)
          ) {
            bookdata.amount = 0;
            await setBookingData(bookdata);
            await setBookingType("offlinepay");
            await setModalShow(true);
          }
        } catch (error) {
          Toaster("Error while fetching user information", "error");
        } finally {
          setIsLoading(false);
        }
      }
    } else {
      await setModalShow4(true);
      Toaster("Please login to book an event", "error");
    }
  };

  const getUser = async () => {
    try {
      let userId = await getUserByToken();
      return userId;
    } catch (error) {
      console.error("Error fetching user ID", error);
      return null;
    }
  };
  const calculateAmount = () => {
    if (eventDetails?.gustPerCost && guestsCount) {
      const total = eventDetails?.gustPerCost * guestsCount;
      setAmount(total);
    }
  };
  const bookRazorpay = (details) => {
    if (eventDetails) {
      const options = {
        key: "rzp_test_B8PBhV3c69dj3L",
        amount: details.amount * 100,
        currency: eventDetails.currency || "INR",
        name: "Meet@Table",
        description: "Test Transaction",
        handler: function (response) {
          setModalShow2(true);
        },
        prefill: {
          name: "Test User",
          email: "testuser@example.com",
          contact: "9999999999",
        },
        notes: {
          bookingId: details.id,
          hostId: eventDetails.hostId,
        },
        theme: {
          color: "#ff7903",
        },
      };
      const pay = new window.Razorpay(options);
      pay.open();
    }
  };
  const razorpayAutoBookings = async () => {
    try {
      const id = await bookingEvent(bookingData);
      if (id) {
        bookingData.id = id;
      }
      getUserDetails();
    } catch (error) {
      Toaster(error?.response?.data?.title, "error");
    }
  };
  const getUserDetails = async () => {
    try {
      const userDetails = await getProfile();
      setUserData(userDetails);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const user = getAccessToken();
    if (checkToken && user) {
      checkoutBooking();
    }
  }, [eventDetails]);

  useEffect(() => {
    if (userData) {
      if (userData.country === "Poland") {
        setModalShow3(true);
      } else {
        bookRazorpay(bookingData);
      }
    }
  }, [userData]);
  // const checkGuestCount = async () => {
  //   try {
  //     const count = await ischeckguestcount(eventDetails.id);
  //     console.log(count, 'count');
  //   } catch (error) {

  //   }
  // }
  const checkoutBooking = async () => {
    try {
      const bookingInfo = await checkBookingStatus(checkToken);
      bookRazorpay(bookingInfo);
    } catch (error) {
      await setErrorMessage(
        error?.response?.data || "An unexpected error occurred."
      );
      await setErrorModalShow(true);
      // Toaster(error?.response?.data, 'error');
    }
  };
  useEffect(() => {
    if (guestsCount != null) {
      calculateAmount();
    }
  }, [guestsCount]);

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };
  const closePopup = async (type) => {
    if (type === "razorBooked") {
      await setModalShow(false);
      await setModalShow1(true);
    }
    if (type === "offlineBooked") {
      await setModalShow(false);
    }
    if (type === "razorAutoBooked") {
      await setModalShow(false);
      await razorpayAutoBookings();
    }
  };
  const stripeConfirm = async () => {
    await setModalShow3(false);
    await setModalShow2(true);
  };
  const getStripe = async () => {
    try {
      const linkData = await getStripeLink(stripeBookingId);
      if (linkData && linkData.value) {
        window.open(linkData.value, "_blank");
      }
    } catch (error) {
      await setErrorMessage(
        error?.response?.data || "An unexpected error occurred."
      );
      await setErrorModalShow(true);
    }
  };
  useEffect(() => {
    if (stripeBookingId != null && !hasOpenedStripeLink) {
      getStripe();
      setHasOpenedStripeLink(true);
    }
  }, [stripeBookingId]);
  return (
    <div>
      <div className="bkng-con">
        <div className="container-fluid">
          <div className="row">
            {/* col-xxl-7  */}
            <div className="col-xxl-8 col-xl-9 col-lg-12 col-md-12 mx-auto z-2">
              <div className="row">
                <div className="col-lg-4 col-sm-6 col-sm-5 col-12 my-auto">
                  <div className="nav-btn no-guest-icon-nav">
                    <InputGroup>
                      <InputGroup.Text
                        id="basic-addon1"
                        className="no-guest-icon"
                      >
                        <img src={userInfo} alt="meet-at-table" />
                      </InputGroup.Text>
                      <Select
                        placeholder="Select Number of Guests"
                        className="guest-no-select guest__noselect border-0 flex-grow-1"
                        getOptionLabel={(option) => `${option.label} Guests`}
                        options={guests}
                        onChange={(selectedGuests) =>
                          setGuestsCount(selectedGuests.value)
                        }
                      />
                    </InputGroup>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-sm-3 col-12 my-auto">
                  <div className="nav-btn guest-date-select">
                    <div className="date-guest-icon">
                      <img src={guestDateIcon} alt="meet-at-table" />
                    </div>
                    <p>
                      {eventDetails?.fromDate !== null
                        ? formatDateTime(eventDetails?.fromDate)
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-sm-4 col-8 my-auto">
                  <div className="nav-btn">
                    <div className="bkng-con-guest-dlr guest-date-select">
                      <div className="date-guest-icon">
                        <img src={money} alt="meet-at-table" />
                      </div>
                      <p className="mb-0">
                        {eventDetails?.currency}{" "}
                        {eventDetails?.gustPerCost || 0} <span>/Guest</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-sm-6 col-4 my-auto">
                  <div className="nav-btn">
                    {eventDetails?.numberOfGuests > 0 ? (
                      <div className="btn bkng-con-btn">
                        <button className="btn" onClick={handleSubmit}>
                          Book
                        </button>
                      </div>
                    ) : (
                      <div>
                        <p className="btn-booking-close">Booking closed</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmBookingModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        payload={bookingData}
        onConfirm={closePopup}
        type={bookingType}
      />
      <OnlineBookingModal
        show={modalShow1}
        onHide={() => setModalShow1(false)}
      />
      <SuccessPaymentModal
        show={modalShow2}
        onHide={() => setModalShow2(false)}
      />
      <PopupModal
        show={errorModalShow}
        onHide={() => setErrorModalShow(false)}
        message={errorMessage}
      />
      <Elements stripe={stripePromise}>
        <StripePayment
          show={modalShow3}
          onHide={() => setModalShow3(false)}
          onSuccess={stripeConfirm}
          bookings={bookingData}
        />
      </Elements>
      <LoginModal show={modalShow4} onHide={() => setModalShow4(false)} />
      {isLoading && <Loading />}
    </div>
  );
}

export default BookingEvent;
