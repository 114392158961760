import React from "react";
import img2 from "../../../assets/image/breadcrumb-image.png";
import img3 from "../../../assets/image/login-pattern2.png";
import img4 from "../../../assets/image/contact1-img.png";
import img5 from "../../../assets/image/faq-bg.png";
import img6 from "../../../assets/image/contact2-img.png";
import { MdOutlinePhone } from "react-icons/md";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import "../contactUs/contactUs.scss";
import banner from "../../../assets/image/new-banner-video.mp4";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { contactForm } from "../../../services/api/events-auth.api";
import Toaster from "../../../common/Toaster";

function ContactUs() {
  const phoneRegex = RegExp(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  );
  let schema = yup.object().shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup.string().required("Email is required").email("Invalid email"),
    phoneNumber: yup
      .string()
      .nullable()
      .matches(phoneRegex, "Phone number is not valid")
      .required("Phone number is required"),
    query: yup.string().required("Message is required"),
  });
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const regSubmit = async (formData) => {
    try {
      const res = await contactForm(formData);
      reset();
      Toaster("Your message has been submitted successfully!", "success");
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <>
      <div className="contactUs-wrapper">
        <div className="breadcrumb-image">
          {/* <img src={img2} alt="meet-at-table"/> */}
          <video autoPlay muted loop>
            <source src={banner} type="video/mp4" />
          </video>
          <div className="breadcrumb-overlay"></div>
          <div className="breadcrumb-title">
            <div className="breadcrumb-header">
              <h4>Contact Us</h4>
            </div>
            <div className="container">
              <p>
                We are happy that you want to get in touch with us. We welcome
                your valuable suggestions, feedback and even questions but
                please do check our FAQs first. Please note that Meet@Table
                works Mon-Fri 9am - 4pm CET and will hence respond accordingly
              </p>
            </div>
          </div>
        </div>
        <div className="pattern-row">
          <div className="col-12">
            <div className="pattern-2">
              <img src={img3} alt="meet-at-table" />
            </div>
          </div>
        </div>

        <div className="position-relative overflow-hidden p-s contactus-bg">
          <div className="contactus-bg-overlay"></div>
          <div className="contactus-bg-content">
            <div className="shape-1"></div>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 my-auto">
                  <div className="row">
                    <div className="col-lg-7">
                      <div className="faq-bg h-100">
                        <div className="faq-img">
                          <div className="faq2-img">
                            <img src={img4} alt="meet-at-table" />
                            <div className="faq-bg-img">
                              <img src={img5} alt="meet-at-table" />
                            </div>
                          </div>
                          <div className="faq1-img">
                            <img src={img6} alt="meet-at-table" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <form onSubmit={handleSubmit(regSubmit)}>
                    <div className="sign-in-form contact-form">
                      <div className="row gy-3">
                        <div className="col-md-6">
                          <div>
                            <label className="form-label input-label">
                              First name
                              <sup className="req">*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="First name"
                              {...register("firstName")}
                            />
                            {errors?.firstName && (
                              <span className="form-error">
                                {errors.firstName.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div>
                            <label className="form-label input-label">
                              Last name
                              <sup className="req">*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Last name"
                              {...register("lastName")}
                            />
                            {errors?.lastName && (
                              <span className="form-error">
                                {errors.lastName.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-6 col-xl-12">
                          <div>
                            <label className="form-label input-label">
                              Email address
                              <sup className="req">*</sup>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Email address"
                              {...register("email")}
                            />
                            {errors?.email && (
                              <span className="form-error">
                                {errors.email.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-6 col-xl-12">
                          <label className="form-label input-label">
                            Phone number
                            <sup className="req">*</sup>
                          </label>
                          <InputGroup className="phone-block">
                            <InputGroup.Text id="basic-addon1">
                              <MdOutlinePhone />
                              <Form.Select aria-label="Default select example">
                                <option>+91</option>
                                <option value="1">+93</option>
                                <option value="2">+92</option>
                                <option value="3">+95</option>
                              </Form.Select>
                            </InputGroup.Text>
                            <Form.Control
                              placeholder=""
                              aria-label=""
                              aria-describedby="basic-addon1"
                              type="number"
                              {...register("phoneNumber")}
                              onKeyDown={(e) =>
                                ["e", "E", "+", "."].includes(e.key) &&
                                e.preventDefault()
                              }
                            />
                          </InputGroup>
                          {errors?.phoneNumber && (
                            <span className="form-error">
                              {errors.phoneNumber.message}
                            </span>
                          )}
                        </div>
                        <div className="col-md-12">
                          <div>
                            <label className="form-label input-label">
                              What can we do for you?
                              <sup className="req">*</sup>
                            </label>
                            <div className="form-floating">
                              <textarea
                                className="form-control"
                                placeholder="Leave a comment here"
                                id="floatingTextarea2"
                                style={{ height: "100px" }}
                                {...register("query")}
                              ></textarea>
                              {errors?.query && (
                                <span className="form-error">
                                  {errors.query.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="submit-btn d-flex justify-content-center">
                            <button className="btn" type="submit">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="shapefaq"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
