export const API = "api";
export const VERSION = "v1";
export const MASTER = "master";
export const USERS = "user";
export const HOSTS = "host";
export const EVENT = "event";
export const GOOGLE_MAPS_API_KEY = "AIzaSyBZw1zL4TH3Kzt0eEbt5YQ1wLY0THVk4pc";

export const AUTH = {
  LOG_IN: `/${API}/${VERSION}/user/login`,
  REGISTER: `/${API}/${VERSION}/user/register`,
  VALID_PASSWORD: `/${API}/${VERSION}/user/isvaliduseremail`,
  RESET_PASSWORD: `/${API}/${VERSION}/user/forgotpassword`,
  UPDATE_PASSWORD: `/${API}/${VERSION}/user/updatepassword`,
  CHANGE_PASSWORD: `/${API}/${VERSION}/user/changepassword`,
  DELETE_USER_PROFILE: `/${API}/${VERSION}/user/deleteuserprofile`,
};

export const SITE_INFO = {
  FAQS: `/${API}/${VERSION}/${MASTER}/getfaqs`,
  ABOUT_US: `/${API}/${VERSION}/${MASTER}/getaboutus`,
  FOOTER_CONTENT: `/${API}/${VERSION}/${MASTER}/footer`,
};

export const USER = {
  GET_PROFILE: `/${API}/${VERSION}/${USERS}/getuserprofilebyid`,
  PROFILE_PICTURE_UPLOAD: `/${API}/${VERSION}/${USERS}/uploadprofileimage`,
  MEMBERSHIP_PLANS: `/${API}/${VERSION}/${USERS}/membershipplans`,
  UPDATE_PROFILE: `/${API}/${VERSION}/${USERS}/updateprofile`,
  USER_BY_TOKEN: `/${API}/${VERSION}/${USERS}/userbytoken`,
  USER_WISHLIST: `/${API}/${VERSION}/${EVENT}/whishes`,
  ADD_WISHLIST: `/${API}/${VERSION}/${EVENT}/createwhishlist`,
  REMOVE_EVENT_WISHLIST: `/${API}/${VERSION}/${EVENT}/createwhishlist`,
  REMOVE_WISHLIST: `/${API}/${VERSION}/${EVENT}/deletewhishes`,
  GET_NOTIFICATION_LIST: `/${API}/${VERSION}/${USERS}/getnotificationlist`,
  GOOGLEAUTH: `/${API}/${VERSION}/${USERS}/google-response`,
  SAVE_GOOGLE_PROFILE: `/${API}/${VERSION}/${USERS}/updateuserdetails`,
  CHECK_USER_PROFILE: `/${API}/${VERSION}/${USERS}/checkuserprofile`,
  ACCEPT_TERMS_CONDITIONS: `/${API}/${VERSION}/${USERS}/acceptedtermsandconditions`,
  SEND_NOTIFICATION: `/${API}/${VERSION}/${USERS}/sendnotification`,
};

export const COMMON = {
  GET_CURRENCIES: `/${API}/${VERSION}/${MASTER}/getcurrencies`,
  GET_LANGUAGE: `/${API}/${VERSION}/${MASTER}/getnativelanguages`,
  GET_PREFERENCE: `/${API}/${VERSION}/${MASTER}/gethostpreference`,
  GET_QUALITY_EXPERIENCE: `/${API}/${VERSION}/${MASTER}/getqualifyhostexperience`,
  GET_EXPERIENCE_TYPE: `/${API}/${VERSION}/${MASTER}/gethostexperiencetype`,
  GET_AMBIANCE: `/${API}/${VERSION}/${MASTER}/getambinace`,
  GET_COUNTRY: `/${API}/${VERSION}/${MASTER}/getcountries`,
  GET_WOW_FACTOR: `/${API}/${VERSION}/${MASTER}/getwowfactor`,
  GET_SPEACK_LANGUAGE: `/${API}/${VERSION}/${MASTER}/languages`,
  GET_TERMS_AND_CONDITIONS: `/${API}/${VERSION}/${MASTER}/gettermsandservice`,
  GET_PRIVACY_POLICY: `/${API}/${VERSION}/${MASTER}/getprivacypolicy`,
  GET_HOW_IT_WORKS: `/${API}/${VERSION}/${MASTER}/howitswork`,
  GET_CUISINES: `/${API}/${VERSION}/${MASTER}/getcuisines`,
};

export const HOST = {
  CREATE: `/${API}/${VERSION}/${HOSTS}/createhost`,
  BECOME_HOST: `/${API}/${VERSION}/${USERS}/becomeahost`,
  GET_HOST: `/${API}/${VERSION}/${HOSTS}/gethostbyid`,
  PATCH: `/${API}/${VERSION}/${HOSTS}/patchhost`,
  HOST_UPLOAD: `/${API}/${VERSION}/${HOSTS}/hostdocuments`,
  HOST_PLAN: `/${API}/${VERSION}/${HOSTS}/selectmembershipplan`,
  DELETE_HOST_DOC: `/${API}/${VERSION}/${HOSTS}/deletehostdocuments`,
  STRIPE_LINK: `/${API}/${VERSION}/${HOSTS}/stripepaymentlink`,
  REFRESH_HOST_FORM: `/${API}/${VERSION}/${HOSTS}/refreshhostform`,
};

export const EVENTS = {
  GET_EVENTS: `/${API}/${VERSION}/${EVENT}/events`,
  GET_EVENT_BY_ID: `/${API}/${VERSION}/${EVENT}/geteventbyid`,
  GET_MENU_EVENTS: `/${API}/${VERSION}/${EVENT}/eventmenu`,
  GET_FACILITY_EVENTS: `/${API}/${VERSION}/${EVENT}/eventfacilities`,
  GET_EVENT_CATEGORY: `/${API}/${VERSION}/${MASTER}/eventcategory`,
  GET_EVENT_SUB_CATEGORY: `/${API}/${VERSION}/${EVENT}/geteventsubcategory`,
  GET_HOST_REVIEWS: `/${API}/${VERSION}/${EVENT}/gethostreviews`,
  GET_RELATED_EVENTS: `/${API}/${VERSION}/${EVENT}/relatedevent`,
  GET_UPCOMING_EVENTS: `/${API}/${VERSION}/${EVENT}/upcomingevents`,
  GET_HOST_EVENTS: `/${API}/${VERSION}/${EVENT}/hostevents`,
  CREATE_EVENT: `/${API}/${VERSION}/${EVENT}/patchcreateevent`,
  CREATE_MENU: `/${API}/${VERSION}/${EVENT}/createeventmenu`,
  DELETE_MENU: `/${API}/${VERSION}/${EVENT}/deletefoodmenu`,
  GET_ALL_FACILITIES: `/${API}/${VERSION}/${MASTER}/eventfacilities`,
  CREATE_EVENT_FACILITY: `/${API}/${VERSION}/${EVENT}/createeventfacilities`,
  DOC_UPLOAD: `/${API}/${VERSION}/${EVENT}/eventdocuments`,
  DELETE_EVENT: `/${API}/${VERSION}/${EVENT}/deleteevent`,
  DELETE_EVENT_DOC: `/${API}/${VERSION}/${EVENT}/deleteeventdocuments`,
  SEARCH_EVENTS: `/${API}/${VERSION}/${EVENT}/searchevents`,
  GET_BOOKING_EVENTS: `/${API}/${VERSION}/${EVENT}/getbookinginfo`,
  APPROVE_EVENTS_USER: `/${API}/${VERSION}/${HOSTS}/approveeventbooking`,
  REJECT_EVENTS_USER: `/${API}/${VERSION}/${HOSTS}/rejecteventbooking`,
  NEAR_EVENTS: `/${API}/${VERSION}/${EVENT}/nearbyevent`,
  BOOKING_EVENTS: `/${API}/${VERSION}/${EVENT}/createbooking`,
  BOOKING_STATUS: `/${API}/${VERSION}/${EVENT}/getbookinginfobytoken`,
  BOOKING_STRIPE: `/${API}/${VERSION}/${EVENT}/create-payment-intent`,
  CHECK_GUEST_COUNT: `/${API}/${VERSION}/${EVENT}/checkguestcount`,
  CREATE_REVIEWS: `/${API}/${VERSION}/${EVENT}/createeventreviews`,
  MEMBERSHIP_PLANS: `/${API}/${VERSION}/${EVENT}/selectmembershipplan`,
  IS_BOOKED_EVENT: `/${API}/${VERSION}/${EVENT}/isuserbooked`,
  CONTACT_FORM: `/${API}/${VERSION}/${EVENT}/contectform`,
  MY_BOOKINGS_EVENTS: `/${API}/${VERSION}/${EVENT}/getbookeduser`,
  REVIEWS_DOCUMENT: `/${API}/${VERSION}/${EVENT}/eventreviewdocuments`,
  EVENT_CANCEL_BOOKINGS: `/${API}/${VERSION}/${EVENT}/cancelbookingevent`,
};
