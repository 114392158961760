import React, { useEffect, useState } from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { StripePaymentIntent } from '../../../services/api/events-auth.api';
import Modal from "react-bootstrap/Modal";
import "../events.scss";
import Button from "react-bootstrap/Button";
import Toaster from '../../../common/Toaster';
import Loading from '../../../common/Loading';
import { getHostId } from "../../../util/auth";

const StripePayment = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const createPaymentIntent = async () => {
    try {
      const data = await StripePaymentIntent(100);
      setClientSecret(data.clientSecret);
    } catch (error) {
      console.error("Error creating payment intent:", error);
    }
  };

  useEffect(() => {
    createPaymentIntent();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // setIsLoading(true);
    if (!stripe || !elements || !clientSecret) return;
    const result = await stripe.confirmCardPayment('pi_3QJtZGP1K64Pnlyo1692inTQ_secret_TUlmrHTPKtXBHTifdVfpMUMyV', {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: { name: 'Test User' },
      }
    });

    if (result.error) {
        Toaster(result.error.message,'error');
    } else if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
    //    setIsLoading(false);
       props.onSuccess();
       Toaster('Payment successful!','success');
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
      },
    },
  };

  return (
    <>
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="row delete-section">
          <form onSubmit={handleSubmit} className="payment-form">
            <h3>Stripe Payment</h3>
            <div className="card-element-wrapper">
              <label>Card Number</label>
              <CardNumberElement options={cardElementOptions} />

              <label>Expiration Date</label>
              <CardExpiryElement options={cardElementOptions} />

              <label>CVC</label>
              <CardCvcElement options={cardElementOptions} />
            </div>
            <div className='justify-content-center d-flex'>
              <button type="submit" disabled={!stripe} className='pay-btn'>Pay</button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
     {isLoading && <Loading />}
    </>
  );
};

export default StripePayment;
