import React, { useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Loading from "../../../common/Loading";
import { getHostDetails } from "../../../services/api/user-auth.api";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

function Location({ prevHook, InputEventHook, eventInfoDetails }) {
  const [loading, setLoading] = useState(false);
  const prev = () => {
    prevHook();
  };

  const [showAddress] = useState([
    { label: "Yes", value: true },
    { label: "No", value: false },
  ]);
  const schema = yup.object().shape({
    placeName: yup.string(),
    primaryAddress: yup
      .string()
      .nullable()
      .required("Address is required")
      .typeError("Number of guest is required"),
    secondaryAddress: yup.string(),
    city: yup
      .string()
      .nullable()
      .required("City is required")
      .typeError("City is required"),
    state: yup
      .string()
      .nullable()
      .required("State is required")
      .typeError("State is required"),
    zipCode: yup
      .number()
      .nullable()
      .required("Zipcode is required")
      .typeError("Zipcode is required"),
    country: yup
      .string()
      .nullable()
      .required("Country is required")
      .typeError("Country is required"),
    privateAddress: yup.boolean(),
  });
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      privateAddress: eventInfoDetails?.privateAddress || false,
    },
  });
  const nextSubmit = (formData) => {
    setLoading(true);
    const stepId = 3;
    InputEventHook(formData, stepId);
  };
  useEffect(() => {
    getHost();
    if (eventInfoDetails) {
      const field = [
        "placeName",
        "primaryAddress",
        "secondaryAddress",
        "city",
        "state",
        "zipCode",
        "country",
        "privateAddress",
      ];
      field.forEach((field) => setValue(field, eventInfoDetails[field]));
    }
  }, [eventInfoDetails]);

  const getHost = async () => {
    try {
      const host = await getHostDetails();
      setValue("country", host.country);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit(nextSubmit)}>
        <div id="pills-tab3">
          {/* <!-- tab3 --> */}
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="create-event-tab">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label className="form-label input-label">
                        Place Name
                      </label>
                      <input
                        className="form-control textarea-ht"
                        placeholder="name of the restaurant / cafe or any name that you would want to call it if it is your private space"
                        {...register("placeName")}
                        id="floatingTextarea2"
                      ></input>
                      {errors?.primaryAddress && (
                        <span className="form-error">
                          {errors.primaryAddress.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label className="form-label input-label">
                        Address1
                        <sup className="req">*</sup>
                      </label>
                      <textarea
                        className="form-control textarea-ht"
                        placeholder="Leave a comment here"
                        {...register("primaryAddress")}
                        id="floatingTextarea2"
                      ></textarea>
                      {errors?.primaryAddress && (
                        <span className="form-error">
                          {errors.primaryAddress.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label className="form-label input-label">Address2</label>
                      <textarea
                        className="form-control textarea-ht"
                        placeholder="Leave a comment here"
                        {...register("secondaryAddress")}
                        id="floatingTextarea2"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="mb-3">
                      <label className="form-label input-label">
                        Country
                        <sup className="req">*</sup>
                      </label>
                      <input
                        readOnly
                        type="text"
                        className="form-control"
                        {...register("country")}
                        placeholder="Enter your country"
                      />
                      {errors?.country && (
                        <span className="form-error">
                          {errors.country.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="mb-3">
                      <label className="form-label input-label">
                        State
                        <sup className="req">*</sup>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("state")}
                        placeholder="Enter your state"
                      />
                      {errors?.state && (
                        <span className="form-error">
                          {errors.state.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="mb-3">
                      <label className="form-label input-label">
                        City
                        <sup className="req">*</sup>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("city")}
                        placeholder="Enter your city"
                      />
                      {errors?.city && (
                        <span className="form-error">
                          {errors.city.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="mb-3">
                      <label className="form-label input-label">
                        Zipcode
                        <sup className="req">*</sup>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        {...register("zipCode")}
                        placeholder="Enter your zipcode"
                      />
                      {errors?.zipCode && (
                        <span className="form-error">
                          {errors.zipCode.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label className="form-label input-label">
                        Do You Want To Show The Address On The Event Page or Not
                        ?
                      </label>
                      <p className="guest__Label">
                        in case you are hosting at your home / private space,
                        the exact address details will only be emailed to
                        confirmed guests
                      </p>
                      <div className="radio-btn">
                        <ButtonGroup className="mb-2 gap-3">
                          {showAddress.map((item, idx) => (
                            <ToggleButton
                              key={idx}
                              id={`radio-${idx}`}
                              type="radio"
                              name="privateAddress"
                              variant="outline-primary toggle-btn"
                              {...register("privateAddress")}
                              checked={watch("privateAddress") === item.value}
                              onChange={() =>
                                setValue("privateAddress", item.value)
                              }
                              value={item.value}
                            >
                              {item.label}
                            </ToggleButton>
                          ))}
                        </ButtonGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="submit-btn next-btn justify-content-end">
                <div className="submit-btn next-btn text-end">
                  <div>
                    <button className="prev-arrow" onClick={prev}>
                      <FaArrowLeftLong />
                    </button>
                  </div>
                  <div>
                    <button className="btn" type="submit">
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {loading && <Loading />}
    </div>
  );
}

export default Location;
