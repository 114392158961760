import React, { useState } from "react";
import { IoMdInformationCircleOutline } from "react-icons/io";
import Loading from "../../../common/Loading";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  changePassword,
  deleteUserProfile,
} from "../../../services/api/user-auth.api";
import Toaster from "../../../common/Toaster";
import { useNavigate } from "react-router-dom";
import { PiEyeClosedLight, PiEyeThin } from "react-icons/pi";
import { removeAccessToken, removeHostId, getEmail } from "../../../util/auth";
import { removeEmail } from "../../../util/auth";
import { setLogout } from "../../../redux/slice/enableLogoutSlice";
import { useDispatch } from "react-redux";

function AccountSettings() {
  const [isLoading, setIsLoading] = useState(false);
  const [showPswd, setShowPswd] = useState(false);
  const [showPswd1, setShowPswd1] = useState(false);
  const [showPswd2, setShowPswd2] = useState(false);
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let schema = yup.object().shape({
    currentPassword: yup.string().required("Current password is required"),
    newPassword: yup.string().required("New password is required"),
    confirmPassword: yup
      .string()
      .required("Confirm password is required")
      .oneOf([yup.ref("newPassword"), null], "Password must match"),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const update = async (data) => {
    setIsLoading(true);
    try {
      const change = await changePassword(data);
      setIsLoading(false);
      if (change && change.succeeded === true) {
        Toaster("Password updated successfully", "success");
        navigate("/login");
      } else {
        Toaster("Current password is Mismatched", "error");
      }
    } catch (error) {
      Toaster("Password Changing failed, Try again later!", "error");
      setIsLoading(false);
    }
  };
  const handleDelete = async () => {
    try {
      const change = await deleteUserProfile(password);
      dispatch(setLogout(true));
      navigate("/");
      Toaster("Account deleted successfully", "success");
    } catch (error) {
      Toaster("Please give a valid password to delete the account", "error");
    }
  };
  return (
    <>
      {/* <!-- change password --> */}
      <div className="profile-info">
        <div>
          <p className="profile-title">Change my password</p>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="bx-shadow">
              <div className="sign-in-form contact-form becomehost-txt ">
                <div>
                  <form onSubmit={handleSubmit(update)}>
                    <div className="row gy-3">
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3 icon-password">
                          <label className="form-label input-label">
                            Current password
                          </label>
                          <input
                            type={showPswd ? "text" : "password"}
                            className="form-control icon-password"
                            placeholder=""
                            {...register("currentPassword")}
                          />
                          {errors?.currentPassword && (
                            <span className="form-error">
                              {errors.currentPassword.message}
                            </span>
                          )}
                          <div
                            className="password-icon"
                            onClick={() => setShowPswd(!showPswd)}
                          >
                            {!showPswd && <PiEyeClosedLight />}
                            {showPswd && <PiEyeThin />}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3 icon-password">
                          <label className="form-label input-label">
                            New Password
                          </label>
                          <input
                            type={showPswd1 ? "text" : "password"}
                            className="form-control"
                            placeholder=""
                            {...register("newPassword")}
                          />
                          {errors?.newPassword && (
                            <span className="form-error">
                              {errors.newPassword.message}
                            </span>
                          )}
                          <div
                            className="password-icon"
                            onClick={() => setShowPswd1(!showPswd1)}
                          >
                            {!showPswd1 && <PiEyeClosedLight />}
                            {showPswd1 && <PiEyeThin />}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3 icon-password">
                          <label className="form-label input-label">
                            Confirm new Password
                          </label>
                          <input
                            type={showPswd2 ? "text" : "password"}
                            className="form-control"
                            placeholder=""
                            {...register("confirmPassword")}
                          />
                          {errors?.confirmPassword && (
                            <span className="form-error">
                              {errors.confirmPassword.message}
                            </span>
                          )}
                          <div
                            className="password-icon"
                            onClick={() => setShowPswd2(!showPswd2)}
                          >
                            {!showPswd2 && <PiEyeClosedLight />}
                            {showPswd2 && <PiEyeThin />}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mt-0">
                        <div className="ok-btn text-end ">
                          <button className="btn ">Save</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- delete account --> */}
      <div className="profile-info">
        <div>
          <p className="profile-title">Delete my account</p>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="bx-shadow">
              <div className="sign-in-form contact-form becomehost-txt">
                <div className="ac-delete-alert">
                  <p>
                    {" "}
                    <IoMdInformationCircleOutline className="me-2" />
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Nostrum sed reiciendis nesciunt quas odit esse libero eius
                    ratione impedit nemo laboriosam voluptatem ipsum ipsam
                    voluptatum illum, veniam illo, hic inventore?
                  </p>
                </div>
                <div>
                  <div className="row gy-3">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label input-label">
                          Password
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-12 mt-0">
                      <div className="ok-btn text-end">
                        <button className="btn" onClick={handleDelete}>
                          Delete account
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountSettings;
