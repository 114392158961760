import React, { useState, useEffect } from "react";
import img1 from "../../assets/image/becomehost-pattern.png";
import img2 from "../../assets/image/becmehost3-img.png";
import facebook from "../../assets/image/facebook.png";
import insta from "../../assets/image/instagram-icon.png";
import twitter from "../../assets/image/twitter.png";
import other from "../../assets/image/other.png";
import trip from "../../assets/image/trip-advisor.png";
import website from "../../assets/image/website.png";
import { FaArrowLeft } from "react-icons/fa";
import { getQualityExperience } from "../../services/api/site-info.api";
import "../host/host.scss";

function HostingExperience({ prevHook, InputEventHook, hostData }) {
  const [formValues, setFormValues] = useState({
    HostingExperienceQualify: 1,
    aboutFoodJourny: null,
    facebookId: null,
    googleId: null,
    twitterId: null,
    instagramId: null,
  });
  const [error, setError] = useState(false);
  const [experience, setExperience] = useState(null);

  const prev = () => {
    prevHook();
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    formValues.aboutFoodJourny ? setError(false) : setError(true);
  };
  const handleSubmit = () => {
    if (
      formValues.aboutFoodJourny === null ||
      formValues.aboutFoodJourny === ""
    ) {
      setError(true);
      return;
    }
    const stepId = 3;
    InputEventHook(formValues, stepId);
  };
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getQualityExperience();
        setExperience(response);
        if (hostData) {
          setFormValues((prev) => ({
            ...prev,
            HostingExperienceQualify: hostData?.HostingExperienceQualify || 1,
            aboutFoodJourny: hostData?.aboutFoodJourny || null,
            facebookId: hostData?.facebookId || null,
            googleId: hostData?.googleId || null,
            twitterId: hostData?.twitterId || null,
            instagramId: hostData?.instagramId || null,
          }));
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);
  return (
    <div className="host-wrapper">
      <div className="become-host-sec">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-lg-6 col-md-12 my-auto">
              <div className="becomehost-img">
                <img src={img2} alt="meet-at-table" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="sign-in-form becomehost-txt shadow">
                <div>
                  <h3>What is your expertise and your story?</h3>
                  <p>
                    What is your culinary background? Have you hosted events
                    before? Have you trained in the food industry? Tell us about
                    your experience and your skill level.
                  </p>
                  <h4>How would you qualify your hosting experience?</h4>
                </div>
                <div className="bcm-radio-btn-sec d-flex">
                  {experience?.length &&
                    experience.map((list) => (
                      <div className="form-check radio-btn" key={list.id}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="HostingExperienceQualify"
                          id={`flexRadio${list.id}`}
                          checked={
                            formValues.HostingExperienceQualify === list.id
                          }
                          onChange={handleInputChange}
                          value={list.id}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`flexRadio${list.id}`}
                        >
                          {list.hostingExperience}
                        </label>
                      </div>
                    ))}
                </div>
                <div>
                  <div>
                    <h4>Your online profiles</h4>
                    <p className="text-dark">
                      Do you post, story, tweet, write or tell the world about
                      your culinary skills on social media? We want to see it!
                    </p>
                  </div>
                  <div className="row g-3">
                    <div className="col-xl-4 col-lg-6 col-md-4">
                      <div className="bc-host-scl">
                        <div className="bc-host-scl-img">
                          <img src={insta} alt="meet-at-table" />
                        </div>
                        <input
                          className="bc-host-scl-name"
                          placeholder="Instagram"
                          name="instagramId"
                          onChange={handleInputChange}
                          value={formValues.instagramId}
                        />
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-4">
                      <div className="bc-host-scl">
                        <div className="bc-host-scl-img">
                          <img src={twitter} alt="meet-at-table" />
                        </div>
                        <input
                          className="bc-host-scl-name"
                          placeholder="Twitter"
                          name="twitterId"
                          onChange={handleInputChange}
                          value={formValues.twitterId}
                        />
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-4">
                      <div className="bc-host-scl">
                        <div className="bc-host-scl-img">
                          <img src={website} alt="meet-at-table" />
                        </div>
                        <input
                          className="bc-host-scl-name"
                          placeholder="Website"
                          name="googleId"
                          onChange={handleInputChange}
                          value={formValues.googleId}
                        />
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-4">
                      <div className="bc-host-scl">
                        <div className="bc-host-scl-img">
                          <img src={facebook} alt="meet-at-table" />
                        </div>
                        <input
                          className="bc-host-scl-name"
                          placeholder="Facebook"
                          name="facebookId"
                          onChange={handleInputChange}
                          value={formValues.facebookId}
                        />
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-4">
                      <div className="bc-host-scl">
                        <div className="bc-host-scl-img">
                          <img src={other} alt="meet-at-table" />
                        </div>
                        <input
                          className="bc-host-scl-name"
                          placeholder="Other"
                        />
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-4">
                      <div className="bc-host-scl">
                        <div className="bc-host-scl-img">
                          <img src={trip} alt="meet-at-table" />
                        </div>
                        <input
                          className="bc-host-scl-name"
                          placeholder="Trip Advisor"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bcm-host-text-border">
                  <h4>
                    Why would you be the perfect meet@table host? Tell us about
                    yourself and your food journey<sup className="req">*</sup>
                  </h4>
                  <div className="form-floating">
                    <textarea
                      className="form-control"
                      placeholder="Leave a comment here"
                      id="floatingTextarea2"
                      name="aboutFoodJourny"
                      style={{ height: "100px", padding: "10px" }}
                      onChange={handleInputChange}
                      value={formValues.aboutFoodJourny}
                    ></textarea>
                    {error && (
                      <span className="form-error">Field is required</span>
                    )}
                  </div>
                </div>

                <div>
                  <div className="submit-btn next-btn text-end">
                    <div className="next-btn1" onClick={prev}>
                      <FaArrowLeft />
                    </div>
                    <div>
                      <button
                        className="btn"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HostingExperience;
