import axios from "axios";
import { getAccessToken } from "./util/auth";
import { setLogout } from "./redux/slice/enableLogoutSlice";
import { store } from "./redux/store/store";

const axiosInstance = axios.create({
  baseURL: "https://meet-at-table-api.scopethinkers.com",
  headers: {
    "Content-Type": "application/json",
  },
});

//request
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers["authorization"] = "Bearer " + token;
    }
    if (config.data instanceof FormData) {
      delete config.headers["Content-Type"];
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.log("Authorization error, logging out...");
      store.dispatch(setLogout(true));
    }
    return Promise.reject(error);
  }
);

export { axiosInstance };
