import { Navigate } from 'react-router-dom';
import App from '../App'
import AllEvents from '../components/allEvents/AllEvents';
import CreateEvent from '../components/allEvents/createEvent/CreateEvent';
import EditEvent from '../components/allEvents/editEvent/EditEvent';
import ViewEvents from '../components/allEvents/viewEvents/ViewEvents';
import { ALL_EVENTS,CREATE_EVENT, EDIT_EVENT, EVENTS, MY_BOOKINGS } from '../const/route-url.const';
import Events from '../components/events/Events';
import GuestBookings from '../components/guestBookings/GuestBookings';

const eventRoutes = [
  {
   path: '',
   element : <App />,
   children: [
      {
       path: ALL_EVENTS,
       element: <AllEvents />,
       children: [
          {
           path: '',
           element: <ViewEvents />
          },
           {
               path: CREATE_EVENT,
               element: <CreateEvent />
           },
           {
              path: `${EDIT_EVENT}/:id`,
              element: <EditEvent />
           },
       ]
      },
      {
         path: MY_BOOKINGS,
         element: <GuestBookings />,
       },
      {
        path: EVENTS,
        children: [
           {
              path: ':id',
              element: <Events />
           }
        ]
       },
   ]
  },
  {
   path: '*',
   element: <Navigate to="/" replace />
 }
 ];

export default eventRoutes;