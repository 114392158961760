import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaCheck } from "react-icons/fa6";
import { deleteEventMenu } from "../../../../services/api/events-auth.api";
import Toaster from '../../../../common/Toaster';

function DeleteModal(props) {
  const deleteMenus = async() =>{
    try{
        const menus = await deleteEventMenu(props.menuId);
        Toaster("Menu deleted successfully", "success");
        props.onConfirm();
    }catch (error){
        Toaster("Failed", "error");
    }
  }
  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="create-event-block-main"
      >
        <Modal.Body>
         <div className="row delete-section">
            <div className="col-lg-12">
                <div className="delete-text">
                  <span>
                  <FaCheck />
                  </span>
                    <p>Are you sure want to delete !</p>
                </div>
            </div>
            <div className="col-12">
              <div className="text-center submit-btn w-100 d-flex mt-2 justify-content-center create-event-modal-btn">
              <Button onClick={()=> props.onHide()} className="delete-btn">
                      Cancel
                </Button>
                <Button onClick={deleteMenus} className="btn">
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default DeleteModal;
