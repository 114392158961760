import React from "react";
import { useEffect, useState } from "react";
import { getaboutus } from "../../../services/api/site-info.api";
import img3 from "../../../assets/image/login-pattern2.png";
import "../aboutUs/about.scss";
function AboutUs() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isFirstActive, setIsFirstActive] = useState(true);
  const slides = [
    {
      quote: "What is the city, but the people?",
      author: "Shakespeare",
    },
    {
      quote: "Me mesa es tu mesa!",
      author: "Anonymous",
    },
    {
      quote: "People who love to eat are always the best people.",
      author: "Julia Child",
    },
  ];
  const slides2 = [
    {
      quote: "Bye-bye awkward networking and swipe-right dating…",
    },
    {
      quote: "Hello real connections and meaningful hangouts!",
    },
  ];
  const [aboutUs, setAboutUs] = useState("");
  useEffect(() => {
    getAboutData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 4000);
    return () => clearInterval(interval);
  }, [slides.length]);

  const getAboutData = async () => {
    try {
      const aboutData = await getaboutus();
      setAboutUs(aboutData);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setIsFirstActive((prev) => !prev);
    }, 2000);
    return () => clearInterval(interval);
  }, [aboutUs]);

  useEffect(() => {
    const firstText = document.querySelector(".meet-us-first-text");
    const secondText = document.querySelector(".meet-us-second-text");
    if (firstText && secondText) {
      if (isFirstActive) {
        firstText.classList.add("active");
        secondText.classList.remove("active");
      } else {
        firstText.classList.remove("active");
        secondText.classList.add("active");
      }
    }
  }, [isFirstActive]);
  return (
    <>
      <div className="home-about-wrapper">
        <div className="pattern-row">
          <div className="col-12">
            <div className="pattern-2">
              <img src={img3} alt="meet-at-table" />
            </div>
          </div>
        </div>
        <div className="position-relative overflow-hidden p-s">
          <div className="shape-1"></div>
          <div className="en-center text-center">
            <h3>Meet Us</h3>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: aboutUs[0]?.text }}
            className="mb-0"
          ></div>
          <div className="shapefaq"></div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
