import Login from "../../auth/login/Login";
import { Navigate } from 'react-router-dom';
import Register from "../../auth/register/Register";
import ForgotPassword from "../../auth/forgot-password/ForgotPassword";
import Homepage from "../../components/homepage/Homepage";
import {LOGIN, REGISTER, FORGOT_PASSWORD, RESET_PASSWORD, ACTIVATION_SUCCESS, ACTIVATION_FAILED} from '../../const/route-url.const';
import ChangePassword from "../../auth/change-password/ChangePassword";
import App from '../../App'
import ActivationSuccess from "../../auth/activation-message/ActivationSuccess";
import ActivationFailed from "../../auth/activation-message/ActivationFailed";
import GoogleAuthCallback from "../../auth/GoogleAuthCallback";

 const authRoute = [
   {
    path: '',
    element : <App />,
    children: [
      {
        path: LOGIN,
        element : <Login />
       },
       {
        path: REGISTER,
        element : <Register />
       },
       {
        path: FORGOT_PASSWORD,
        element : <ForgotPassword />
       },
       {
        path: RESET_PASSWORD,
        element : <ChangePassword />
       },
       {
        path: ACTIVATION_SUCCESS,
        element : <ActivationSuccess />
       },
       {
        path: ACTIVATION_FAILED,
        element : <ActivationFailed />
       },
       {
        path:'auth/callback',
        element:<GoogleAuthCallback />
       },
       {
        path:'',
        element : <Homepage />
       },
    ]
   },
   {
    path: '*',
    element: <Navigate to="/" replace />
  }
  ];

  export default authRoute;