import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { getTermsConditions } from "../../services/api/site-info.api";
import "../modal/termsConditionModal.scss";
import { acceptedtermsandconditions } from "../../services/api/user-auth.api";
import { useSelector } from "react-redux";
const TermsConditionModal = (props) => {
  const [terms, setTerms] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState("");
  const loginToken = useSelector((state) => state.tokenRefresh.loginToken);
  const getData = async () => {
    try {
      const res = await getTermsConditions();
      setTerms(res);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isChecked) {
      setError("You must agree to the Terms & Conditions.");
    } else {
      try {
        const approved = await acceptedtermsandconditions(true);
        props.accepted(approved);
      } catch (error) {}
      setError("");
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Modal {...props} centered size="lg" backdrop="static" keyboard={false}>
        <Modal.Header className="mx-4">
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="position-relative overflow-hidden p-s termscondition-bg">
            <div className="termscondition-bg-overlay"></div>
            <div className="termscondition-bg-content">
              <div className="shape-1"></div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 my-auto">
                    <div className="tc-text">
                      <div
                        dangerouslySetInnerHTML={{ __html: terms[0]?.text }}
                        className="mb-0"
                      ></div>
                      <div className="form-check">
                        <input
                          className={`form-check-input ${
                            error ? "border-danger" : ""
                          }`}
                          type="checkbox"
                          id="flexCheckDefault"
                          checked={isChecked}
                          onChange={(e) => setIsChecked(e.target.checked)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          I agree to <span>Terms & Conditions</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {error && (
            <p className="error-message" style={{ color: "red" }}>
              {error}
            </p>
          )}
          <div className="container-fluid my-1 d-flex justify-content-end">
            <Button
              disabled={!isChecked}
              style={{ opacity: !isChecked ? 0.5 : 1 }}
              onClick={handleSubmit}
              className="btn-login"
            >
              Submit
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TermsConditionModal;
