import React, { useEffect, useState } from "react";
import img2 from "../../assets/image/badge.png";
import img3 from "../../assets/image/silver.png";
import img4 from "../../assets/image/tick.png";
import { FaArrowLeft } from "react-icons/fa";
import { getAllPlans, updateHostPlan } from "../../services/api/user-auth.api";
import HostPayment from "./HostPayment";
import "../host/host.scss";
import SuccessPaymentModal from "./modal/SuccessPaymentModal";

function HostPlan({ nextHook, prevHook, hostData, refresh }) {
  const [plans, setPlans] = useState([]);
  const [planDetail, setPlanDetail] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const next = () => {
    nextHook();
  };
  const prev = () => {
    prevHook();
  };
  const handlePlan = async (plan) => {
    try {
      const res = await updateHostPlan(hostData.id, plan.id);
      next();
      refresh();
    } catch (error) {}
  };
  useEffect(() => {
    const getPlans = async () => {
      try {
        const res = await getAllPlans();
        setPlans(res);
      } catch (error) {
        console.log(error);
      }
    };
    getPlans();
  }, []);
  // const checkStatus = async (status) => {
  //     if(status === 'success'){
  //         setModalShow(false);
  //         setModalShow1(true);
  //     }
  // }
  // const onClose = () =>{
  //     setModalShow1(false);
  //     next();
  // }
  const yearConversion = (day) => {
    const years = Math.floor(day / 365);
    const remainingDays = day % 365;
    const months = Math.floor(remainingDays / 30);
    const days = remainingDays % 30;
    const yearText = `${years > 0 ? years + " " + "year" : ""}`;
    const monthText = `${months > 0 ? months + " " + "month" : ""}`;
    const daysText = `${days > 0 ? days + " " + "day" : ""}`;
    return `Valid for ${yearText} ${monthText} ${daysText}`;
  };
  return (
    <div className="host-wrapper">
      <div className="become-host-sec">
        <div className="container">
          <div className="row gy-5 mt-3">
            {plans.length > 0 &&
              plans.map((plan) => (
                <div className="col-lg-4 col-md-6" key={plan.id}>
                  <div className="becomehost-txt">
                    <div className="badge">
                      <div className="badge-img">
                        <img src={img2} alt="meet-at-table" />
                        <div className="badge-dlr badge__dlr">
                          <p>${plan.price}</p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column h-100">
                      <div className="plan-image">
                        <img
                          src={img3}
                          alt="meet-at-table"
                          className="mw-100 mh-100"
                        />
                      </div>
                      <div className="d-flex flex-column h-100">
                        {plan.promotionalOffer !== null ? (
                          <p className="plan-offer plan__offer">
                            Promotional Offer
                          </p>
                        ) : (
                          <p className="plan-offer plan__offer invisible">
                            Test offer
                          </p>
                        )}
                        <h3
                          className={`promo__offer ${
                            plan.promotionalOffer !== null ? "strike" : ""
                          } `}
                        >
                          {plan.name}
                        </h3>
                        <div className="bcm-list">
                          <ul>
                            <li>
                              <div className="d-flex mb-3 align-items-center gap-2">
                                <div className="tick-img">
                                  <img src={img4} alt="meet-at-table" />
                                </div>
                                <div>
                                  <p className="mb-0 bcm-text">
                                    {yearConversion(plan.validDays)}
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex mb-3 align-items-center gap-2">
                                <div className="tick-img">
                                  <img src={img4} alt="meet-at-table" />
                                </div>
                                <div className="chk-txt">
                                  <p className="mb-0 bcm-text">
                                    {plan.maxNumberOfGuest} Members allowed for
                                    events
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="submit-btn next-btn text-center">
                          <div>
                            <button
                              className="btn"
                              type="submit"
                              onClick={() => handlePlan(plan)}
                            >
                              Select
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="row h-100 align-items-center">
            <div className="col-md-12">
              <div className="submit-btn next-btn">
                <div className="next-btn1" onClick={prev}>
                  <FaArrowLeft />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <HostPayment show={modalShow} onHide={() => setModalShow(false)} selectedPlan={planDetail} nextPage={next} host={hostData} onConfirm={checkStatus}/>
            <SuccessPaymentModal show={modalShow1} onHide={onClose}/> */}
    </div>
  );
}

export default HostPlan;
