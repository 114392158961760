import React, { useEffect, useState } from 'react';
import { transformImageUrl } from '../../../util/dataTransform';
import LightGallery from 'lightgallery/react';
import fjGallery from 'flickr-justified-gallery';
import lgZoom from 'lightgallery/plugins/zoom';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import upload from "../../../assets/image/upload.png";
import heart from "../../../assets/image/heart.png";
import heart_outline from "../../../assets/image/heart-outline.png";
import WishlistModal from '../modal/WishlistModal';
import {removeEventWishList} from '../../../services/api/user-auth.api';
import Toaster from '../../../common/Toaster';
import { getAccessToken } from "../../../util/auth";

function Gallery({ event,eventRefresh }) {
    const [data, setData] = useState([]);
    const [modalShow, setModalShow] = React.useState(false);
    
    useEffect(() => {
        if(event?.eventDocuments.length > 0){
                const imageData = event.eventDocuments.map((img)=> transformImageUrl(img.eventDocumentPath))
                setData(imageData);
        }
    }, [event]);
    useEffect(() => {
        if (data.length > 0) {
            fjGallery(document.querySelectorAll('.gallery'), {
                itemSelector: '.gallery__item',
                rowHeight: 250,
                lastRow: 'start',
                gutter: 8,
                rowHeightTolerance: 0.1,
                calculateItemsHeight: false,
            });
        }
    }, [data]); 
    const removeWishlist = async(id) =>{
        if(getAccessToken()){
            try{
                const data = {eventId : id}
                const res = await removeEventWishList(data);
                Toaster("Wishlist removed successfully", "success");
                eventRefresh();
            }catch(error){
                console.log(error)
            }
        }else{
            Toaster("Please login to remove events from your wishlist.", "error");
        }
      }
    const addModal = async() =>{
        if(getAccessToken()){
            setModalShow(true);
        }else {
            Toaster("Please login to add events to your wishlist.", "error");
        }
    }
    return (
        <>
        <div className='gallery-section position-relative'>
           <div className='gallery-action'>
                <div className='d-flex'>
                    {
                        event?.isWhishList ? (
                            <div className='gallery-action-icon' onClick={() => removeWishlist(event?.id)}>
                                <img src={heart}/>
                            </div> 
                        )
                        : (
                            <div className='gallery-action-icon' data-bs-toggle="modal" data-bs-target="#add-new-list" onClick={addModal}>
                             <img src={heart_outline}/>
                            </div>
                        )
                    }
                    <WishlistModal show={modalShow} onHide={() => setModalShow(false)} onConfirm={eventRefresh}/>
                </div>
            </div>
            <LightGallery
                plugins={[lgZoom,lgThumbnail]}
                mode="lg-fade"
                pager={false}
                thumbnail={true}
                elementClassNames={'gallery'}
                mobileSettings={{
                    controls: false,
                    showCloseIcon: false,
                    download: false,
                    rotate: false,
                }}
            >
                {data.map((image, index) => (
                    <a
                        key={index}
                        className="gallery__item"
                        data-src={image}
                    >
                        <img
                            className="img-responsive"
                            src={image}
                            alt={`Gallery item ${index + 1}`}
                        />
                    </a>
                ))}
            </LightGallery>
        </div>
            
        </>
           
    );
}

export default Gallery;
