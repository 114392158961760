import React, { useEffect, useState } from "react";
import "../events.scss";
import { transformImageUrl } from "../../../util/dataTransform";

function TheHost({ event }) {
  const eventImageSetup = (img) => {
    if (img) {
      const imgUrl = transformImageUrl(img);
      return imgUrl;
    }
  };
  return (
    <>
      <div className="tab-content">
        <div className="evnt-host-tab">
          <br />
          <div className="row">
            {/* <div className="col-md-3">
            <div className="rating-profile">
                <img src={eventImageSetup(event?.userProfile)} alt="meet-at-table" />
            </div>
            </div> */}
            <div className="col-md-12">
              <div className="evnt-host-des">
                <div>
                  <h4>
                    {event?.firstName} {event?.lastName}
                  </h4>
                </div>
                <div className="evnt-exp-des">
                  <p>{event?.hostingExperience}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TheHost;
