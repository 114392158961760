import React, { useEffect, useState } from "react";
import img2 from "../../../assets/image/breadcrumb-image.png";
import "../createEvent/create-event.scss";
import { FaCircleCheck } from "react-icons/fa6";
import EventInfo from "./EventInfo";
import BookingInfo from "./BookingInfo";
import Location from "./Location";
import ChefHost from "./ChefHost";
import FoodInfo from "./FoodInfo";
import Facilities from "./Facilities";
import EventGallery from "./EventGallery";
import {
  createHostEvent,
  createHostEventById,
  getEventById,
} from "../../../services/api/events-auth.api";
import { saveEventId, getEventId } from "../../../util/auth";
import Toaster from "../../../common/Toaster";

function CreateEvent() {
  const [tab, setTab] = useState(1);
  const [activeDiv, setActiveDiv] = useState(null);
  const [eventDetails, setEventDetails] = useState(null);
  const nextStep = () => setTab((prev) => prev + 1);
  const prevStep = () => setTab((prev) => prev - 1);

  const transformFormValues = (formValues) => {
    return Object.keys(formValues).map((key) => ({
      operationType: "Add",
      path: `/${key}`,
      op: "replace",
      value: formValues[key],
    }));
  };
  const getEventDetails = async () => {
    const eventId = await getEventId();
    try {
      const details = await getEventById(eventId);
      setEventDetails(details);
    } catch (error) {
      console.log(error);
    }
  };
  const createEvent = async (formData, stepId) => {
    const transformedData = transformFormValues(formData);
    const eventId = await getEventId();
    try {
      if (eventId) {
        const res = await createHostEventById(transformedData, eventId, stepId);
      } else {
        const res = await createHostEvent(transformedData, stepId);
        await saveEventId(res);
      }
      await getEventDetails();
      nextStep();
    } catch (error) {
      const errorMsg = error.response.data.message;
      Toaster(errorMsg, "error");
      console.log(error);
    }
  };
  const renderTabContent = () => {
    switch (tab) {
      case 1:
        return (
          <EventInfo
            nextHook={nextStep}
            prevHook={prevStep}
            InputEventHook={createEvent}
            eventInfoDetails={eventDetails}
          />
        );
      case 2:
        return (
          <BookingInfo
            nextHook={nextStep}
            prevHook={prevStep}
            InputEventHook={createEvent}
            eventInfoDetails={eventDetails}
          />
        );
      case 3:
        return (
          <Location
            nextHook={nextStep}
            prevHook={prevStep}
            InputEventHook={createEvent}
            eventInfoDetails={eventDetails}
          />
        );
      case 4:
        return (
          <ChefHost
            nextHook={nextStep}
            prevHook={prevStep}
            InputEventHook={createEvent}
            eventInfoDetails={eventDetails}
          />
        );
      case 5:
        return (
          <FoodInfo
            nextHook={nextStep}
            prevHook={prevStep}
            eventInfoDetails={eventDetails}
            InputEventHook={createEvent}
          />
        );
      case 6:
        return (
          <EventGallery
            nextHook={nextStep}
            prevHook={prevStep}
            eventInfoDetails={eventDetails}
          />
        );
      default:
        return null;
    }
  };
  useEffect(() => {
    getEventDetails();
  }, []);
  return (
    <div className="create-event-block-main">
      <div className="breadcrumb-image">
        <img src={img2} alt="meet-at-table" />
        <div className="breadcrumb-overlay"></div>
        <div className="breadcrumb-title">
          <div className="breadcrumb-header">
            <h4>Create Event</h4>
          </div>
        </div>
      </div>

      {/* <!--main section--> */}
      <main>
        {/* <!--faq--> */}
        <section>
          <div className="position-relative overflow-hidden p-s create-event">
            <div className="shape-1"></div>
            <div className="container">
              <div className="create-event-sec">
                <div className="row">
                  <div className="col-12">
                    <ul className="nav-create-event mb-0 justify-content-between">
                      <li
                        className={
                          tab === 1 ? "nav-item active" : "nav-item no-active"
                        }
                      >
                        <span className="dotted-bdr"></span>
                        <a className="nav-link" id="pills-event-inform">
                          <FaCircleCheck />
                          <span className="event-heading">Event Info</span>
                        </a>
                      </li>
                      <li
                        className={
                          tab === 2 ? "nav-item active" : "nav-item no-active"
                        }
                      >
                        <span className="dotted-bdr"></span>
                        <a className="nav-link" id="pills-booking-inform">
                          <FaCircleCheck />
                          <span className="event-heading">Booking Info</span>
                        </a>
                      </li>
                      <li
                        className={
                          tab === 3 ? "nav-item active" : "nav-item no-active"
                        }
                      >
                        <span className="dotted-bdr"></span>
                        <a className="nav-link" id="pills-event-loc">
                          <FaCircleCheck />
                          <span className="event-heading">Location</span>
                        </a>
                      </li>
                      <li
                        className={
                          tab === 4 ? "nav-item active" : "nav-item no-active"
                        }
                      >
                        <span className="dotted-bdr"></span>
                        <a className="nav-link" id="pills-chef-host">
                          <FaCircleCheck />
                          <span className="event-heading">Chef & Host</span>
                        </a>
                      </li>
                      <li
                        className={
                          tab === 5 ? "nav-item active" : "nav-item no-active"
                        }
                      >
                        <span className="dotted-bdr"></span>
                        <a className="nav-link" id="pills-menu-inform">
                          <FaCircleCheck />
                          <span className="event-heading">Food Info</span>
                        </a>
                      </li>
                      <li
                        className={
                          tab === 6 ? "nav-item active" : "nav-item no-active"
                        }
                      >
                        <span className="dotted-bdr"></span>
                        <a className="nav-link" id="pills-event-gallery">
                          <FaCircleCheck />
                          <span className="event-heading">Photos</span>
                        </a>
                      </li>
                    </ul>
                    <div>{renderTabContent()}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="shapefaq"></div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default CreateEvent;
