import React, { useEffect, useState } from "react";
import {
  getBookingEvents,
  approveUserByHost,
  rejectUserByHost,
  ischeckguestcount,
} from "../../../services/api/events-auth.api";
import Toaster from "../../../common/Toaster";
import Loading from "../../../common/Loading";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { AiOutlineCheckSquare } from "react-icons/ai";
import ConfirmBookingModal from "./modal/confirmBookingModal";
import RejectBookingModal from "./modal/rejectBookingModal";
import CancelBookingModal from "./modal/cancelBookingModal";

function BookingDetailsTab({ event }) {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [guestCount, setGuestsCount] = useState();
  const [bookingDetail, setBookingDetail] = useState();
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow1, setModalShow1] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);

  useEffect(() => {
    if (event?.id) {
      fetchData();
      checkGuestCount();
    }
  }, [event]);
  const fetchData = async () => {
    try {
      const res = await getBookingEvents(event?.id);
      setTableData(res);
    } catch (err) {
      console.log(err);
    }
  };
  const confirmModal = async (bookingItem) => {
    setBookingDetail(bookingItem);
    setModalShow(true);
  };
  const rejectModal = async (bookingItem) => {
    setBookingDetail(bookingItem);
    setModalShow1(true);
  };
  const cancelBokking = async (bookingItem) => {
    await setBookingDetail(bookingItem);
    await setModalShow2(true);
  };
  const handleAccept = async () => {
    setIsLoading(true);
    try {
      const data = {
        id: bookingDetail.id,
        link: null,
      };
      const response = await approveUserByHost(data);
      setModalShow(false);
      Toaster(
        `Booking with ID ${bookingDetail.id} has been accepted!`,
        "success"
      );
      fetchData();
      checkGuestCount();
    } catch (err) {
      const error = err?.response.data.split(":");
      Toaster(
        `Only ${error[1]} seats left. Approval limited to available seats.`,
        "error"
      );
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReject = async () => {
    setIsLoading(true);
    try {
      const response = await rejectUserByHost(bookingDetail.id);
      setModalShow1(false);
      Toaster(
        `Booking with ID ${bookingDetail.id} has been rejected!`,
        "success"
      );
      fetchData();
      checkGuestCount();
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const checkGuestCount = async () => {
    try {
      const data = await ischeckguestcount(event?.id);
      setGuestsCount(data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="col-md-12 mx-auto">
      <div className="create-event-tab edit-booking-tab">
        <div className="row">
          <div className="col-12">
            <div className="table-item">
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Email Id</th>
                    <th scope="col">Number of seats</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Transaction Id</th>
                    <th scope="col">Actions</th>
                    <th scope="col">Cancel Bookings</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.length > 0 ? (
                    tableData.map((item, index) => (
                      <tr
                        key={index}
                        className={`${
                          item.bookingStatus === "Pending" && guestCount === 0
                            ? "row-disabled"
                            : ""
                        }`}
                      >
                        <td className="item-name">
                          <p>{item.firstName}</p>
                        </td>
                        <td>
                          <p>{item.lastName}</p>
                        </td>
                        <td>
                          <p>{item.email ? item.email : "-"}</p>
                        </td>
                        <td>
                          <p>{item.noOfBookingSheets}</p>
                        </td>
                        <td>
                          <p>{item.amount ? item.amount : "-"}</p>
                        </td>
                        <td>
                          <p>{item.transactionId ? item.transactionId : "-"}</p>
                        </td>
                        <td>
                          {item.bookingStatus === "Approved" && (
                            <span className="text-success">Approved</span>
                          )}
                          {item.bookingStatus === "Rejected" && (
                            <span className="text-danger">Rejected</span>
                          )}
                          {item.bookingStatus === "WaitingForPayment" && (
                            <span className="wait-label">
                              Waiting for payment
                            </span>
                          )}
                          {item.bookingStatus === "failed" && (
                            <span className="text-danger">
                              Transaction failed
                            </span>
                          )}
                          {!event?.isAuto &&
                            item.bookingStatus === "Pending" && (
                              <>
                                <div className="d-flex gap-3">
                                  <div onClick={() => confirmModal(item)}>
                                    <AiOutlineCheckSquare className="check-icon" />
                                  </div>
                                  <div onClick={() => rejectModal(item)}>
                                    <AiOutlineCloseSquare className="close-icon" />
                                  </div>
                                </div>
                              </>
                            )}
                        </td>
                        <td>
                          {item.bookingStatus === "WaitingForPayment" ? (
                            <button
                              className="reject-btn"
                              onClick={() => cancelBokking(item)}
                            >
                              Cancel
                            </button>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center">
                        <p>No bookings for this event</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ConfirmBookingModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onConfirm={handleAccept}
      />
      <RejectBookingModal
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        onConfirm={handleReject}
      />
      <CancelBookingModal
        show={modalShow2}
        onHide={() => setModalShow2(false)}
        data={bookingDetail}
        onConfirm={fetchData}
      />
      {isLoading && <Loading />}
    </div>
  );
}

export default BookingDetailsTab;
