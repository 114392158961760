import React, { useContext, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { ChatContext } from "../../../context/ChatContext";
import {
  arrayUnion,
  doc,
  serverTimestamp,
  Timestamp,
  updateDoc,
  getDoc, // Import getDoc to check document existence
} from "firebase/firestore";
import { db, storage } from "../../../firebase";
import { v4 as uuid } from "uuid";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { FaPaperPlane } from "react-icons/fa6";
import { FaImage } from "react-icons/fa6";

const Input = () => {
  const [text, setText] = useState("");
  const [img, setImg] = useState(null);

  const { currentUser } = useContext(AuthContext);
  const { data } = useContext(ChatContext);

  const handleSend = async () => {
    if (text === "" && img == null) return;
    try {
      if (img) {
        const storageRef = ref(storage, uuid());
        const uploadTask = uploadBytesResumable(storageRef, img);

        await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            null,
            (error) => reject(error),
            () => {
              getDownloadURL(uploadTask.snapshot.ref)
                .then(resolve)
                .catch(reject);
            }
          );
        }).then(async (downloadURL) => {
          await updateDoc(doc(db, "chats", data.chatId), {
            messages: arrayUnion({
              id: uuid(),
              text,
              senderId: currentUser.uid,
              date: Timestamp.now(),
              img: downloadURL,
            }),
          });
        });
      } else {
        await updateDoc(doc(db, "chats", data.chatId), {
          messages: arrayUnion({
            id: uuid(),
            text,
            senderId: currentUser.uid,
            date: Timestamp.now(),
          }),
        });
      }

      // Check and update userchats document existence
      const currentUserDoc = doc(db, "userchats", currentUser.uid);
      const currentUserDocSnap = await getDoc(currentUserDoc);
      if (currentUserDocSnap.exists()) {
        await updateDoc(currentUserDoc, {
          [data.chatId + ".lastMessage"]: {
            text,
          },
          [data.chatId + ".date"]: serverTimestamp(),
        });
      }

      const otherUserDoc = doc(db, "userchats", data.user.uid);
      const otherUserDocSnap = await getDoc(otherUserDoc);
      if (otherUserDocSnap.exists()) {
        await updateDoc(otherUserDoc, {
          [data.chatId + ".lastMessage"]: {
            text,
          },
          [data.chatId + ".date"]: serverTimestamp(),
        });
      }

      setText("");
      setImg(null);
    } catch (error) {
      console.error("Error sending message: ", error);
    }
  };

  const fileSelectedHandler = (e) => {
    if (e.target.files[0]) {
      setImg(e.target.files[0]);
      handleSend();
    }
  };

  return (
    <div className="input send-msg">
      <input
        type="text"
        placeholder="Type something..."
        onChange={(e) => setText(e.target.value)}
        value={text}
      />
      <div className="upload-image">
        <input
          type="file"
          style={{ display: "none" }}
          id="file"
          onChange={(e) => setImg(e.target.files[0])}
        />
        <label htmlFor="file" className="custom-file-upload">
          <FaImage />
          <input type="file" onChange={fileSelectedHandler} />
        </label>
      </div>
      <div className="send send-icon" onClick={handleSend}>
        {/* <img src={Attach} alt="" /> */}

        <span>
          <FaPaperPlane />
        </span>
      </div>
    </div>
  );
};

export default Input;
