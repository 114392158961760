import React, { useEffect, useState } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import "../myWishlists/wishlistEvent.scss";
import {
  getWishlist,
  removeWishlist,
} from "../../../services/api/user-auth.api";
import Loading from "../../../common/Loading";
import { transformImageUrl } from "../../../util/dataTransform";
import noPhoto from "../../../assets/image/noPhoto.png";
import Toaster from "../../../common/Toaster";
import { EVENTS } from "../../../const/route-url.const";
import { useNavigate } from "react-router-dom";

function WishlistEvents() {
  const [wishList, setWishList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const redirectToEvent = (item) => {
    navigate(`/${EVENTS}/${item.eventId}`);
  };
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await getWishlist();
      setWishList(res);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const eventImageSetup = (imgData) => {
    if (imgData) {
      const docUrl = transformImageUrl(imgData?.eventDocumentPath);
      return docUrl;
    } else {
      return noPhoto;
    }
  };
  const handleDelete = async (e, itemId) => {
    e.stopPropagation();
    try {
      const res = await removeWishlist(itemId);
      Toaster("Wishlist deleted successfully", "success");
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="row mt-4">
      {wishList.length > 0 ? (
        wishList.map((item, index) => (
          <div
            className="col-md-6 col-12 mb-3 col-xl-3 cursor-pointer"
            key={index}
            onClick={() => redirectToEvent(item)}
          >
            <div className="position-relative">
              <div className="tab-img tab__img">
                <img
                  src={eventImageSetup(item.eventImage)}
                  alt="meet-at-table"
                />
                <p className="online-evnt text-truncate">{item.eventTitle}</p>
              </div>
              <div className="d-flex trash-icon">
                <a
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#delete"
                  onClick={(e) => handleDelete(e, item.id)}
                >
                  <RiDeleteBinLine />
                </a>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className="empty-list">
          Your wishlist is empty! Start adding items you love.
        </p>
      )}
      {isLoading && <Loading />}
    </div>
  );
}

export default WishlistEvents;
