import React, { useEffect, useState } from "react";
import img2 from "../../../assets/image/login-pattern2.png";
import "../termsAndConditions/termsCondition.scss";
import { Link } from "react-router-dom";
import banner from "../../../assets/image/new-banner-video.mp4";
import { getTermsConditions } from "../../../services/api/site-info.api";

function TermsAndConditions() {
  const [terms, setTerms] = useState("");

  const getData = async () => {
    try {
      const res = await getTermsConditions();
      setTerms(res);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="termsCondition-wrapper">
        {/* <!--breadcrumb--> */}
        <div className="breadcrumb-image">
          {/* <img src={img2} alt="meet-at-table"/> */}
          <video autoPlay muted loop>
            <source src={banner} type="video/mp4" />
          </video>
          <div className="breadcrumb-overlay"></div>
          <div className="breadcrumb-title">
            <div className="breadcrumb-header">
              <h4>Terms and Conditions</h4>
            </div>
          </div>
        </div>

        {/* <!--banner background--> */}
        <div className="pattern-row">
          <div className="col-12">
            <div className="pattern-2">
              <img src={img2} alt="meet-at-table" />
            </div>
          </div>
        </div>
        {/* <!--main section--> */}
        <main>
          {/* <!--faq--> */}
          <section>
            <div className="position-relative overflow-hidden p-s termscondition-bg">
              <div className="termscondition-bg-overlay"></div>
              <div className="termscondition-bg-content">
                <div className="shape-1"></div>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 my-auto">
                      <div className="abt-text">
                        <div
                          dangerouslySetInnerHTML={{ __html: terms[0]?.text }}
                          className="mb-0"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="shapefaq"></div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
}

export default TermsAndConditions;
