import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { getfaqs } from "../../../services/api/site-info.api";
import "../faq/home-faq.scss";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function Faq() {
  const [faqs, setFaqs] = useState([]);
  const [generalToShow, setGeneralToShow] = useState(5);
  const [guestsToShow, setGuestsToShow] = useState(5);
  const [hostsToShow, setHostsToShow] = useState(5);

  useEffect(() => {
    getFaqData();
  }, []);

  const getFaqData = async () => {
    try {
      const faqData = await getfaqs();
      setFaqs(faqData);
    } catch (error) {
      console.log(error);
    }
  };

  const showMore = (tab) => {
    if (tab === "general")
      setGeneralToShow(faqs.filter((faq) => faq.categoryId === 0).length);
    if (tab === "guests")
      setGuestsToShow(faqs.filter((faq) => faq.categoryId === 1).length);
    if (tab === "hosts")
      setHostsToShow(faqs.filter((faq) => faq.categoryId === 2).length);
  };

  const showLess = (tab) => {
    if (tab === "general") setGeneralToShow(5);
    if (tab === "guests") setGuestsToShow(5);
    if (tab === "hosts") setHostsToShow(5);
  };

  const renderFaqs = (categoryId, itemsToShow) => {
    const filteredFaqs = faqs.filter((faq) => faq?.categoryId === categoryId);
    return (
      filteredFaqs?.length > 0 &&
      filteredFaqs.slice(0, itemsToShow).map((faq) => (
        <Accordion.Item eventKey={faq?.id} key={faq?.id}>
          <Accordion.Header>{faq?.question}</Accordion.Header>
          <Accordion.Body className="accordion-body">
            <p
              dangerouslySetInnerHTML={{ __html: faq?.answer }}
              className="mb-0"
            ></p>
          </Accordion.Body>
        </Accordion.Item>
      ))
    );
  };

  const renderShowMoreLessButton = (itemsToShow, totalLength, tab) => {
    if (totalLength > 5) {
      return (
        <div className="pointer me-3 text-end">
          {itemsToShow === 5 ? (
            <button onClick={() => showMore(tab)} className="show-more">
              Show More
            </button>
          ) : (
            <button onClick={() => showLess(tab)} className="show-more">
              Show Less
            </button>
          )}
        </div>
      );
    }
    return null;
  };
  return (
    <>
      <div className="home-faq-wrapper">
        <div className="en">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 mx-auto">
                <div className="en-center faq-txt text-center">
                  <h3>FAQ</h3>
                  <p>
                    Search our knowledge base for answers to common questions
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="menu-tab">
                  <Tabs
                    defaultActiveKey="general"
                    id="controlled-tab-example"
                    className="nav-underline"
                  >
                    {/* General Tab */}
                    <Tab
                      eventKey="general"
                      className="nav-item"
                      title="General"
                    >
                      <div className="col-md-12 my-3">
                        <Accordion className="faq-accordion h-100">
                          {renderFaqs(0, generalToShow)}
                        </Accordion>
                        {renderShowMoreLessButton(
                          generalToShow,
                          faqs?.filter((faq) => faq.categoryId === 0).length,
                          "general"
                        )}
                      </div>
                    </Tab>

                    {/* Guests Tab */}
                    <Tab eventKey="guests" className="nav-item " title="Guests">
                      <div className="col-md-12 my-3">
                        <Accordion className="faq-accordion h-100">
                          {renderFaqs(1, guestsToShow)}
                        </Accordion>
                        {renderShowMoreLessButton(
                          guestsToShow,
                          faqs?.filter((faq) => faq.categoryId === 1).length,
                          "guests"
                        )}
                      </div>
                    </Tab>

                    {/* Hosts Tab */}
                    <Tab eventKey="hosts" className="nav-item" title="Hosts">
                      <div className="col-md-12  my-3">
                        <Accordion className="faq-accordion h-100">
                          {renderFaqs(2, hostsToShow)}
                        </Accordion>
                        {renderShowMoreLessButton(
                          hostsToShow,
                          faqs?.filter((faq) => faq.categoryId === 2).length,
                          "hosts"
                        )}
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Faq;
