import React, { useEffect, useState } from "react";
import img5 from "../../../assets/image/evnt1-hm.png";
import img6 from "../../../assets/image/faq-bg.png";
import img7 from "../../../assets/image/evnt2-hm.png";
import { getNearByEvents } from "../../../services/api/events-auth.api";
import "../events-near/eventsNear.scss";
import EventsSlider from "./EventsSlider";

function EventsNear() {
  const [activeIndex, setActiveIndex] = useState(0);
  const slides = [
    {
      quote: "Food is our common ground, a universal experience.",
      author: "James Beard",
    },
    {
      quote:
        "Trade couch time for coffee time - your mental health will thank you.",
      author: "Anonymous",
    },
    {
      quote: "There is no love sincerer than the love of food.",
      author: "George Bernard Shaw",
    },
    {
      quote: "Food is not just what we eat; it’s how we connect.",
      author: "Anonymous",
    },
    {
      quote:
        "Food brings us together, and it is often the centerpiece of our shared experiences.",
      author: "Michael Pollan",
    },
    {
      quote: "In the end, it's not about the food; it's about the company.",
      author: "Anonymous",
    },
    {
      quote:
        "The table is a place for sharing; it’s where we break bread and build relationships.",
      author: "Anonymous",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 4000);
    return () => clearInterval(interval);
  }, [slides.length]);
  const [events, setEvents] = useState([]);
  const [location, setLocation] = useState({ lat: null, lng: null });
  const [error, setError] = useState(null);
  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };
  const getAllEvents = async () => {
    try {
      const res = await getNearByEvents(location);
      const formattedRes = chunkArray(res, 3);
      setEvents(formattedRes);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ lat: latitude, lng: longitude });
            setError(null);
          },
          (err) => {
            setError(err.message);
          }
        );
      } else {
        setError("Geolocation is not supported by this browser.");
      }
    };
    getLocation();
  }, []);
  useEffect(() => {
    if ((location.lat != null) & (location.lng != null)) {
      getAllEvents();
    }
  }, [location]);
  return (
    <>
      {error ? (
        <></>
      ) : (
        <div className="eventsNear-wrapper">
          {/* slider */}
          {/* Prev slide */}
          <div className="en position-relative evnt-hm">
            <div className="shape-2"></div>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 my-auto">
                  <div>
                    <EventsSlider eventItem={events} />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 mx-auto d-flex align-items-center justify-content-center  flex-column">
                  <div className="en-center  text-center">
                    <h5>Come, add some flavour to your social calendar! </h5>
                    <h3>What’s Cooking Around You ?</h3>
                    <p>
                      {` Here's a great chance to enjoy good food and make new
                      friends while having a gala time. Put your wellbeing on
                      the menu - and check out these exciting gatherings near
                      you!(psst, it’s easy “pea”sy to join - simply click the
                      event you like -> check the details -> click join)`}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row gap-2 gap-lg-0">
                <div className="col-lg-6 col-md-12 mx-auto d-flex justify-content-center align-items-center">
                  <div className="slider">
                    {slides.map((slide, index) => (
                      <div
                        key={index}
                        className={`slide ${
                          index === activeIndex ? "active" : "inactive"
                        }`}
                      >
                        <div className="blockquote">
                          <h3>
                            <span>{slide.quote}</span>
                          </h3>
                          <h4>
                            <em>- {slide.author}</em>
                          </h4>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="faq-bg h-100">
                    <div className="faq-img">
                      <div className="faq2-img">
                        <img src={img5} alt="meet-at-table" />
                        {/* <div className="faq-bg-img">
                          <img src={img6} alt="meet-at-table" />
                        </div> */}
                      </div>
                      <div className="faq1-img">
                        <img src={img7} alt="meet-at-table" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EventsNear;
