import React, { useEffect, useState } from "react";
import noPhoto from "../../assets/image/noPhoto.png";
import img3 from "../../assets/image/tom.png";
import { useNavigate } from "react-router-dom";
import { MdOutlineStar } from "react-icons/md";
import { SlLocationPin } from "react-icons/sl";
import "../guestBookings/guestBooking.scss";
import img2 from "../../assets/image/breadcrumb-image.png";
import Pagination from "../../common/Pagination";
import { myBookingsEvents } from "../../services/api/events-auth.api";
import { EVENTS } from "../../const/route-url.const";
import { transformImageUrl } from "../../util/dataTransform";

function GuestBookings() {
  const navigate = useNavigate();
  const [totalCount, setTotalCount] = useState([]);
  const [bookedEvent, setBookedEvent] = useState([]);
  const [bookedPayload, setBookedPayload] = useState({
    pageId: 1,
    pageSize: 10,
  });
  const eventImageSetup = (arrDoc) => {
    if (arrDoc[0]?.eventDocumentPath) {
      const docUrl = transformImageUrl(arrDoc[0]?.eventDocumentPath);
      return docUrl;
    } else {
      return noPhoto;
    }
  };

  const myBookingEvents = async () => {
    setBookedEvent([]);
    try {
      const res = await myBookingsEvents(bookedPayload);
      setTotalCount(res.eventCount);
      setBookedEvent(res.eventsViewModel);
    } catch (error) {
      console.log(error);
    }
  };
  const redirectToEvent = (event) => {
    navigate(`/${EVENTS}/${event.id}`);
  };
  const handlePageChange = (page) => {
    setBookedPayload((prevParams) => ({
      ...prevParams,
      pageId: page,
    }));
  };

  useEffect(() => {
    myBookingEvents();
  }, [bookedPayload]);

  return (
    <div className="guest-booking">
      <div className="breadcrumb-image">
        <img src={img2} alt="meet-at-table" />
        <div className="breadcrumb-overlay"></div>
        <div className="breadcrumb-title">
          <div className="breadcrumb-header">
            <h4>My Bookings</h4>
          </div>
        </div>
      </div>
      <div className="position-relative overflow-hidden p-s menu-tab">
        <div className="container">
          <div className="tab-content p-0">
            <div className="row event-main-block menu-tab">
              {bookedEvent.length > 0 ? (
                bookedEvent.map((event) => (
                  <div
                    className="col-lg-3 col-md-6 p-3"
                    key={event.id}
                    onClick={() => redirectToEvent(event)}
                  >
                    <div className="card h-100">
                      <div className="position-relative">
                        <div className="tab-img p-0 shadow-none">
                          <img
                            src={eventImageSetup(event.eventDocuments)}
                            className="card-img-top"
                            alt="meet-at-table"
                          />
                        </div>
                        <div className="evnt-ratng-sec d-flex justify-content-between align-items-center">
                          <p className="evnt-ratng">
                            <MdOutlineStar /> 5(90)
                          </p>
                        </div>
                      </div>
                      <div className="card-body p-0">
                        <div className="tom-txt">
                          <p className="d-flex align-items-center">
                            <span className="hgt hgt-small">By</span>{" "}
                            <span className="tom-img">
                              <img src={img3} alt="meet-at-table" />
                            </span>{" "}
                            {event?.firstName} {event?.lastName}
                          </p>
                        </div>
                        <div className="tab-evnt-dlr d-flex justify-content-between flex-wrap">
                          <p>{event.eventCategory}</p>
                          <p>
                            {event.currency} {event.gustPerCost || 0}
                            <span className="hgt hgt-small">/Guest</span>
                          </p>
                        </div>
                        <div className="d-flex align-itemt-start mb-3">
                          <div className="location-icon">
                            <SlLocationPin />
                          </div>
                          <div className="tab-evnt-des">
                            <span>{event.city}</span>
                            <p className="text-truncate mb-0">
                              {event.eventTitle}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="not-found mt-3 text-center">
                  Events are not available now
                </p>
              )}
            </div>
          </div>

          <Pagination
            className="pagination-bar "
            currentPage={bookedPayload.pageId}
            totalCount={totalCount}
            pageSize={bookedPayload.pageSize}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}

export default GuestBookings;
