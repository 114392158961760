import React, { useEffect, useState } from "react";
import "../user.scss";
import {
  getAllNotification,
  sendNotification,
} from "../../../services/api/user-auth.api";
import Pagination from "../../../common/Pagination";
import Loading from "../../../common/Loading";
import { useSelector } from "react-redux";
function Notifications() {
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState([]);
  const [searchParams, setSearchParams] = useState({
    pageId: 1,
    pageSize: 10,
  });
  const [totalCount, setTotalCount] = useState([]);
  const userId = useSelector((state) => state.userEmail.userId);

  useEffect(() => {
    if (searchParams) {
      getNotification();
    }
  }, [searchParams]);
  const getNotification = async () => {
    try {
      const res = await getAllNotification(searchParams, userId);
      setIsLoading(false);
      setTotalCount(res.notificationCount);
      const formattedNotifications = res.notificationViewModel.map(
        (notification) => {
          const dateString = notification.date;
          const parsedDate = new Date(dateString);
          const formattedDate = !isNaN(parsedDate)
            ? `${String(parsedDate.getDate()).padStart(2, "0")}-${String(
                parsedDate.getMonth() + 1
              ).padStart(2, "0")}-${String(parsedDate.getFullYear())}`
            : "Invalid date";
          return {
            ...notification,
            formattedDate,
          };
        }
      );
      setNotification(formattedNotifications);
    } catch (error) {
      console.log(error);
    }
  };
  const handleBeforeUnload = async () => {
    try {
      const readMessages = await sendNotification();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    return () => {
      handleBeforeUnload();
    };
  }, []);

  const handlePageChange = (page) => {
    setIsLoading(true);
    setSearchParams((prevParams) => ({
      ...prevParams,
      pageId: page,
    }));
  };

  return (
    <>
      <div>
        <div className="profile-info">
          <div className="row">
            <div className="col-12">
              <div className="bx-shadow">
                <div className="sign-in-form contact-form becomehost-txt">
                  <table className="table notify-table">
                    <thead>
                      <tr>
                        <th className="notify-table-header col-2">Date</th>
                        <th className="notify-table-header">Notifications</th>
                      </tr>
                    </thead>
                    <tbody>
                      {notification.length === 0 ? (
                        <tr>
                          <td className="text-center">
                            No notifications found.
                          </td>
                        </tr>
                      ) : (
                        notification.map((item, index) => (
                          <tr key={index}>
                            <th className="user-date">{item.formattedDate}</th>
                            <td className="user-notify">{item.message}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Pagination
          className="pagination-bar "
          currentPage={searchParams.pageId}
          totalCount={totalCount}
          pageSize={searchParams.pageSize}
          onPageChange={handlePageChange}
        /> */}
      </div>
      {isLoading && <Loading />}
    </>
  );
}

export default Notifications;
