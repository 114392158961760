import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { setGoogleLogin, setFacebookLogin } from "../../redux/slice/tokenSlice";
import { useNavigate } from "react-router-dom";
import { googleProfileDetails } from "../../services/api/user-auth.api";
import Select from "react-select";
import { getCountries } from "../../services/api/site-info.api";
import { Link } from "react-router-dom";

const AuthProfileModal = (props) => {
  const [countryList, setCountryList] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const schema = yup.object().shape({
    firstName: yup
      .string()
      .required("First name is required")
      .min(4, "Must be at least 4 characters"),
    lastName: yup.string().required("Last name is required"),
    phoneNumber: yup
      .string()
      .required("Phone number is required")
      .matches(/^[0-9]{10}$/, "Phone number must be 10 digits"),
    city: yup.string().required("City is required"),
    country: yup.string().nullable().required("Country is required"),
    optedInForMarketingEmails: yup.boolean(),
    acceptedTermsAndConditions: yup
      .boolean()
      .oneOf([true], "You must accept the terms and conditions")
      .required("Terms acceptance is required"),
  });
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const onSubmit = async (data) => {
    setValue("country", "India");
    try {
      const res = await googleProfileDetails(data);
      dispatch(setGoogleLogin(true));
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };
  const getCountryData = async () => {
    try {
      const res = await getCountries();
      const list = res.map((option) => ({
        label: option.name,
        value: option.name,
      }));
      setCountryList(list);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCountryData();
  }, []);
  return (
    <>
      <Modal {...props} centered backdrop="static" keyboard={false}>
        <Modal.Header closeButton className="mx-4">
          <Modal.Title>Personal Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            className="auth-profile mx-3 my-2"
          >
            <Form.Group className="mb-3">
              <Form.Label>
                First Name<sup className="req">*</sup>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter first name"
                {...register("firstName")}
                isInvalid={!!errors.firstName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.firstName?.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>
                Last Name<sup className="req">*</sup>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter last name"
                {...register("lastName")}
                isInvalid={!!errors.lastName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.lastName?.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>
                Phone Number<sup className="req">*</sup>
              </Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter phone number"
                {...register("phoneNumber")}
                isInvalid={!!errors.phoneNumber}
                onKeyDown={(e) =>
                  ["e", "E", "+", "."].includes(e.key) && e.preventDefault()
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.phoneNumber?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <label className="form-label input-label">
                Country<sup className="req">*</sup>
              </label>
              <div className="selectbox-block">
                {countryList?.length && (
                  <Controller
                    className="form-control"
                    name="country"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={countryList}
                        value={countryList.find(
                          (option) => option.value === field.value
                        )}
                        onChange={(selectedOption) =>
                          field.onChange(selectedOption?.value)
                        }
                      />
                    )}
                  />
                )}
              </div>
              {errors?.country && (
                <span className="form-error">{errors.country.message}</span>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                City<sup className="req">*</sup>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter city"
                {...register("city")}
                isInvalid={!!errors.city}
              />
              <Form.Control.Feedback type="invalid">
                {errors.city?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <div className="my-4 checkbox-terms">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  {...register("optedInForMarketingEmails")}
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Yes! I want to receive exclusive Meet@table offers, travel
                  inspo and al the food in my inbox
                </label>
              </div>
              <div className="form-check">
                <input
                  className={`form-check-input ${
                    errors?.acceptedTermsAndConditions ? "border-danger" : ""
                  }`}
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  {...register("acceptedTermsAndConditions")}
                />

                <label className="form-check-label" htmlFor="flexCheckDefault">
                  By signing up, I agree to{" "}
                  <span>
                    <Link to="/terms-and-conditions" target="_blank">
                      Terms & Conditions
                    </Link>
                  </span>{" "}
                  and{" "}
                  <span>
                    <Link to="/privacy-policy" target="_blank">
                      Privacy Policy
                    </Link>
                  </span>
                  .
                </label>
              </div>
              {errors?.acceptedTermsAndConditions && (
                <span className="form-error">
                  {errors.acceptedTermsAndConditions.message}
                </span>
              )}
            </div>
            <Button
              variant="primary"
              type="submit"
              className="w-100 profile-btn"
            >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AuthProfileModal;
