import { Navigate } from "react-router-dom";
import { HOST, UPGRADE_PLAN } from "../const/route-url.const";
import App from "../App";
import Host from "../components/host/Host";
import UpgradePlan from "../components/upgradePlan/UpgradePlan";
const hostRoutes = [
  {
    path: "",
    element: <App />,
    children: [
      {
        path: HOST,
        element: <Host />,
      },
      {
        path: UPGRADE_PLAN,
        element: <UpgradePlan />,
      },
    ],
  },
];

export default hostRoutes;
