import React, { useEffect, useState, useRef } from "react";
import img2 from "../../assets/image/location.png";
import img3 from "../../assets/image/calender.png";
import img4 from "../../assets/image/guest.png";
import img5 from "../../assets/image/breadcrumb-image.png";
import img9 from "../../assets/image/filter.png";
import noPhoto from "../../assets/image/noPhoto.png";
// import blank from "../../assets/image/noData-image.png";
import "../handPickedEvents/handpicked-events.scss";
import { transformImageUrl } from "../../util/dataTransform";
import {
  getFilterEvents,
  getSearchEvents,
} from "../../services/api/events-auth.api";
import ApplyFilterModal from "./modal/ApplyFilterModal";
import { MdOutlineStar } from "react-icons/md";
import Select from "react-select";
import { EVENTS } from "../../const/route-url.const";
import { useNavigate } from "react-router-dom";
import DatePicker, { DateObject } from "react-multi-date-picker";
import Loading from "../../common/Loading";
import { useSelector } from "react-redux";
import {
  GoogleMap,
  Autocomplete,
  MarkerF,
  InfoWindowF,
  useJsApiLoader,
} from "@react-google-maps/api";
import { RiCloseLine } from "react-icons/ri";
import Pagination from "../../common/Pagination";
import { format } from "date-fns";
import { FiSearch } from "react-icons/fi";
import {
  searchBoolean,
  sendEvents,
  sendEventValues,
} from "../../redux/slice/searchSlice";
import { useDispatch } from "react-redux";
const mapContainerStyle = {
  height: "760px",
  width: "100%",
};
const center = {
  lat: 13.0827,
  lng: 80.2707,
};
export const baseURL = "https://meet-at-table-api.scopethinkers.com";
function HandPickedEvents() {
  const libraries = ["places"];
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBZw1zL4TH3Kzt0eEbt5YQ1wLY0THVk4pc",
    libraries,
  });
  const [activeButtons, setActiveButtons] = useState([]);
  const [activeMarker, setActiveMarker] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [clearSearch, setClearchSearch] = useState(false);
  const searchData = useSelector((search) => search?.searchHandle?.sendEvents);
  const searchEvent = useSelector(
    (search) => search?.searchHandle?.sendEventValues
  );
  const searchBooleanVal = useSelector(
    (search) => search?.searchHandle?.searchBoolean
  );

  const advancedSearchValues = useSelector(
    (search) => search?.searchHandle?.advancedValues
  );
  const advancedSearchBoolean = useSelector(
    (search) => search?.searchHandle?.advancedSearch
  );
  const resetAdvancedSearch = useSelector(
    (search) => search?.searchHandle?.resetAdvancedSearch
  );
  const resetBoolean = useSelector(
    (search) => search?.searchHandle?.resetBoolean
  );

  const handleMarkerClick = (marker) => {
    setActiveMarker(marker);
  };

  const handleCloseClick = () => {
    setActiveMarker(null);
  };

  const [inputValue, setInputValue] = useState();
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(center);
  const [hasPagination, setHasPagination] = useState(true);
  const inputRef = useRef(null);
  const autocompleteRef = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (searchEvent?.Address) {
      setInputValue(searchEvent?.Address);
      setSelectedPlace(searchEvent?.Address);
      setHasActiveSearch(true);
    }
  }, [searchEvent?.Address]);

  const onLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
    autocompleteRef.current = autocomplete;
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place && place.geometry) {
        const fullAddress = place.formatted_address.replace(/, /g, ",");
        setSelectedPlace(place);
        setSearchEvents((prev) => ({
          ...prev,
          Address: fullAddress,
        }));
        setInputValue(fullAddress);
        setHasActiveSearch(true);
      }
    }
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSearchClear = async () => {
    setClearchSearch(true);
    const currentDate = new Date();
    const currentDateObject = new DateObject(currentDate);
    setValues([currentDateObject, currentDateObject]);
    setActiveButtons([]);
    setData([]);
    setHasPagination(true);
    setInputValue("");
    setSelectedPlace(null);
    setHasActiveSearch(false);
    dispatch(sendEventValues([]));
    dispatch(sendEvents([]));
    dispatch(searchBoolean(false));
    setGuests(1);
    setSearchEvents((prev) => ({
      ...prev,
      Address: null,
      FromDate: "Not selected",
      ToDate: "Not selected",
    }));
    setSearchParams((prevParams) => ({
      ...prevParams,
      PageId: 1,
    }));
    fetchData(searchParams.PageId);
  };

  const locations = [
    { lat: 37.7749, lng: -122.4194 }, // San Francisco
    { lat: 34.0522, lng: -118.2437 }, // Los Angeles
    { lat: 36.1699, lng: -115.1398 }, // Las Vegas
  ];
  const [modalShow, setModalShow] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [pageEvents, setPageEvents] = useState([]);
  const [eventCategory, setEventCategory] = useState([]);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState();
  const [guests, setGuests] = useState(1);
  const [values, setValues] = useState([new DateObject(), null]);
  const [hasActiveSearch, setHasActiveSearch] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [sortParam, setSortParam] = useState("");
  const navigate = useNavigate();
  const [sortList] = useState([
    { label: "Relevance", value: null },
    { label: "Price (lowest first)", value: "lowestfirst" },
    { label: "Price (highest first)", value: "highestfirst" },
  ]);
  const [searchParams, setSearchParams] = useState({
    Sort: null,
    PageId: 1,
    PageSize: pageSize,
  });
  useEffect(() => {
    // if (searchEvent?.Address ) {
    //   setInputValue(searchEvent?.Address);
    //   setSelectedPlace(searchEvent?.Address);
    // }
    if (searchEvent?.FromDate && searchEvent?.ToDate) {
      const fromDate = new Date(searchEvent?.FromDate);
      const toDate = new Date(searchEvent?.ToDate);
      setValues([fromDate, toDate]);
      setSearchEvents(searchEvent?.NoOfGuest);
    } else {
      setValues([new DateObject(), new DateObject()]);
    }
  }, [searchEvent]);
  const incrementGuests = () => {
    setGuests((prevGuests) => prevGuests + 1);
  };
  const decrementGuests = () => {
    setGuests((prevGuests) => (prevGuests > 1 ? prevGuests - 1 : 1));
  };

  const [searchEvents, setSearchEvents] = useState({
    Address: null,
    PageId: 1,
    PageSize: 10,
  });
  const handleSelect = async (event) => {
    setIsLoading(true);
    setSearchParams((prev) => ({
      ...prev,
      Sort: event.value,
    }));
    setSearchEvents((prev) => ({
      ...prev,
      Sort: event.value,
    }));
    setActiveButtons([]);
    setData([]);
    if (advancedSearchBoolean && advancedSearchValues) {
      setSortParam(event.value);
      setActiveButtons([]);
      setData([]);
    }
    setIsLoading(false);
  };

  const selectDate = (dates) => {
    const [start, end] = dates || [];
    const formattedStart = start
      ? format(new Date(start), "MM/dd/yyyy")
      : "Not selected";
    const formattedEnd = end
      ? format(new Date(end), "MM/dd/yyyy")
      : "Not selected";
    setSearchEvents((prev) => ({
      ...prev,
      FromDate: formattedStart,
      ToDate: formattedEnd,
    }));
  };

  useEffect(() => {
    if (searchEvent?.length > 0) {
      setGuests(searchEvent.NoOfGuest);
    }
  }, [searchEvent]);

  const formatStartOfDayISO = (date) => {
    const d = new Date(date);
    if (!(d instanceof Date) || isNaN(d.getTime())) {
      throw new Error("Invalid date value");
    }
    d.setHours(0, 0, 0, 0);
    return new Date(
      Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0)
    ).toISOString();
  };

  const formatEndOfDayISO = (date) => {
    const d = new Date(date);
    if (!(d instanceof Date) || isNaN(d.getTime())) {
      throw new Error("Invalid date value");
    }
    d.setHours(23, 59, 59, 999);
    return new Date(
      Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59, 999)
    ).toISOString();
  };
  useEffect(() => {
    if (
      (searchEvents.Sort != undefined || searchEvents.Sort === null) &&
      inputValue
    ) {
      handleSearch();
    }
  }, [searchEvents.Sort]);
  const handleSearch = async () => {
    if (searchEvents.Address === null) return;
    const currentDate = new Date();
    const isValidDate = (date) => date instanceof Date && !isNaN(date);

    const fromDate = isValidDate(new Date(searchEvents.FromDate))
      ? formatStartOfDayISO(searchEvents.FromDate)
      : formatStartOfDayISO(currentDate);

    const toDate = isValidDate(new Date(searchEvents.ToDate))
      ? formatEndOfDayISO(searchEvents.ToDate)
      : formatEndOfDayISO(currentDate);

    const updatedSearchEvents = {
      ...searchEvents,
      FromDate: fromDate,
      ToDate: toDate,
      PageId: 1,
      PageSize: 10,
      ...(guests >= 1 && { NoOfGuest: guests }),
    };
    setIsLoading(true);
    try {
      const data = await getSearchEvents(updatedSearchEvents);
      setFilteredEvents(data.eventsViewModel);
      setPageEvents(data.eventsViewModel);
      setTotalCount(data.eventCount);
      setActiveButtons([]);
      setData([]);
      setIsLoading(false);
      if (data.eventsViewModel.length === 0) {
        return setEventCategory([]);
      }
      const filteredHomeSearch = groupBy(data.eventsViewModel, "eventCategory");
      if (Object.keys(filteredHomeSearch).length) {
        const searchNamesCount = Object.keys(filteredHomeSearch).map(
          (category) => {
            const firstEventInCategory = filteredHomeSearch[category][0];
            return {
              id: firstEventInCategory.eventCategoryId,
              name: firstEventInCategory.eventCategory,
              count: filteredHomeSearch[category].length,
            };
          }
        );
        return setEventCategory(searchNamesCount);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const groupBy = (data, key) => {
    if (data?.length > 0) {
      return data.reduce((acc, item) => {
        (acc[item[key]] = acc[item[key]] || []).push(item);
        return acc;
      }, {});
    }
  };
  const advancedSearchData = async () => {
    if (advancedSearchValues && advancedSearchBoolean) {
      setTotalCount(advancedSearchValues.eventCount);
      setPageEvents(advancedSearchValues.eventsViewModel);
      setFilteredEvents(advancedSearchValues.eventsViewModel);
      setActiveButtons([]);
      setData([]);
      // dispatch(sendEventValues([]));

      // dispatch(searchBoolean(false));
      //  setInputValue("");
      // setSelectedPlace(null);
      // setHasActiveSearch(false);
      setIsLoading(false);
    } else if (advancedSearchBoolean === true) {
      setTotalCount(advancedSearchValues.eventCount);
      setPageEvents(advancedSearchValues.eventsViewModel);
      setFilteredEvents(advancedSearchValues.eventsViewModel);
      setActiveButtons([]);
      setData([]);
    }
  };

  const getAllEvents = async () => {
    setIsLoading(true);
    try {
      if (resetAdvancedSearch && resetBoolean) {
        dispatch(sendEventValues([]));
        // setInputValue("");
        // setHasActiveSearch(false);
        setHasPagination(true);
        setTotalCount(resetAdvancedSearch.eventCount);
        setPageEvents(resetAdvancedSearch.eventsViewModel);
        setFilteredEvents(resetAdvancedSearch.eventsViewModel);
        setIsLoading(false);
      } else {
        return;
      }
    } catch (error) {
      console.error("Error fetching events:", error);
      setIsLoading(false);
    }
  };

  const filterEventsByCategory = (selectedCategories) => {
    if (pageEvents.length > 0) {
      const categoryIds = selectedCategories.map((category) => category.id);
      const filtered = pageEvents.filter((event) =>
        categoryIds.includes(parseInt(event.eventCategoryId))
      );
      setFilteredEvents(filtered);
    } else {
      const categoryIds = selectedCategories.map((category) => category.id);
      const filtered = allEvents.filter((event) =>
        categoryIds.includes(parseInt(event.eventCategoryId))
      );
      setFilteredEvents(filtered);
    }
  };

  const selectEventCategory = (categoryId, index) => {
    if (activeButtons.includes(index)) {
      setActiveButtons(activeButtons.filter((i) => i !== index));
      setData((prevData) => prevData.filter((id) => id !== categoryId));
    } else {
      setActiveButtons([...activeButtons, index]);
      setData((prevData) => [...prevData, categoryId]);
    }
  };

  const resetEventCategory = () => {
    setActiveButtons([]);
    setData([]);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          advancedSearchValues.eventsViewModel.length > 0 &&
          advancedSearchBoolean
        ) {
          const filteredAdvancedSearch = groupBy(
            advancedSearchValues.eventsViewModel,
            "eventCategory"
          );
          if (
            Object.keys(filteredAdvancedSearch)?.length > 0 ||
            filteredAdvancedSearch != undefined ||
            filteredAdvancedSearch != null
          ) {
            const searchNamesCount = Object.keys(filteredAdvancedSearch).map(
              (category) => {
                const firstEventInCategory =
                  filteredAdvancedSearch[category][0];
                return {
                  id: firstEventInCategory.eventCategoryId,
                  name: firstEventInCategory.eventCategory,
                  count: filteredAdvancedSearch[category].length,
                };
              }
            );
            return setEventCategory(searchNamesCount);
          }
        }
        if (
          advancedSearchValues.eventsViewModel.length === 0 &&
          advancedSearchBoolean
        ) {
          return setEventCategory([]);
        }
        if (resetAdvancedSearch.eventsViewModel.length > 0 && resetBoolean) {
          const filteredresetValues = groupBy(
            resetAdvancedSearch.eventsViewModel,
            "eventCategory"
          );
          if (
            Object.keys(filteredresetValues)?.length > 0 ||
            filteredresetValues != undefined ||
            filteredresetValues != null
          ) {
            const searchNamesCount = Object.keys(filteredresetValues).map(
              (category) => {
                const firstEventInCategory = filteredresetValues[category][0];
                return {
                  id: firstEventInCategory.eventCategoryId,
                  name: firstEventInCategory.eventCategory,
                  count: filteredresetValues[category].length,
                };
              }
            );
            setEventCategory(searchNamesCount);
            return;
          }
        }
      } catch (error) {}
    };
    fetchData();
  }, [advancedSearchValues, resetAdvancedSearch]);

  useEffect(() => {
    if (data.length > 0) {
      filterEventsByCategory(data);
    } else {
      setFilteredEvents(pageEvents);
    }
  }, [data]);

  const eventImageSetup = (arrDoc) => {
    if (arrDoc[0]?.eventDocumentPath) {
      const docUrl = transformImageUrl(arrDoc[0]?.eventDocumentPath);
      if (!docUrl.includes(baseURL)) {
        return noPhoto;
      } else {
        return docUrl;
      }
    }
  };

  const redirectToEvent = (event) => {
    navigate(`/${EVENTS}/${event.id}`);
  };

  const getIconFacility = (item) => {
    const svgImage = item?.toLowerCase().replace(/\s+/g, "");
    let imagePath;
    try {
      imagePath = require(`../../assets/image/${svgImage}.png`);
    } catch (error) {}
    return imagePath;
  };

  useEffect(() => {
    getAllEvents();
  }, [searchParams, resetAdvancedSearch]);

  const allData = async () => {
    if (!clearSearch) {
      const data = await getFilterEvents(searchParams);
      setTotalCount(data?.eventCount);
      setAllEvents(data?.eventsViewModel);
      setFilteredEvents(data?.eventsViewModel);
      setIsLoading(false);
      const filteredEventData = groupBy(data.eventsViewModel, "eventCategory");

      if (Object.keys(filteredEventData).length) {
        const categoryNamesCount = Object.keys(filteredEventData).map(
          (category) => {
            const firstEventInCategory = filteredEventData[category][0];
            return {
              id: firstEventInCategory.eventCategoryId,
              name: firstEventInCategory.eventCategory,
              count: filteredEventData[category].length,
            };
          }
        );
        setEventCategory(categoryNamesCount);
      }
    }
  };

  const searchedEventsData = async () => {
    const homeSearch = await searchData;
    if (homeSearch?.eventsViewModel?.length === 0) {
      setHasActiveSearch(true);
      setTotalCount([]);
      setPageEvents([]);
      setFilteredEvents([]);
    } else {
      setHasActiveSearch(true);
      setTotalCount(homeSearch?.eventCount);
      setPageEvents(homeSearch?.eventsViewModel);
      setFilteredEvents(homeSearch?.eventsViewModel);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (searchData?.length === 0 && !searchBooleanVal) {
      allData();
    }
    if (searchData && searchBooleanVal) {
      searchedEventsData();
    }
    if (searchData && searchBooleanVal) {
      const filteredSearchData = groupBy(
        searchData.eventsViewModel,
        "eventCategory"
      );

      if (filteredSearchData && Object.keys(filteredSearchData).length) {
        const categoryNamesCount = Object.keys(filteredSearchData).map(
          (category) => {
            const firstEventInCategory = filteredSearchData[category][0];
            return {
              id: firstEventInCategory.eventCategoryId,
              name: firstEventInCategory.eventCategory,
              count: filteredSearchData[category].length,
            };
          }
        );
        setEventCategory(categoryNamesCount);
      } else {
        return setEventCategory([]);
      }
    }
  }, [searchData]);

  useEffect(() => {
    if (advancedSearchValues && advancedSearchBoolean) {
      advancedSearchData();
    }
  }, [advancedSearchValues]);

  useEffect(() => {
    setSearchEvents((prev) => ({
      ...prev,
      NoOfGuest: guests,
    }));
    if (guests > 1) {
      setHasActiveSearch(true);
    } else {
      setHasActiveSearch(false);
    }
  }, [guests]);

  const errorImageHandler = (e) => {
    e.target.src = noPhoto;
  };
  useEffect(() => {
    if (filteredEvents?.length > 0) {
      const latitudes = filteredEvents?.map((event) => event.latitude);
      const longitudes = filteredEvents?.map((event) => event.longitude);

      const avgLat =
        latitudes.reduce((acc, lat) => acc + lat, 0) / latitudes.length;
      const avgLng =
        longitudes.reduce((acc, lng) => acc + lng, 0) / longitudes.length;

      setMarkerPosition({ lat: avgLat, lng: avgLng });
    }
  }, [filteredEvents]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    if (allEvents?.length > 0) {
      setFilteredEvents(allEvents.slice(startIndex, endIndex));
    }
  }, [allEvents, currentPage, pageSize]);

  const handlePageChange = (newPageId) => {
    setSearchParams((prevParams) => ({
      ...prevParams,
      PageId: newPageId,
    }));
    setActiveButtons([]);
    setData([]);
    setCurrentPage(newPageId); // Update the current page to reflect the UI
    fetchData(newPageId); // Trigger the API call with the new PageId
    setIsLoading(true);
  };

  useEffect(() => {
    if (
      searchData.length === 0 &&
      !searchBooleanVal &&
      (inputValue == undefined || inputValue === "") &&
      !advancedSearchBoolean
    ) {
      fetchData(searchParams.PageId);
    }
  }, [searchData, searchParams]);

  const fetchData = async (pageId) => {
    try {
      const data = await getFilterEvents({ ...searchParams, PageId: pageId });
      setIsLoading(false);
      setFilteredEvents(data.eventsViewModel);
      setPageEvents(data.eventsViewModel);
      setTotalCount(data.eventCount);
      const filteredEventData = groupBy(data.eventsViewModel, "eventCategory");
      if (
        Object.keys(filteredEventData)?.length > 0 ||
        filteredEventData != undefined ||
        filteredEventData != null
      ) {
        const categoryNamesCount = Object.keys(filteredEventData).map(
          (category) => {
            const firstEventInCategory = filteredEventData[category][0];
            return {
              id: firstEventInCategory.eventCategoryId,
              name: firstEventInCategory.eventCategory,
              count: filteredEventData[category].length,
            };
          }
        );
        setEventCategory(categoryNamesCount);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [resetAdvancedSearch]);
  return (
    <>
      <div className="handpicked-main-block">
        <div className="position-relative">
          <div className="breadcrumb-image hand-pick-breadcrumb">
            <img src={img5} alt="meet-at-table" />
            <div className="breadcrumb-overlay"></div>
          </div>

          <div className="banner-text-sec">
            <div className="container">
              <div className="row">
                <div className=" col-md-12">
                  <div className="breadcrumb-header text-center">
                    <h4>
                      Hand-Picked Selections <br />
                      Of Experiences!
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="handpic-brdcrumb-search">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="ad-sec">
                    <div className="row">
                      <div className="col ad-col my-auto">
                        <div className="d-flex align-items-center">
                          <div className="ad-icon">
                            <img src={img2} alt="meet-at-table" />
                          </div>
                          <div className="autocomplete-search">
                            {isLoaded && (
                              <div className="position-relative">
                                <Autocomplete
                                  onLoad={onLoad}
                                  onPlaceChanged={onPlaceChanged}
                                >
                                  <input
                                    ref={inputRef}
                                    onChange={handleInputChange}
                                    value={inputValue || ""}
                                    type="text"
                                    placeholder="Enter a location"
                                    style={{
                                      boxSizing: `border-box`,
                                      border: `1px solid transparent`,
                                      width: `100%`,
                                      height: `32px`,
                                      padding: `0px`,
                                      borderRadius: `3px`,
                                      boxShadow: `none`,
                                      fontSize: `14px`,
                                      outline: `none`,
                                      textOverflow: `ellipses`,
                                    }}
                                  />
                                </Autocomplete>
                                {hasActiveSearch && (
                                  <div
                                    className="loc-close-btn"
                                    onClick={handleSearchClear}
                                  >
                                    <RiCloseLine />
                                  </div>
                                )}
                                {selectedPlace && (
                                  <div
                                    style={{
                                      marginTop: "0px",
                                      boxShadow: "none",
                                    }}
                                  >
                                    <h2>
                                      Selected Place:{" "}
                                      {selectedPlace && selectedPlace.name
                                        ? selectedPlace.name
                                        : ""}
                                    </h2>
                                    <p>
                                      Address:{" "}
                                      {selectedPlace &&
                                      selectedPlace.formatted_address
                                        ? selectedPlace.formatted_address
                                        : selectedPlace.Address}
                                    </p>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col ad-col my-auto">
                        <div className="d-flex align-items-center">
                          <div className="ad-icon">
                            <img src={img3} alt="meet-at-table" />
                          </div>
                          <div className="rmdp-container-main">
                            <DatePicker
                              value={values}
                              selected={startDate}
                              onChange={selectDate}
                              startDate={startDate}
                              endDate={endDate}
                              format="DD MMM YYYY"
                              range
                              inline
                              minDate={
                                new Date(new Date().setHours(0, 0, 0, 0))
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col my-auto">
                        <div className="d-flex align-items-center guest-count-block-main">
                          <div className="ad-icon">
                            <img src={img4} alt="meet-at-table" />
                          </div>
                          <div className="guest-count-block">
                            <ul>
                              <li>
                                <div
                                  id="guestCount"
                                  className="d-flex align-items-center"
                                >
                                  <span className="me-3">{guests}</span>
                                  <button
                                    className="btn me-2"
                                    onClick={decrementGuests}
                                    disabled={guests === 1}
                                  >
                                    -
                                  </button>
                                  <button
                                    className="btn btn-minus"
                                    onClick={incrementGuests}
                                  >
                                    +
                                  </button>
                                </div>
                                <span className="hgt">Guest</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="w-auto my-auto">
                        <div className="text-end d-flex align-items-center justify-content-end">
                          <button
                            className="btn"
                            onClick={() => handleSearch()}
                            disabled={!inputValue}
                            style={{ opacity: !inputValue ? 0.5 : 1 }}
                          >
                            <FiSearch />
                          </button>

                          <div
                            onClick={() => setModalShow(true)}
                            className="filter-btn"
                          >
                            <img src={img9} alt="meet-at-table" />
                          </div>
                        </div>
                      </div>
                      {/* <Search onSearch={getSearch} /> Pass the handleSearch function */}
                    </div>
                  </div>
                </div>
                {/* <div className="col-12">
                  <div className="ad-sec-handpick">
                    {activeButtons.length > 0 ? (
                      <span className="reset-btn">
                        {activeButtons.length > 0 ? (
                          <RiCloseLine
                            className="close-icon"
                            onClick={() =>resetEventCategory()}
                          />
                        ) : (
                          ""
                        )}
                      </span>
                    ) : (
                      ""
                    )}
                    {eventCategory?.map((category, index) => (
                      <>
                        <div
                          key={category.id}
                          className={
                            activeButtons.includes(index)
                              ? "hand-pick-breadcrumb-img-sec active"
                              : "hand-pick-breadcrumb-img-sec"
                          }
                          onClick={() => selectEventCategory(category, index)}
                        >
                          <div className="hand-pick-breadcrumb-img">
                            <img
                              src={getIconFacility(category.name)}
                              alt="meet-at-table"
                            />
                          </div>
                          <div>
                            <p>{category?.name}</p>
                          </div>
                          <div>
                            <span>{category?.count}</span>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <section>
          <div className="en position-relative handpicked-bg">
            <div className="handpicked-bg-overlay"></div>
            <div className="handpicked-bg-content">
              <div className="shape-1"></div>
              <div className="px-3">
                <div className="row mt-3 gy-3">
                  <div className="col-lg-5 col-md-12">
                    <div className="see-menu-sec-btn hand-pick-loc h-100">
                      {isLoaded && (
                        <div>
                          <GoogleMap
                            mapContainerStyle={mapContainerStyle}
                            center={center}
                            zoom={5}
                          >
                            {locations.map(
                              (location, index) =>
                                filteredEvents?.length > 0 &&
                                filteredEvents?.map((event) => {
                                  const lat = event.latitude;
                                  const lng = event.longitude;

                                  if (
                                    typeof lat !== "number" ||
                                    typeof lng !== "number"
                                  ) {
                                    console.warn(
                                      `Invalid coordinates for event: ${event.id}`
                                    );
                                    return null; // Skip invalid events
                                  }

                                  return (
                                    <MarkerF
                                      key={event.id}
                                      position={{
                                        lat: event.latitude,
                                        lng: event.longitude,
                                      }}
                                      icon={{
                                        url: "https://meet-at-table-admin.scopethinkers.com/HTML/image/map-marker.png",
                                      }}
                                      onClick={() =>
                                        handleMarkerClick(location)
                                      }
                                    />
                                  );
                                })
                            )}
                            <div></div>
                            {activeMarker && (
                              <InfoWindowF
                                position={{
                                  lat: activeMarker.lat,
                                  lng: activeMarker.lng,
                                }}
                                onCloseClick={handleCloseClick} // Close the InfoWindow
                              >
                                <div className="infowindow-block">
                                  <h3>Marker Info</h3>
                                  <p>Latitude: {activeMarker.lat}</p>
                                  <p>Longitude: {activeMarker.lng}</p>
                                  <p>Additional information can go here.</p>
                                </div>
                              </InfoWindowF>
                            )}
                          </GoogleMap>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-12">
                    <div className="menu-tab hand-pick-menu-tab h-100 d-flex flex-column">
                      <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item">
                          <span>{filteredEvents?.length} Experiences</span>
                        </li>
                        {filteredEvents.length > 0 && (
                          <div className="d-flex ms-auto align-items-center me-2 tab-loc-sec">
                            <div className="mb-0">
                              <div className="selectbox-block">
                                <Select
                                  placeholder="Sort By"
                                  options={sortList}
                                  onChange={handleSelect}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </ul>
                      <div className="tab-content p-0 ">
                        <div id="all" className="tab-pane active">
                          <div className="row g-3 tab-content-ht">
                            <div className="col-12 mt-0">
                              <div className="ad-sec-handpick pt-0">
                                {activeButtons.length > 0 ? (
                                  <span className="reset-btn">
                                    {activeButtons.length > 0 ? (
                                      <RiCloseLine
                                        className="close-icon"
                                        onClick={() => resetEventCategory()}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                ) : (
                                  ""
                                )}
                                {eventCategory?.map((category, index) => (
                                  <>
                                    <div
                                      key={category.id}
                                      className={
                                        activeButtons.includes(index)
                                          ? "hand-pick-breadcrumb-img-sec active"
                                          : "hand-pick-breadcrumb-img-sec"
                                      }
                                      onClick={() =>
                                        selectEventCategory(category, index)
                                      }
                                    >
                                      <div className="hand-pick-breadcrumb-img">
                                        <img
                                          src={getIconFacility(category.name)}
                                          alt="meet-at-table"
                                        />
                                      </div>
                                      <div>
                                        <p>{category?.name}</p>
                                      </div>
                                      <div>
                                        <span>{category?.count}</span>
                                      </div>
                                    </div>
                                  </>
                                ))}
                              </div>
                            </div>
                            {filteredEvents?.length > 0 ? (
                              filteredEvents?.map((event) => (
                                <>
                                  <div
                                    className="col-lg-4 col-md-6 cursor-pointer"
                                    onClick={() => redirectToEvent(event)}
                                    key={event.id}
                                  >
                                    <div className="card h-100">
                                      <div className="position-relative event-slider">
                                        <div className="tab-img p-0 shadow-none">
                                          {event.eventDocuments.length > 0 ? (
                                            <img
                                              src={eventImageSetup(
                                                event?.eventDocuments
                                              )}
                                              className="card-img-top"
                                              alt="images"
                                              onError={errorImageHandler}
                                            />
                                          ) : (
                                            <img
                                              src={noPhoto}
                                              className="card-img-top"
                                              alt="noPhoto"
                                            />
                                          )}
                                        </div>
                                        <div className="evnt-ratng-sec d-flex justify-content-between align-items-center">
                                          <p className="evnt-ratng">
                                            <MdOutlineStar /> 5(90)
                                          </p>
                                        </div>
                                      </div>
                                      <div className="card-body p-0">
                                        <div className="d-flex align-items-center carousel-img-body">
                                          <div className="carousel-small">
                                            {event.eventDocuments.length > 0 ? (
                                              <img
                                                src={eventImageSetup(
                                                  event?.eventDocuments
                                                )}
                                                className="card-img-top"
                                                alt="images"
                                                onError={errorImageHandler}
                                              />
                                            ) : (
                                              <img
                                                src={noPhoto}
                                                className="card-img-top"
                                                alt="noimages"
                                              />
                                            )}
                                          </div>
                                          <div className="tab-evnt-des">
                                            <p className="mb-0">
                                              {event.eventDescription}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="tab-evnt-dlr d-flex justify-content-between flex-wrap">
                                          <p className="mb-0">
                                            {event.eventCategory}
                                          </p>
                                          <p className="mb-0">
                                            {event.currency}
                                            {event.gustPerCost}
                                            <span className="hgt hgt-small">
                                              /Guest
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))
                            ) : (
                              <div className="card h-100 shadow border-0">
                                <div className="position-relative">
                                  <div className="tab-img shadow-none border-0 not-found">
                                    {/* <img
                                      src={blank}
                                      className="card-img-top"
                                      alt="images"
                                    /> */}
                                    No Events Found!
                                  </div>
                                </div>
                                <div className="card-body">
                                  <div className="blank-text"></div>
                                  <div className="blank-text-2"></div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {hasPagination && totalCount > 10 && (
                        <Pagination
                          className="pagination-bar"
                          currentPage={currentPage}
                          totalCount={totalCount}
                          pageSize={searchParams.PageSize}
                          onPageChange={handlePageChange}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="shapefaq"></div>
            </div>
          </div>
        </section>

        <ApplyFilterModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          sortData={sortParam}
        />
      </div>
      {isLoading && <Loading />}
    </>
  );
}

export default HandPickedEvents;
