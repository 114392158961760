import React, { useState, useEffect, useRef } from "react";
import logo from "../../assets/image/logo-dark.svg";
import img1 from "../../assets/image/login-pattern2.png";
import img2 from "../../assets/image/profile.png";
import img3 from "../../assets/image/edit-profile.png";
import img4 from "../../assets/image/chat-icon-new.png";
import img5 from "../../assets/image/account.png";
import img6 from "../../assets/image/logout.png";
import img7 from "../../assets/image/calender.png";
import myEvents from "../../assets/image/myEvents.png";
import logowhite from "../../assets/image/logo-light.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  removeAccessToken,
  removeHostId,
  getEmail,
  saveHostId,
} from "../../util/auth";
import {
  FAQ,
  ABOUT_US,
  LOGIN,
  REGISTER,
  UPDATE_PROFILE,
  USER,
  MY_WISHLISTS,
  ACCOUNT_SETTINGS,
  ALL_EVENTS,
  HANDPICKED_EVENTS,
  HOW_IT_WORKS,
  CHAT,
  MY_BOOKINGS,
  UPGRADE_PLAN,
  NOTIFICATIONS,
} from "../../const/route-url.const";
import { getAccessToken, getHostId, removeEmail } from "../../util/auth";
import { FaRegBell } from "react-icons/fa";
import {
  getAllNotification,
  getHostDetails,
  getProfile,
} from "../../services/api/user-auth.api";
import { useDispatch, useSelector } from "react-redux";
import "../../index.scss";
import "../header/header.scss";
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import Toaster from "../../common/Toaster";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import Container from "react-bootstrap/Container";
import { NavLink } from "react-router-dom";
import { transformImageUrl } from "../../util/dataTransform";
import { setUserId } from "../../redux/slice/userMail";

function Header() {
  const [searchParams, setSearchParams] = useState({
    pageId: 1,
    pageSize: 10,
  });
  const userId = useSelector((state) => state.userEmail.userId);
  const dispatch = useDispatch();
  const booleanValue = useSelector((state) => state.tokenRefresh.value);
  const isGoogleLogin = useSelector((state) => state.tokenRefresh.googleLogin);
  const isFacebookLogin = useSelector(
    (state) => state.tokenRefresh.facebookLogin
  );
  const isLoggedOut = useSelector((state) => state.logoutRefresh.value);
  const navigate = useNavigate();
  const [userName, setUserName] = useState([]);
  const [url, setUrl] = useState();
  const [hostData, setHostData] = useState(null);
  const location = useLocation();
  const [token, setToken] = useState("");
  const [hostId, setHostId] = useState(getHostId());
  const [notify, setNotify] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const userEmail = useSelector((selector) => selector?.userEmail?.value);
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [profileImg, setProfileImg] = useState();
  const notifyRef = useRef(null);
  const showStyle = [
    "about-us",
    "contact-us",
    "how-it-works",
    "faq",
    "privacy-policy",
    "terms-and-conditions",
  ];
  function timeAgo(date) {
    const now = new Date();
    const diffInSeconds = Math.floor((now - new Date(date)) / 1000);
    const minutes = 60;
    const hours = minutes * 60;
    const days = hours * 24;
    const months = days * 30;
    const years = days * 365;

    if (diffInSeconds < minutes) {
      return diffInSeconds === 1
        ? "1 second ago"
        : `${diffInSeconds} seconds ago`;
    } else if (diffInSeconds < hours) {
      const diffInMinutes = Math.floor(diffInSeconds / minutes);
      return diffInMinutes === 1
        ? "1 minute ago"
        : `${diffInMinutes} minutes ago`;
    } else if (diffInSeconds < days) {
      const diffInHours = Math.floor(diffInSeconds / hours);
      return diffInHours === 1 ? "1 hour ago" : `${diffInHours} hours ago`;
    } else if (diffInSeconds < months) {
      const diffInDays = Math.floor(diffInSeconds / days);
      return diffInDays === 1 ? "1 day ago" : `${diffInDays} days ago`;
    } else if (diffInSeconds < years) {
      const diffInMonths = Math.floor(diffInSeconds / months);
      return diffInMonths === 1 ? "1 month ago" : `${diffInMonths} months ago`;
    } else {
      const diffInYears = Math.floor(diffInSeconds / years);
      return diffInYears === 1 ? "1 year ago" : `${diffInYears} years ago`;
    }
  }

  const handleClickOutside = (event) => {
    if (notifyRef.current && !notifyRef.current.contains(event.target)) {
      setNotify(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    authSetUp();
    let path = location.pathname.replace(/^\//, "");
    setUrl(path);
    // if (hostId != undefined) {
    getHost();
    // }
    if (booleanValue || isGoogleLogin || isFacebookLogin) {
      authSetUp();
      const hostIdVal = getHostId();
      if (hostIdVal) {
        setHostId(hostIdVal);
      }
    }
  }, [location, booleanValue, isGoogleLogin, isFacebookLogin]);

  useEffect(() => {
    if (isLoggedOut) {
      logout();
    }
  }, [isLoggedOut]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        document.body.classList.add("header-scroll");
      } else {
        document.body.classList.remove("header-scroll");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const getNotification = async (userId) => {
    try {
      const res = await getAllNotification(searchParams, userId);
      const formattedNotification = res.notificationViewModel.map((notify) => {
        return {
          ...notify,
          formattedDate: timeAgo(notify.date),
        };
      });
      const filteredNotification = formattedNotification.filter((notific) => {
        return notific.isRead === false;
      });
      setNotifications(filteredNotification);
      setNotificationCount(filteredNotification.length);
    } catch (error) {
      console.log(error);
    }
  };
  const authSetUp = async () => {
    const tokVal = await getAccessToken();
    if (tokVal) {
      setToken(tokVal);
      getUser();
    }
  };
  const logout = async () => {
    await removeAccessToken();
    await removeHostId();
    await setToken("");
    await removeEmail();
    navigate("/");
  };
  const getUser = async () => {
    const user = await getProfile();
    dispatch(setUserId(user.userId));
    setUserName(user.firstName + " " + user.lastName);
    await getNotification(user.userId);
    if (user.profileFilepath) {
      const profileUrl = await transformImageUrl(user.profileFilepath);
      setProfileImg(profileUrl);
    } else {
      setProfileImg(img2);
    }
  };
  const getHost = async () => {
    try {
      const host = await getHostDetails();
      if (host) {
        await setHostData(host);
        await saveHostId(host.id);
        await setHostId(host.id);
      }
    } catch (error) {
      console.log(error);
      await setHostData({});
      await removeHostId("");
      await setHostId("");
    }
  };

  const loginFirebaseHandler = async () => {
    try {
      let mail = getEmail();
      mail = userEmail && userEmail !== "" ? userEmail : mail;
      await signInWithEmailAndPassword(auth, mail, mail);
      navigate(`${USER}/${CHAT}`);
    } catch (error) {
      Toaster("Please login with your profile", "error");
    }
  };

  const headerPath = useLocation();
  const redirectNotifications = () => {
    setNotify(!notify);
    navigate(`${USER}/${NOTIFICATIONS}`);
  };
  return (
    <>
      {["lg"].map((expand) => (
        <div className="position-relative header-wrapper">
          <Navbar
            key={expand}
            expand={expand}
            className={`nav-menu ${
              showStyle.includes(url) || url === "" ? "hm-nav-menu" : ""
            }`}
          >
            <Container className="container-custom">
              <div className="logo-image logo-res d-lg-none">
                <Link className="navbar-brand logo-white" to="/">
                  <img src={logowhite} alt="meet-at-table" />
                </Link>
                <Link className="navbar-brand logo-dark" to="/">
                  <img src={logo} alt="meet-at-table" />
                </Link>
              </div>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    <div className="logo-image mx-auto">
                      <Link className="navbar-brand" to="/">
                        <img src={logo} alt="meet-at-table" />
                      </Link>
                    </div>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="off-canvas-menus">
                  <Nav className=" header-wrapper-content align-items-lg-center  navlist">
                    <Nav.Link
                      as={NavLink}
                      className={`${url === ABOUT_US ? "active" : ""} nav-link`}
                      to={ABOUT_US}
                    >
                      Meet Us
                    </Nav.Link>
                    <Nav.Link
                      as={NavLink}
                      className={`${
                        url == HOW_IT_WORKS ? "active" : ""
                      } nav-link`}
                      to={HOW_IT_WORKS}
                    >
                      How It Works
                    </Nav.Link>
                    <Nav.Link
                      as={NavLink}
                      className={`${
                        url == HANDPICKED_EVENTS ? "active" : ""
                      } nav-link`}
                      to={HANDPICKED_EVENTS}
                    >
                      Events
                    </Nav.Link>
                    <Nav.Link
                      as={NavLink}
                      className={`${url == FAQ ? "active" : ""} nav-link`}
                      to={FAQ}
                    >
                      FAQ
                    </Nav.Link>
                  </Nav>
                  <div className="logo-image logo-res mx-auto">
                    <Link className="navbar-brand logo-white" to="/">
                      <img src={logowhite} alt="meet-at-table" />
                    </Link>
                    <Link className="navbar-brand logo-dark" to="/">
                      <img src={logo} alt="meet-at-table" />
                    </Link>
                  </div>
                  <Nav className="justify-content-end d-flex nav-btn ">
                    {token ? (
                      <>
                        {hostId ? (
                          (hostData?.verificationStatus === 0 ||
                            hostData?.verificationStatus === 3 ||
                            hostData?.verificationStatus === 27) && (
                            <div className="navlist nav__link">
                              <Nav.Link
                                as={NavLink}
                                className={`sub-menu-text sub__menu ${
                                  url === "/host" ? "active" : ""
                                } nav-link`}
                                to={"/host"}
                              >
                                Become a host
                              </Nav.Link>
                            </div>
                          )
                        ) : (
                          <div className="navlist nav__link">
                            <Nav.Link
                              as={NavLink}
                              className={`sub__menu ${
                                url === "/host" ? "active" : ""
                              } nav-link`}
                              to={"/host"}
                            >
                              Become a host
                            </Nav.Link>
                          </div>
                        )}

                        <div
                          className="position-relative a-notify"
                          ref={notifyRef}
                        >
                          <div
                            className="notify-icon position-relative"
                            onClick={() => setNotify(!notify)}
                          >
                            <FaRegBell />
                            <div className="notify">
                              {notificationCount > 0 && (
                                <p className="notify">{notificationCount}</p>
                              )}
                            </div>
                          </div>
                          <div className="notify-content">
                            {notify && (
                              <ul className="notify-dropdown">
                                <li>
                                  <div>
                                    <li>
                                      <div>
                                        <Link
                                          to={`${USER}/${NOTIFICATIONS}`}
                                          className="notify-link"
                                          onClick={() => setNotify(!notify)}
                                        >
                                          <p className="notify-dropdown-title">
                                            Notifications
                                          </p>
                                        </Link>
                                      </div>
                                    </li>
                                  </div>
                                </li>
                                {notifications.length > 0 ? (
                                  <>
                                    {notifications
                                      .slice(0, 3)
                                      .map((item, index) => (
                                        <li key={index}>
                                          <div className="notify-list">
                                            <p>{item.message}</p>
                                            <span>{item.formattedDate}</span>
                                          </div>
                                        </li>
                                      ))}
                                    {notificationCount > 3 && (
                                      <div className="d-flex justify-content-end">
                                        <button
                                          className="see-all"
                                          onClick={redirectNotifications}
                                        >
                                          See all
                                        </button>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <div className="notify-list">
                                    <p className="no-data">
                                      No new notifications
                                    </p>
                                  </div>
                                )}
                              </ul>
                            )}
                          </div>
                        </div>
                        <div className="navlist nav__link">
                          <Nav.Link
                            as={NavLink}
                            className={`sub-menu-text sub__menu ${
                              url === "/user/chat" ? "active" : ""
                            } nav-link`}
                            to={"/user/chat"}
                          >
                            Chat
                          </Nav.Link>
                        </div>
                        <NavDropdown
                          title={
                            <div className="profile-dropdown-title d-flex align-items-center">
                              <div className="profile">
                                <img
                                  src={profileImg}
                                  alt="Profile"
                                  style={{ width: "30px", borderRadius: "50%" }}
                                />
                              </div>
                              <span>{userName ? userName : "John"}</span>
                            </div>
                          }
                          className="profile-dropdown"
                          id={`offcanvasNavbarDropdown-expand-${expand}`}
                        >
                          {hostId && (
                            <>
                              <NavDropdown.Item
                                className="profile-dropdown profile-list"
                                onClick={() =>
                                  navigate(`${ALL_EVENTS}?upcoming=true`)
                                }
                              >
                                <span className="profile-img">
                                  {/* <img src={img7} alt="meet-at-table" /> */}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="size-6"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                                    />
                                  </svg>
                                </span>

                                <span className="ms-2">My Upcoming Events</span>
                              </NavDropdown.Item>
                              <NavDropdown.Item
                                className="profile-dropdown profile-list"
                                onClick={() =>
                                  navigate(`${ALL_EVENTS}?past=true`)
                                }
                              >
                                <span className="profile-img">
                                  {/* <img src={img7} alt="meet-at-table" /> */}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="size-6"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M6.75 2.994v2.25m10.5-2.25v2.25m-14.252 13.5V7.491a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v11.251m-18 0a2.25 2.25 0 0 0 2.25 2.25h13.5a2.25 2.25 0 0 0 2.25-2.25m-18 0v-7.5a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v7.5m-6.75-6h2.25m-9 2.25h4.5m.002-2.25h.005v.006H12v-.006Zm-.001 4.5h.006v.006h-.006v-.005Zm-2.25.001h.005v.006H9.75v-.006Zm-2.25 0h.005v.005h-.006v-.005Zm6.75-2.247h.005v.005h-.005v-.005Zm0 2.247h.006v.006h-.006v-.006Zm2.25-2.248h.006V15H16.5v-.005Z"
                                    />
                                  </svg>
                                </span>

                                <span className="ms-2">My Past Events</span>
                              </NavDropdown.Item>
                              <NavDropdown.Item
                                className="profile-dropdown profile-list"
                                onClick={() => navigate(`${UPGRADE_PLAN}`)}
                              >
                                <span className="profile-img">
                                  {/* <img src={img7} alt="meet-at-table" /> */}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="size-6"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z"
                                    />
                                  </svg>
                                </span>
                                <span className="ms-2">
                                  My Subscription plan
                                </span>
                              </NavDropdown.Item>
                            </>
                          )}
                          <NavDropdown.Item
                            className="profile-dropdown profile-list"
                            onClick={() => navigate(`${MY_BOOKINGS}`)}
                          >
                            <span className="profile-img">
                              {/* <img src={myEvents} alt="meet-at-table" /> */}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="size-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                                />
                              </svg>
                            </span>

                            <span className="ms-2">My Events</span>
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            className="profile-dropdown profile-list"
                            onClick={() =>
                              navigate(`${`${USER}/${ACCOUNT_SETTINGS}`}`)
                            }
                          >
                            <span className="profile-img">
                              {/* <img src={img5} alt="meet-at-table" /> */}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="size-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                />
                              </svg>
                            </span>
                            <span className="ms-2">My Account</span>
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            className="profile-dropdown profile-list"
                            onClick={logout}
                          >
                            <span className="profile-img">
                              {/* <img src={img6} alt="meet-at-table" /> */}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="size-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15"
                                />
                              </svg>
                            </span>
                            <span className="ms-2">Logout</span>
                          </NavDropdown.Item>
                        </NavDropdown>
                      </>
                    ) : (
                      <>
                        <Nav className="header-wrapper-content align-items-lg-center navlist nav__link">
                          <Nav.Link
                            as={NavLink}
                            className={`${
                              url === LOGIN ? "active" : ""
                            } nav-link`}
                            to={LOGIN}
                          >
                            Login
                          </Nav.Link>
                          <Nav.Link
                            as={NavLink}
                            className={`${
                              url === "/host" ? "active" : ""
                            } nav-link`}
                            to={"/host"}
                          >
                            Become a host
                          </Nav.Link>
                        </Nav>
                        <Link
                          to={REGISTER}
                          className="btn active"
                          type="button"
                        >
                          Sign Up for Free
                        </Link>
                      </>
                    )}
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
          <div
            className={`pattern-row ${url === "event" ? "d-block" : "d-none"}`}
          >
            <div className="col-12">
              <div className="pattern-2">
                {headerPath.pathname === "/host" || "/login" ? null : (
                  <img src={img1} alt="meet-at-table" />
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default Header;
