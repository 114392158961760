import React, { useMemo, useState, useEffect } from "react";
import img2 from "../../../assets/image/breadcrumb-image.png";
import "../all-events.scss";
import { Link } from "react-router-dom";
import {
  ALL_EVENTS,
  CREATE_EVENT,
  EDIT_EVENT,
} from "../../../const/route-url.const";
import Pagination from "../../../common/Pagination";
import data from "../events/data.json";
import { getHostDetails } from "../../../services/api/user-auth.api";
import { getHostEvents } from "../../../services/api/events-auth.api";
import Loading from "../../../common/Loading";
import { removeEventId } from "../../../util/auth";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import EventContent from "./EventContent";
import { useLocation } from "react-router-dom";
function ViewEvents() {
  const [events, setEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState();
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  const upcomingEvents = queryString.get("upcoming");
  const pastEvents = queryString.get("past");
  const [searchParams, setSearchParams] = useState({
    pageId: 1,
    pageSize: 10,
    status: 0,
  });
  const [hostId, setHostId] = useState(null);

  useEffect(() => {
    removeEventId();
    getHost();
  }, []);

  useEffect(() => {
    if (upcomingEvents) {
      handleSelect("approved");
    } else if (pastEvents) {
      handleSelect("past");
    }
  }, [upcomingEvents, pastEvents]);
  const getAllHostEvents = async () => {
    setIsLoading(true);
    try {
      const res = await getHostEvents(searchParams);
      setEvents(res?.eventsViewModel);
      setCount(res?.eventCount);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const getHost = async () => {
    setIsLoading(true);
    try {
      const host = await getHostDetails();
      if (host.id) {
        setSearchParams((prev) => ({
          ...prev,
          HostId: host.id,
        }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handlePageChange = (newPageId) => {
    setSearchParams((prevParams) => ({
      ...prevParams,
      pageId: newPageId,
    }));
    setCurrentPage(newPageId);
  };
  const handleSelect = (key) => {
    setCurrentTab(key);
    setSearchParams((prev) => ({
      ...prev,
      currentDate: "",
    }));
    switch (key) {
      case "past":
        const today = new Date();
        today.setUTCHours(0, 0, 0, 0);
        setSearchParams((prev) => ({
          ...prev,
          status: 1,
          currentDate: today.toISOString(),
        }));
        break;
      case "pending":
        setSearchParams((prev) => ({
          ...prev,
          pageId: currentPage,
          status: 0,
        }));
        break;
      case "approved":
        setSearchParams((prev) => ({
          ...prev,
          status: 1,
        }));
        break;
      case "default":
        break;
    }
  };
  const refresh = () => {
    getAllHostEvents();
  };
  useEffect(() => {
    getAllHostEvents();
  }, [searchParams]);

  return (
    <>
      <div className="breadcrumb-image">
        <img src={img2} alt="meet-at-table" />
        <div className="breadcrumb-overlay"></div>
        <div className="breadcrumb-title">
          <div className="breadcrumb-header">
            <h4>Events</h4>
          </div>
        </div>
      </div>
      <main>
        <section>
          <div className="position-relative overflow-hidden p-s menu-tab">
            <div className="shape-1"></div>
            <div className="container">
              <div className="en-center d-flex justify-content-between mb-2">
                <h3></h3>
                <div className="next-btn mt-0">
                  <Link to={`${CREATE_EVENT}`} type="button">
                    Create Event
                  </Link>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="menu-tab">
                    {/* tab */}
                    <Tabs
                      defaultActiveKey="pending"
                      id="controlled-tab-example"
                      className="nav-underline"
                      onSelect={handleSelect}
                      activeKey={currentTab ? currentTab : "pending"}
                    >
                      <Tab
                        eventKey="pending"
                        className="nav-item "
                        title="Pending Events"
                      >
                        <EventContent
                          eventList={events}
                          refreshHook={refresh}
                          tab={currentTab}
                        />
                      </Tab>
                      <Tab
                        eventKey="approved"
                        className="nav-item"
                        title="Upcoming Events"
                      >
                        <EventContent
                          eventList={events}
                          refreshHook={refresh}
                          tab={currentTab}
                        />
                      </Tab>
                      <Tab
                        eventKey="past"
                        className="nav-item"
                        title="Past Events"
                      >
                        <EventContent
                          eventList={events}
                          refreshHook={refresh}
                          tab={currentTab}
                        />
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
            <div className="shapefaq"></div>
            {count > 0 && (
              <Pagination
                className="pagination-bar"
                currentPage={searchParams?.pageId}
                totalCount={count}
                pageSize={searchParams.pageSize}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        </section>
      </main>

      {isLoading && <Loading />}
    </>
  );
}

export default ViewEvents;
