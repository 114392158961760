import React, { useEffect, useState } from "react";
import { getfaqs } from "../../../services/api/site-info.api";
import banner from "../../../assets/image/new-banner-video.mp4";
import img1 from "../../../assets/image/logo.png";
import img2 from "../../../assets/image/breadcrumb-image.png";
import img3 from "../../../assets/image/login-pattern2.png";
import img4 from "../../../assets/image/faq2-img.png";
import img5 from "../../../assets/image/faq-bg.png";
import img6 from "../../../assets/image/faq1-img.png";
import img7 from "../../../assets/image/footer-logo.png";
import Accordion from "react-bootstrap/Accordion";
import "../faq/FAQ.scss";
function FAQ() {
  const [faqs, setFaqs] = useState();
  const [generalToShow, setGeneralToShow] = useState(3);
  const [guestsToShow, setGuestsToShow] = useState(3);
  const [hostsToShow, setHostsToShow] = useState(3);
  useEffect(() => {
    getFaqData();
  }, []);

  const getFaqData = async () => {
    try {
      const faqData = await getfaqs();
      setFaqs(faqData);
    } catch (error) {
      console.log(error);
    }
  };
  const showMore = (tab) => {
    if (tab === "general")
      setGeneralToShow(faqs?.filter((faq) => faq.categoryId === 0).length);
    if (tab === "guests")
      setGuestsToShow(faqs?.filter((faq) => faq.categoryId === 1).length);
    if (tab === "hosts")
      setHostsToShow(faqs?.filter((faq) => faq.categoryId === 2).length);
  };

  const showLess = (tab) => {
    if (tab === "general") setGeneralToShow(3);
    if (tab === "guests") setGuestsToShow(3);
    if (tab === "hosts") setHostsToShow(3);
  };
  const renderFaqs = (categoryId, itemsToShow) => {
    const filteredFaqs = faqs?.filter((faq) => faq?.categoryId === categoryId);
    return (
      filteredFaqs?.length > 0 &&
      filteredFaqs.slice(0, itemsToShow).map((faq) => (
        <Accordion.Item eventKey={faq?.id} key={faq?.id}>
          <Accordion.Header>{faq?.question}</Accordion.Header>
          <Accordion.Body className="accordion-body">
            <p
              dangerouslySetInnerHTML={{ __html: faq?.answer }}
              className="mb-0"
            ></p>
          </Accordion.Body>
        </Accordion.Item>
      ))
    );
  };

  const renderShowMoreLessButton = (itemsToShow, totalLength, tab) => {
    if (totalLength > 3) {
      return (
        <div className="pointer me-3 text-end">
          {itemsToShow === 3 ? (
            <button onClick={() => showMore(tab)} className="show-more">
              Show More
            </button>
          ) : (
            <button onClick={() => showLess(tab)} className="show-more">
              Show Less
            </button>
          )}
        </div>
      );
    }
    return null;
  };
  return (
    <>
      <div className="FAQ-wrapper">
        <div className="breadcrumb-image">
          <video autoPlay muted loop>
            <source src={banner} type="video/mp4" />
          </video>
          <div className="breadcrumb-overlay"></div>
          <div className="breadcrumb-title">
            <div className="breadcrumb-header">
              <h4>FAQ</h4>
            </div>
            <div>
              <p>Search our knowledge base for answers to common</p>
            </div>
          </div>
        </div>
        <div className="pattern-row">
          <div className="col-12">
            <div className="pattern-2">
              <img src={img3} alt="meet-at-table" />
            </div>
          </div>
        </div>
        <div className="position-relative overflow-hidden p-s faq-bg">
          <div className="faq-bg-overlay"></div>
          <div className="faq-bg-content">
            <div className="shape-1"></div>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 my-auto">
                  <div className="row">
                    <div className="col-lg-9">
                      <div className="faq-bg h-100">
                        <div className="faq-img">
                          <div className="faq2-img">
                            <img src={img4} alt="meet-at-table" />
                            <div className="faq-bg-img">
                              <img src={img5} alt="meet-at-table" />
                            </div>
                          </div>
                          <div className="faq1-img">
                            <img src={img6} alt="meet-at-table" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="col-12 mb-5">
                    <h2>General</h2>
                    <Accordion className="faq-accordion h-100">
                      {renderFaqs(0, generalToShow)}
                    </Accordion>
                    {renderShowMoreLessButton(
                      generalToShow,
                      faqs?.filter((faq) => faq?.categoryId === 0).length,
                      "general"
                    )}
                  </div>
                  <div className="col-12 mb-5">
                    <h2>Guests</h2>
                    <Accordion className="faq-accordion h-100">
                      {renderFaqs(1, guestsToShow)}
                    </Accordion>
                    {renderShowMoreLessButton(
                      guestsToShow,
                      faqs?.filter((faq) => faq.categoryId === 1).length,
                      "guests"
                    )}
                  </div>
                  <div className="col-12">
                    <h2>Hosts</h2>
                    <Accordion className="faq-accordion h-100">
                      {renderFaqs(2, hostsToShow)}
                    </Accordion>
                    {renderShowMoreLessButton(
                      hostsToShow,
                      faqs?.filter((faq) => faq.categoryId === 2).length,
                      "hosts"
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="shapefaq"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FAQ;
