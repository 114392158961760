import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCheckProfileStatus } from "../services/api/user-auth.api";
import { useDispatch } from "react-redux";
import { setGoogleLogin } from "../redux/slice/tokenSlice";
import AuthProfileModal from "./modal/authProfile";

function GoogleAuthCallback() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const checkProfile = async () => {
    try {
      const status = await getCheckProfileStatus();
      if (status) {
        dispatch(setGoogleLogin(true));
        navigate("/");
      } else {
        await setShowModal(true);
      }
    } catch (error) {}
  };
  useEffect(() => {
    checkProfile();
  }, []);

  return (
    <div>
      {/* <AuthProfileModal show={showModal} onHide={() => setShowModal(false)} /> */}
    </div>
  );
}

export default GoogleAuthCallback;
