import React, { useState, useEffect } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import Loading from "../../../common/Loading";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
function ChefHost({ prevHook, InputEventHook, eventInfoDetails }) {
  const prev = () => {
    prevHook();
  };
  const [isCertified, setIsCertified] = useState(false);
  const [isHostSame, setIsHostSame] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hostExp, setHostExp] = useState("");
  const [willCookFood, setWillCookFood] = useState(false);
  const [formValues, setFormValues] = useState({
    cookExperience: null,
    foodCertified: false,
    isChefHost: false,
  });
  const [error, setError] = useState(false);
  const [certify] = useState([
    { label: "Yes", value: true },
    { label: "No", value: false },
  ]);
  const nextSubmit = () => {
    setLoading(true);
    if (!isHostSame) {
      setLoading(false);
    }
    const stepId = 4;
    InputEventHook(formValues, stepId);
  };
  const handleInputChange = (name, val) => {
    if (name === "foodCertified") {
      setIsCertified(val);
    }
    if (name === "isChefHost") {
      setIsHostSame(val);
    }
    if (name === "willCookFood") {
      setWillCookFood(val);
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: val,
    }));
  };
  const handleChefExp = (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      cookExperience: e.target.value,
    }));
  };
  useEffect(() => {
    if (eventInfoDetails) {
      setIsCertified(eventInfoDetails?.foodCertified || false);
      setIsHostSame(eventInfoDetails?.isChefHost || false);
      setHostExp(eventInfoDetails?.hostingExperience);
      setFormValues((prev) => ({
        ...prev,
        cookExperience: eventInfoDetails?.cookExperience || null,
        foodCertified: eventInfoDetails?.foodCertified || false,
        isChefHost: eventInfoDetails?.isChefHost || false,
      }));
    }
  }, [eventInfoDetails]);
  return (
    <div>
      <div id="pills-tab4">
        {/* <!-- tab4 -->  */}
        <div className="row">
          <div className="col-md-12 mx-auto">
            <div className="create-event-tab">
              <div className="row">
                <div className="col-lg-4">
                  <div className="mb-3">
                    <label className="form-label input-label">
                      Is the cook a certified chef?
                    </label>
                    <div className="radio-btn">
                      {/* {certify.map((item, index) => (
                        <div className="form-check" key={index}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="foodCertified"
                            onChange={() =>
                              handleInputChange("foodCertified", item.value)
                            }
                            value={item.value}
                            checked={isCertified === item.value}
                            id={`flexRadio${item.value}`}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`flexRadio${item.value}`}
                          >
                            {item.label}
                          </label>
                        </div>
                      ))} */}
                      <ButtonGroup>
                        {certify.map((item, index) => (
                          <ToggleButton
                            key={index}
                            id={`radio-${item.value}`}
                            type="radio"
                            // variant={
                            //   item.value ? "outline-success" : "outline-danger"
                            // }
                            style={{
                              backgroundColor:
                                isCertified === item.value
                                  ? "#ff7903"
                                  : "transparent",
                              color:
                                isCertified === item.value
                                  ? "#ffffff"
                                  : "#000000",
                              borderColor: "#ff7903",
                            }}
                            variant="outline-primary toggle-btn"
                            name="foodCertified"
                            value={item.value}
                            checked={isCertified === item.value}
                            onChange={() =>
                              handleInputChange("foodCertified", item.value)
                            }
                          >
                            {item.label}
                          </ToggleButton>
                        ))}
                      </ButtonGroup>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-3">
                    <label className="form-label input-label">
                      Is The Host Is Same As The Chef ?
                    </label>
                    <div className="radio-btn">
                      {/* {certify.map((item, index) => (
                        <div className="form-check" key={index}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="isChefHost"
                            onChange={() =>
                              handleInputChange("isChefHost", item.value)
                            }
                            value={item.value}
                            checked={isHostSame === item.value}
                            id={`flexRadio${item.value}`}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`flexRadio${item.value}`}
                          >
                            {item.label}
                          </label>
                        </div>
                      ))} */}
                      <ButtonGroup>
                        {certify.map((item, index) => (
                          <ToggleButton
                            key={index}
                            id={`toggle-${item.value}`}
                            type="radio"
                            // variant={
                            //   item.value ? "outline-success" : "outline-danger"
                            // }
                            style={{
                              backgroundColor:
                                isHostSame === item.value
                                  ? "#ff7903"
                                  : "transparent",
                              color:
                                isHostSame === item.value
                                  ? "#ffffff"
                                  : "#000000",
                              borderColor: "#ff7903",
                            }}
                            variant="outline-primary toggle-btn"
                            name="isChefHost"
                            value={item.value}
                            checked={isHostSame === item.value}
                            onChange={() =>
                              handleInputChange("isChefHost", item.value)
                            }
                          >
                            {item.label}
                          </ToggleButton>
                        ))}
                      </ButtonGroup>
                    </div>
                  </div>
                </div>
                {/*New UI*/}
                <div className="col-lg-4">
                  <div className="mb-3">
                    <label className="form-label input-label">
                      Will you be cooking up the food for this event?
                    </label>
                    <ButtonGroup>
                      {certify.map((item, index) => (
                        <ToggleButton
                          key={index}
                          id={`toggle-willCookFood-${index}`}
                          type="radio"
                          // variant={
                          //   item.value ? "outline-success" : "outline-danger"
                          // }
                          name="willCookFood"
                          variant="outline-primary toggle-btn"
                          value={item.value}
                          checked={formValues.willCookFood === item.value}
                          onChange={() =>
                            handleInputChange("willCookFood", item.value)
                          }
                        >
                          {item.label}
                        </ToggleButton>
                      ))}
                    </ButtonGroup>
                  </div>
                </div>
                {!isHostSame && (
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label className="form-label input-label">
                        Chef Experience
                        {/* <sup className="req">*</sup> */}
                      </label>
                      <textarea
                        className="form-control textarea-ht"
                        name="cookExperience"
                        onChange={handleChefExp}
                        value={formValues.cookExperience}
                        placeholder="Healthy and delicious French dishes made from high quality, seasonal, and fresh ingredients"
                        id="floatingTextarea2"
                      ></textarea>
                    </div>
                  </div>
                )}
                <div className="col-lg-12">
                  <div className="mb-3">
                    <label className="form-label input-label">
                      Host Experience
                    </label>
                    <textarea
                      className="form-control textarea-ht"
                      placeholder="I’m a professional host. I have hosted a similar experience before and I have received professional training."
                      id="floatingTextarea2"
                      value={hostExp}
                      onChange={(e) => setHostExp(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mx-auto">
          <div className="submit-btn next-btn justify-content-end">
            <div className="submit-btn next-btn text-end">
              <div>
                <button className="prev-arrow" onClick={prev}>
                  <FaArrowLeftLong />
                </button>
              </div>
              <div>
                <button className="btn" type="submit" onClick={nextSubmit}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loading />}
    </div>
  );
}

export default ChefHost;
