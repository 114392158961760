import React, { useEffect, useRef, useState } from "react";
import img1 from "../../assets/image/becomehost-pattern.png";
import img2 from "../../assets/image/becmehost6-img.png";
import img3 from "../../assets/image/upload-image.png";
import img4 from "../../assets/image/photo.png";
import { FaArrowLeft } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import { IoMdInformationCircleOutline } from "react-icons/io";
import {
  uploadHostDoc,
  uploadProfilePicture,
  getProfile,
  getHostDetails,
  deleteHostDoc,
} from "../../services/api/user-auth.api";
import Toaster from "../../common/Toaster";
import { RiDeleteBin6Line } from "react-icons/ri";
import { transformImageUrl } from "../../util/dataTransform";
import { v4 as uuidv4 } from "uuid";
import Loading from "../../common/Loading";
import "../host/host.scss";

function HostDoc({ nextHook, prevHook }) {
  const [profile, setProfile] = useState(img3);
  const [hostDocs, setHostDocs] = useState([]);
  const [storeDocs, setStoreDocs] = useState([]);
  const [hostDetail, setHostDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const profileUploadRef = useRef(null);
  const docUploadRef = useRef(null);
  const maxFiles = 5;
  const next = () => {
    nextHook();
  };
  const prev = () => {
    prevHook();
  };
  const handleImageUpload = (event) => {
    event.preventDefault();
    profileUploadRef.current.click();
  };
  const handleDocUpload = (event) => {
    event.preventDefault();
    docUploadRef.current.click();
  };
  const uploadImageDisplay = async () => {
    if (profileUploadRef.current && profileUploadRef.current.files.length > 0) {
      setIsLoading(true);
      const uploadedFile = profileUploadRef.current.files[0];
      const formData = new FormData();
      formData.append("profileImage", uploadedFile);
      try {
        await uploadProfilePicture(formData);
        const cachedURL = URL.createObjectURL(uploadedFile);
        setProfile(cachedURL);
        getUser();
        Toaster("Profile picture uploaded successfully", "success");
      } catch (error) {
        Toaster("Upload failed", "error");
      } finally {
        setIsLoading(false);
      }
    }
  };
  const uploadDocDisplay = async () => {
    const files = Array.from(docUploadRef.current.files);
    const validFiles = [];
    const cachedURLs = [];
    for (const file of files) {
      if (file.size > 1024 * 1024) {
        Toaster("File size should not exceed 1 MB.", "error");
      } else {
        const fileData = {
          file: file,
          id: uuidv4(),
        };
        validFiles.push(fileData); // Add valid file to the array
        cachedURLs.push({
          url: URL.createObjectURL(file),
          type: "preview",
          id: fileData.id,
        });
      }
    }
    setHostDocs((prevState) => [...prevState, ...cachedURLs]);
    setStoreDocs((prevState) => [...prevState, ...validFiles]);
  };
  const handleUpload = async () => {
    const stepId = 6;
    const allDocs = storeDocs.length + hostDetail.documents.length;
    if (allDocs === 0) {
      Toaster(
        "Please upload at least one document before proceeding.",
        "warning"
      );
      return;
    }
    // if(allDocs >= maxFiles){
    //     Toaster(`You can only upload a maximum of ${maxFiles} files.`, 'warning');
    //     return ;
    // }
    if (hostDocs.length > 4) {
      const formData = new FormData();
      storeDocs.forEach((data) => {
        formData.append(`hostDocuments`, data.file);
      });
      if (storeDocs.length > 0) {
        try {
          await uploadHostDoc(formData, stepId);
          getUser();
          getHost();
          Toaster("Documents uploaded successfully", "success");
        } catch (error) {
          Toaster("Upload failed", "error");
        }
      }
    } else {
      return Toaster("Upload minimum of 5 photos", "error");
    }
    next();
  };
  const removeDocument = async (doc) => {
    if (doc.type === "preview") {
      setHostDocs((prev) => prev.filter((item) => item.id !== doc.id));
      setStoreDocs((prev) => prev.filter((item) => item.id !== doc.id));
    } else if (doc.type === "existing") {
      deleteDocs(doc.id);
    }
  };
  const deleteDocs = async (id) => {
    try {
      await deleteHostDoc(id);
      setHostDocs((prev) => prev.filter((item) => item.id !== id));
      getHost();
      Toaster("Documents deleted successfully", "success");
    } catch (error) {
      Toaster("failed", "error");
    }
  };
  const getUser = async () => {
    try {
      const user = await getProfile();
      if (user) {
        const profileUrl = await transformImageUrl(user.profileFilepath);
        setProfile(profileUrl);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getHost = async () => {
    try {
      const host = await getHostDetails();
      if (host) {
        setHostDetail(host);
        const docUrl = host.documents.map((file) => ({
          url: transformImageUrl(file.documentURL),
          type: "existing",
          id: file.id,
        }));
        setHostDocs(docUrl);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUser();
    getHost();
  }, []);
  return (
    <>
      <div className="host-wrapper">
        <div className="become-host-sec becomehost-3 becomehost-6">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-lg-6 col-md-12 my-auto">
                <div className="becomehost-img">
                  <img src={img2} alt="meet-at-table" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="sign-in-form becomehost-txt shadow">
                  <div>
                    <h3>Time to show off!</h3>
                    <p>
                      We want to know more about you! <br />
                      At meet@table, we want to build the world's most trusted
                      community of foodies. One of the most important steps to
                      hosting a successful experience is ensuring guests feel
                      safe and trust you as a host. This starts with your
                      photos!
                    </p>
                  </div>
                  <Accordion className="acr-alert">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <IoMdInformationCircleOutline className="me-2" /> Keep
                        in mind
                      </Accordion.Header>
                      <Accordion.Body className="accordion-body">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Unde, est.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <div className="mt-3">
                    <h4>Your profile picture</h4>
                    <div className="image-upload-sec">
                      <div className="upload-image">
                        <img src={profile} alt="meet-at-table" />
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn"
                          onClick={handleImageUpload}
                        >
                          Upload Photo
                        </button>
                        <input
                          type="file"
                          ref={profileUploadRef}
                          onChange={uploadImageDisplay}
                          hidden
                          id="profile-photo-input"
                          name="profile_picture"
                          accept="image/png, image/jpeg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <h4>
                      Add a minimum of 5 photos that best represents the events
                      you are going to host
                    </h4>
                    <p>
                      The photos should be high quality and show the location of
                      the experience, the dishes or drinks you might serve,
                      people (guests from past experiences or friends you have
                      hosted) around the table enjoying your meal and details
                      such as table settings or the streets you will walk down
                      during a food tour. Good photos of people and food are key
                      to attracting guests!
                    </p>
                  </div>
                  <Accordion className="acr-alert">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <IoMdInformationCircleOutline className="me-2" /> Tips
                        for taking inviting photos
                      </Accordion.Header>
                      <Accordion.Body className="accordion-body">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Unde, est.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <button
                    className="drag-image text-center mt-3 w-100 bg-white"
                    onClick={handleDocUpload}
                    type="button"
                  >
                    <div className="blk-img">
                      <img src={img4} alt="meet-at-table" />
                    </div>
                    <div>
                      <p>click to upload or drag and drop a new file</p>
                    </div>
                  </button>
                  <input
                    type="file"
                    ref={docUploadRef}
                    onChange={uploadDocDisplay}
                    hidden
                    multiple
                    id="profile-photo-input"
                    name="profile_picture"
                    accept="image/png, image/jpeg"
                  />
                  <div className="uploaded-image-sec">
                    <div className="row g-3">
                      {hostDocs.map((doc, index) => (
                        <div className="col-md-4 position-relative" key={index}>
                          <div className="uploaded-img">
                            <img src={doc.url} alt={`Uploaded ${index}`} />
                          </div>
                          <div
                            className="trash-icon"
                            onClick={() => removeDocument(doc)}
                          >
                            <RiDeleteBin6Line />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="mt-3">
                    <h4>Upload a video(optional)</h4>
                    <p>Show our team your place and give us a wave!</p>
                  </div>
                  <Accordion className="acr-alert">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <IoMdInformationCircleOutline className="me-2" /> Tips
                        for taking inviting photos
                      </Accordion.Header>
                      <Accordion.Body className="accordion-body">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Unde, est.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <div className="drag-image text-center mt-3">
                    <div className="blk-img">
                      <img src={img4} alt="meet-at-table" />
                    </div>
                    <div>
                      <p>click to upload or drag and drop a new file</p>
                    </div>
                  </div>
                  <div>
                    <div className="submit-btn next-btn text-end">
                      <div className="next-btn1" onClick={prev}>
                        <FaArrowLeft />
                      </div>
                      <div>
                        <button
                          className="btn"
                          type="button"
                          onClick={handleUpload}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loading />}
    </>
  );
}

export default HostDoc;
