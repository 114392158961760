import React, { useState, useEffect } from "react";
import img1 from "../../assets/image/logo-light.svg";
import { ImCompass } from "react-icons/im";
import { Link } from "react-router-dom";
import { FaFacebookSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import {
  CONTACT_US,
  PRIVACY_POLICY,
  TERMS_AND_CONDITION,
} from "../../const/route-url.const";
import "../footer/footer.scss";
import "../../index.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { FaGooglePlay } from "react-icons/fa";
import { FaApple } from "react-icons/fa";
import { getFooterContent } from "../../services/api/site-info.api";
function Footer() {
  const [footerContent, setFooterContent] = useState([]);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleCheckboxGroup = (event, groupName) => {
    const checkboxes = document.querySelectorAll(`input[name="${groupName}"]`);
    checkboxes.forEach((checkbox) => {
      if (checkbox !== event.target) {
        checkbox.checked = false;
      }
    });
  };
  const getData = async () => {
    try {
      const res = await getFooterContent();
      setFooterContent(res);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div className="footer-wrapper">
        <div className="footer mt-0">
          <div className="container">
            <div className="row gap-3 gap-md-0">
              <div className="col-sm-12 col-md-12 col-lg-5 mb-3 mb-lg-0">
                <div>
                  <div className="footer-logo">
                    <img src={img1} alt="meet-at-table" />
                  </div>
                  <div
                    className="footer-content"
                    dangerouslySetInnerHTML={{
                      __html: footerContent?.text,
                    }}
                  ></div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-2 mb-3 mb-lg-0">
                <div className="footer-menu">
                  <h5>Help and Support</h5>
                  <ul>
                    {/* <li>
                          <Link to="/" onClick={scrollToTop}>Home</Link>
                        </li>
                        <li>
                          <Link to={ABOUT_US} onClick={scrollToTop}>Meet Us</Link>
                        </li>
                        <li>
                          <Link to={HOW_IT_WORKS} onClick={scrollToTop}>How It Works</Link>
                        </li>
                        <li>
                          <Link to={HANDPICKED_EVENTS} onClick={scrollToTop}>Events</Link>
                        </li>
                        <li>
                          <Link to={FAQ} onClick={scrollToTop}>FAQ</Link>
                        </li> */}
                    <li>
                      <Link to={CONTACT_US} onClick={scrollToTop}>
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-2 mb-3 mb-lg-0">
                <div className="footer-form">
                  <h5 className="mb-2">Follow Us</h5>
                  {/* <form>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control join-input"
                            placeholder="your email address"
                            aria-label="your email address"
                            aria-describedby="basic-addon1"
                          />
                          <span className="input-group-text" id="basic-addon1">
                            <ImCompass className="text-white" />
                          </span>
                        </div>
                      </form> */}

                  <div className="social-block  mb-3">
                    <Link
                      to="https://www.facebook.com/"
                      target="_blank"
                      className="btn p-0 m-0 me-1"
                    >
                      <FaFacebookSquare />
                    </Link>
                    <Link
                      to="https://www.linkedin.com/home"
                      target="_blank"
                      className="btn p-0 m-0 me-1"
                    >
                      <FaLinkedin />
                    </Link>
                    <Link
                      to="https://www.instagram.com/"
                      target="_blank"
                      className="btn p-0 m-0 me-1"
                    >
                      <FaInstagramSquare />
                    </Link>
                  </div>
                </div>
              </div>
              {/* Currency/ Social Icons */}
              <div className="col-sm-12 col-md-6 col-lg-3 mb-3 mb-lg-0">
                <div className="footer-form">
                  {/* <h5>Currency</h5>
                      <Dropdown>
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="tab-loc-btn lang-btn"
                        >
                          PLN
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="lang-dropdown">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="lang-check">
                                <p>Currency</p>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value=""
                                          name="currencyGroup"
                                          id="flexCheckChecked3"
                                          onChange={(e) =>
                                            handleCheckboxGroup(
                                              e,
                                              "currencyGroup"
                                            )
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="flexCheckChecked3"
                                        >
                                          PLN
                                        </label>
                                      </div>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="flexCheckChecked4"
                                          name="currencyGroup"
                                          onChange={(e) =>
                                            handleCheckboxGroup(
                                              e,
                                              "currencyGroup"
                                            )
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="flexCheckChecked4"
                                        >
                                          EUR €{" "}
                                        </label>
                                      </div>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="flexCheckChecked5"
                                          name="currencyGroup"
                                          onChange={(e) =>
                                            handleCheckboxGroup(
                                              e,
                                              "currencyGroup"
                                            )
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="flexCheckChecked5"
                                        >
                                          USD $
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown> */}
                  <h5 className="mb-2">Download Our Apps</h5>
                  <div className="social-block d-flex flex-wrap gap-1">
                    <Link
                      className="d-flex play-store me-2 rounded"
                      to="https://play.google.com/store/games?hl=en"
                      target="_blank"
                    >
                      <div className="btn p-0">
                        <FaGooglePlay />
                      </div>
                      <p className="mb-0 me-2 d-flex flex-column justify-content-center">
                        <span className="d-block link1">Download on the</span>
                        <span className="link2">App Store</span>
                      </p>
                    </Link>
                    <Link
                      className="d-flex play-store me-2 rounded"
                      to="https://www.apple.com/in/app-store/"
                      target="_blank"
                    >
                      <div className="btn p-0">
                        <FaApple />
                      </div>
                      <p className="mb-0 me-2 d-flex flex-column justify-content-center">
                        <span className="d-block link1">Download on the</span>
                        <span className="link2">App Store</span>
                      </p>
                    </Link>
                    <div className="footer-tc mt-2 mt-lg-4">
                      <Link to={TERMS_AND_CONDITION} onClick={scrollToTop}>
                        Terms and Conditions
                      </Link>
                      <Link to={PRIVACY_POLICY} onClick={scrollToTop}>
                        Privacy Policy
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <hr /> */}
            <div className="row mt-2">
              <div className="col-md-12 text-center">
                <div className="cpy-ryt">
                  <p>
                    &#169; 2024 All Rights Reserved.{" "}
                    <span className="text-white">
                      <a href="#">Meet@Table</a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
