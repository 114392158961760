import React, { useEffect, useRef } from "react";
import { SlLocationPin } from "react-icons/sl";
import img3 from "../../../assets/image/tom.png";
import blank from "../../../assets/image/blank-image.webp";
import { useNavigate } from "react-router-dom";
import { EVENTS } from "../../../const/route-url.const";
import { FaBookmark } from "react-icons/fa";
import noPhoto from "../../../assets/image/noPhoto.png";
import { transformImageUrl } from "../../../util/dataTransform";
import { MdOutlineStar } from "react-icons/md";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function TabContent({ eventList }) {
  const navigate = useNavigate();
  const sliderRef = useRef(null);

  const redirectToEvent = (event) => {
    navigate(`${EVENTS}/${event.id}`);
  };

  const settings = {
    dots: false,
    arrows: true,
    infinite: eventList.length > 4,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: eventList.length > 4,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const eventImageSetup = (arrDoc) => {
    if (arrDoc.length > 0) {
      const docUrl = transformImageUrl(arrDoc[0]?.eventDocumentPath);
      return docUrl;
    } else {
      return noPhoto;
    }
  };

  const totalSlides = 4;
  const placeholdersNeeded = totalSlides - eventList.length;

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickPlay();
    }
  }, [eventList]);
  const errorImageHandler = (e) => {
    e.target.src = noPhoto;
  };

  return (
    <div className="tab-content p-0">
      <div id="all" className="tab-pane active">
        <br />
        <Slider {...settings} ref={sliderRef} key={eventList.length}>
          {eventList.length > 0 &&
            eventList.map((event) => (
              <div
                className="col-lg-3 col-md-6 p-3 position-relative"
                key={event.id}
                onClick={() => redirectToEvent(event)}
              >
                <div className="card  h-100">
                  <div className="">
                    <div className="tab-img p-0 shadow-none">
                      {event.eventDocuments.length > 0 ? (
                        <img
                          src={eventImageSetup(event?.eventDocuments)}
                          className="card-img-top"
                          alt="images"
                          onError={errorImageHandler}
                        />
                      ) : (
                        <img
                          src={noPhoto}
                          className="card-img-top"
                          alt="noPhoto"
                        />
                      )}
                    </div>
                    {/* <div className="evnt-bk-mrk">
                      <FaBookmark />
                    </div> */}
                    <div className="evnt-ratng-sec d-flex justify-content-between align-items-center">
                      <p className="evnt-ratng">
                        <MdOutlineStar /> 5(90)
                      </p>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="tom-txt">
                      <p className="d-flex">
                        <span className="hgt hgt-small">By</span>{" "}
                        <span className="tom-img">
                          <img src={img3} alt="meet-at-table" />
                        </span>{" "}
                        {event?.firstName} {event?.lastName}
                      </p>
                    </div>
                    <div className="tab-evnt-dlr gap-1 d-flex justify-content-between">
                      <p className="text-truncate flex-grow-1 mb-1">
                        {event.eventCategory}
                      </p>
                      <p className="mb-1">
                        {event.currency} {event.gustPerCost || 0}
                        <span className="hgt hgt-small">/Guest</span>
                      </p>
                    </div>
                    <div className="d-flex flex-row-reverse align-items-center justify-content-between gap-1">
                      <div className="d-flex  align-items-center overflow-hidden location-icon">
                        <div>
                          <SlLocationPin className="location-pin" />
                        </div>
                        <p className="text-truncate mb-0 event-city">
                          {event.city}
                        </p>
                      </div>
                      <div className="tab-evnt-des overflow-hidden">
                        <p className="text-truncate mb-0 event-title">
                          {event.eventTitle}
                        </p>
                        {/* <p className="text-truncate mb-0 event-title">
                          {event.eventTitle}
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          {/* Ensure 4 slides are always displayed, including placeholders */}
          {placeholdersNeeded > 0 &&
            Array.from({ length: placeholdersNeeded }).map((_, index) => (
              <div className="slick-slide p-3" key={`placeholder-${index}`}>
                <div className="card h-100 shadow border-0 shimmer">
                  <div className="position-relative">
                    <div className="tab-img shadow-none border-0">
                      <img
                        src={blank}
                        className="card-img-top"
                        alt="meet-at-table"
                      />
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="blank-text"></div>
                    {/* <div className="blank-text-2"></div> */}
                    <div className="blank-text-3"></div>
                  </div>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </div>
  );
}

export default TabContent;
