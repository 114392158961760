import React, { useEffect, useState } from "react";
import img2 from "../../assets/image/becmehost1-img.png";
import { MdOutlinePhone } from "react-icons/md";
import { HiMiniChevronDown } from "react-icons/hi2";
import { FaArrowLeft } from "react-icons/fa";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { becomeHost, getHostDetails } from "../../services/api/user-auth.api";
import Select from "react-select";
import { PiEyeClosedLight } from "react-icons/pi";
import { PiEyeThin } from "react-icons/pi";
import { saveAccessToken, saveHostId } from "../../util/auth";
import Loading from "../../common/Loading";
import Toaster from "../../common/Toaster";
import { useDispatch } from "react-redux";
import { setTokenRefresh } from "../../redux/slice/tokenSlice";
import "../host/host.scss";
import { Link } from "react-router-dom";
import { getCountries } from "../../services/api/site-info.api";
import { InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";

function CreateHost({ langList, countryData, prevHookType, nextHookType }) {
  const dispatch = useDispatch();
  const [showPswd, setShowPswd] = useState(false);
  const [confirmPswd, setConfirmPswd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [entity, setEntity] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const schema = yup.object().shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    username: yup
      .string()
      .matches(/^[a-zA-Z0-9\s.,!?_-]*$/, "Special characters are not allowed")
      .required("Username is required"),
    email: yup.string().required("Email is required").email("Invalid email"),
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/\d/, "Password must contain at least one number")
      .matches(
        /[@$!%*?&]/,
        "Password must contain at least one special character"
      )
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .required("Confirm password is required")
      .oneOf([yup.ref("password"), null], "Password must match"),
    phoneNumber: yup
      .string()
      .matches(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        "Phone number is not valid"
      )
      .required("Phone number is required"),
    brandName: yup.string(),
    nativeLanguage: yup.number(),
    city: yup.string().required("City is required"),
    country: yup.string().required("Country is required"),
    isEntity: yup.boolean().required("Entity is required"),
    optedInForMarketingEmails: yup.boolean(),
    acceptedTermsAndConditions: yup
      .boolean()
      .oneOf(
        [true],
        "You must accept the terms and conditions & privacy policy"
      )
      .required("Terms acceptance is required"),
    countryCode: yup.string(),
  });
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      nativeLanguage: 3,
    },
  });
  const getHost = async () => {
    try {
      const hostData = await getHostDetails();
      await saveHostId(hostData.id);
    } catch (error) {
      console.log(error);
    }
  };
  const nextSubmit = async (formData) => {
    setIsLoading(true);
    const stepId = 1;
    try {
      const res = await becomeHost(formData, stepId);
      saveAccessToken(res.value.token);
      getHost();
      dispatch(setTokenRefresh(true));
      nextHookType();
    } catch (error) {
      Toaster(error.response.data, "error");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const getEntity = () => {
      try {
        const data = ["Individual", "Entity"];
        const list = data.map((option) => ({
          label: option,
          value: option === "Entity" ? true : false,
        }));
        setEntity(list);
      } catch (err) {
        console.log(err);
      }
    };
    getEntity();
  }, []);
  //country code
  const getCountryData = async () => {
    try {
      const res = await getCountries();
      setCountryList(res);
      setValue("countryCode", "+91");
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getCountryData();
  }, []);
  return (
    <>
      <div className="host-wrapper">
        <form onSubmit={handleSubmit(nextSubmit)}>
          <div className="become-host-sec">
            <div className="container h-100">
              <div className="row h-100 align-items-center">
                <div className="col-lg-6 col-md-12 my-auto">
                  <div className="becomehost-img">
                    <img src={img2} alt="meet-at-table" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="sign-in-form contact-form becomehost-txt shadow">
                    <div>
                      <h3>Let's get to know each other</h3>
                      <p>
                        We want to know more about you! <br /> First, fill in
                        your basic contact details. This information will be
                        private and will only be visible to the Eatwith team.
                        Your contact information will be shared with guests only
                        after the bookings are confirmed.
                      </p>
                    </div>
                    <div>
                      <div className="row gy-3">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label input-label">
                              First name<sup className="req">*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="First name"
                              {...register("firstName")}
                            />
                            {errors?.firstName && (
                              <span className="form-error">
                                {errors.firstName.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label input-label">
                              Last name<sup className="req">*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Last name"
                              {...register("lastName")}
                            />
                            {errors?.lastName && (
                              <span className="form-error">
                                {errors.lastName.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 mt-0">
                          <div className="mb-3">
                            <label className="form-label input-label">
                              Username<sup className="req">*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Username"
                              {...register("username")}
                            />
                            {errors?.username && (
                              <span className="form-error">
                                {errors.username.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 mt-0">
                          <div className="mb-3">
                            <label className="form-label input-label">
                              Email<sup className="req">*</sup>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Email"
                              {...register("email")}
                            />
                            {errors?.email && (
                              <span className="form-error">
                                {errors.email.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 mt-0">
                          <div className="mb-3">
                            <label className="form-label input-label">
                              Password<sup className="req">*</sup>
                            </label>
                            <div className="position-relative">
                              <input
                                type={showPswd ? "text" : "password"}
                                className="form-control"
                                placeholder="Password"
                                {...register("password")}
                              />
                              {errors?.password && (
                                <span className="form-error">
                                  {errors.password.message}
                                </span>
                              )}
                              <div
                                className="password-icon"
                                onClick={() => setShowPswd(!showPswd)}
                              >
                                {!showPswd && <PiEyeClosedLight />}
                                {showPswd && <PiEyeThin />}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mt-0">
                          <div className="mb-3 position-relative">
                            <label className="form-label input-label">
                              Confirm Password<sup className="req">*</sup>
                            </label>
                            <div className="position-relative">
                              <input
                                type={confirmPswd ? "text" : "password"}
                                className="form-control"
                                placeholder="Confirm Password"
                                {...register("confirmPassword")}
                              />
                              {errors?.confirmPassword && (
                                <span className="form-error">
                                  {errors.confirmPassword.message}
                                </span>
                              )}
                              <div
                                className="password-icon"
                                onClick={() => setConfirmPswd(!confirmPswd)}
                              >
                                {!confirmPswd && <PiEyeClosedLight />}
                                {confirmPswd && <PiEyeThin />}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 mt-0 mb-3">
                          <div className="mb-1 col-12">
                            <label className="form-label input-label">
                              Your Phone Number<sup className="req">*</sup>
                            </label>
                            <InputGroup className="mb-3 phone-block phone__block">
                              <InputGroup.Text id="basic-addon1">
                                <MdOutlinePhone />
                              </InputGroup.Text>
                              <div className="selectbox-block col-md-5 border">
                                {countryList?.length && (
                                  <Controller
                                    name="countryCode"
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        options={countryList.map((code) => ({
                                          value: code.countryCode,
                                          label: `${code.name} ${code.countryCode}`,
                                        }))}
                                        value={countryList
                                          .map((code) => ({
                                            value: code.countryCode,
                                            label: code.countryCode,
                                          }))
                                          .find(
                                            (option) =>
                                              option.value === field.value
                                          )}
                                        onChange={(selectedOption) =>
                                          field.onChange(selectedOption?.value)
                                        }
                                      />
                                    )}
                                  />
                                )}
                              </div>
                              <Form.Control
                                placeholder="+89 561 300 00"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                type="number"
                                {...register("phoneNumber")}
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "."].includes(e.key) &&
                                  e.preventDefault()
                                }
                              />
                            </InputGroup>
                            {errors?.phoneNumber && (
                              <span className="form-error">
                                {errors.phoneNumber.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 mt-0">
                          <div className="mb-3">
                            <label className="form-label input-label">
                              Choose any one (Individual / Entity)
                              <sup className="req">*</sup>
                            </label>
                            <div className="selectbox-block">
                              {entity?.length && (
                                <Controller
                                  name="isEntity"
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      options={entity}
                                      value={entity.find(
                                        (option) => option.value === field.value
                                      )}
                                      onChange={(selectedOption) =>
                                        field.onChange(selectedOption?.value)
                                      }
                                    />
                                  )}
                                />
                              )}
                            </div>
                            {errors?.isEntity && (
                              <span className="form-error">
                                {errors.isEntity.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 mt-0">
                          <div className="mb-3">
                            <label className="form-label input-label">
                              Your Brand Name (if you have one)
                            </label>
                            <p>
                              Your brand name will be displayed for other users
                              and guests to see
                            </p>
                            <input
                              type="text"
                              placeholder="Your Brand Name"
                              className="form-control"
                              {...register("brandName")}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mt-0">
                          <div className="mb-3">
                            <label className="form-label input-label">
                              City<sup className="req">*</sup>
                            </label>
                            <input
                              type="text"
                              placeholder="City"
                              className="form-control"
                              {...register("city")}
                            />
                            {errors?.city && (
                              <span className="form-error">
                                {errors.city.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 mt-0">
                          <div className="mb-3">
                            <label className="form-label input-label">
                              Country<sup className="req">*</sup>
                            </label>
                            <div className="selectbox-block">
                              {countryData?.length && (
                                <Controller
                                  name="country"
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      options={countryData}
                                      value={countryData.find(
                                        (option) => option.value === field.value
                                      )}
                                      onChange={(selectedOption) =>
                                        field.onChange(selectedOption?.value)
                                      }
                                    />
                                  )}
                                />
                              )}
                            </div>
                            {errors?.country && (
                              <span className="form-error">
                                {errors.country.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="form-check mx-3 market">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            {...register("optedInForMarketingEmails")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            Yes! I want to receive exclusive Meet@table offers,
                            travel inspo and al the food in my inbox
                          </label>
                        </div>
                        <div className="mx-1">
                          <div className="form-check terms">
                            <input
                              className={`form-check-input ${
                                errors?.acceptedTermsAndConditions
                                  ? "border-danger"
                                  : ""
                              }`}
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              {...register("acceptedTermsAndConditions")}
                            />

                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              I agree to{" "}
                              <span>
                                <Link
                                  to="/terms-and-conditions"
                                  target="_blank"
                                >
                                  Terms & Conditions
                                </Link>
                              </span>{" "}
                              and{" "}
                              <span>
                                <Link to="/privacy-policy" target="_blank">
                                  Privacy Policy
                                </Link>
                              </span>
                              .
                            </label>
                          </div>
                          {errors?.acceptedTermsAndConditions && (
                            <span className="form-error">
                              {errors.acceptedTermsAndConditions.message}
                            </span>
                          )}
                        </div>
                        <div className="col-md-12 mt-0">
                          <div className="submit-btn next-btn text-end">
                            <div className="next-btn1" onClick={prevHookType}>
                              <FaArrowLeft />
                            </div>
                            <div>
                              <button className="btn" type="submit">
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {isLoading && <Loading />}
    </>
  );
}

export default CreateHost;
