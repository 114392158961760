import React, { useContext, useEffect, useState } from "react";
import { collection, onSnapshot, doc, updateDoc } from 'firebase/firestore';
import { AuthContext } from "../../../context/AuthContext";
import { ChatContext } from "../../../context/ChatContext";
import { db } from "../../../firebase";
import { FaBell } from 'react-icons/fa';
import Messages from "./Messages";
import Input from "./Input";

const Chat = () => {
  const { currentUser } = useContext(AuthContext);
  const { data } = useContext(ChatContext);
  const [globalUnreadCount, setGlobalUnreadCount] = useState(0);

  useEffect(() => {
    if (!currentUser?.uid) return;

    const chatsRef = collection(db, 'chats');
    let totalUnreadCount = 0;

    const unsubscribe = onSnapshot(chatsRef, (snapshot) => {
      totalUnreadCount = 0;

      snapshot.docs.forEach((doc) => {
        const chatData = doc.data();
        const messages = chatData?.messages;
        const lastReadTimestamp = chatData.lastRead?.[currentUser?.uid] || 0;

        let chatUnreadCount = 0;
        messages.forEach((message) => {
          if (message.senderId !== currentUser.uid && message.date.toMillis() > lastReadTimestamp) {
            chatUnreadCount += 1;
          }
        });

        totalUnreadCount += chatUnreadCount;
      });

      setGlobalUnreadCount(totalUnreadCount);
    });

    return () => {
      unsubscribe();
    };
  }, [currentUser?.uid]);

  useEffect(() => {
    const resetUnreadCount = async () => {
      if (!data.chatId) {
        return;
      }

      const chatDocRef = doc(db, 'chats', data?.chatId);

      try {
        await updateDoc(chatDocRef, {
          [`lastRead.${currentUser?.uid}`]: Date.now(),
        });
      } catch (error) {
        console.log(error)
      }
    };

    if (data?.chatId) {
      resetUnreadCount();
    }
  }, [data?.chatId, currentUser?.uid]);

  return (
    <div className="chat">
      <div className="cht-header">
        <p>{data.user?.displayName}</p>
        <div className="chatIcons">
          <p className="bell-block m-0">
            <FaBell />
            {globalUnreadCount > 0 && <span className="unreadCount count">{globalUnreadCount}</span>}
          </p>
        </div>
      </div>
      
      <Messages />
      <Input />
    </div>
  );
};

export default Chat;
