import React, { useEffect, useState } from "react";
import img1 from "../../assets/image/becomehost-pattern.png";
import img2 from "../../assets/image/becmehost1-img.png";
import { MdOutlinePhone } from "react-icons/md";
import { HiMiniChevronDown } from "react-icons/hi2";
import { FaArrowLeft } from "react-icons/fa";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import {
  getProfile,
  patchHost,
  getHostDetails,
  createHost,
} from "../../services/api/user-auth.api";
import Select from "react-select";
import { getAccessToken, getHostId, saveHostId } from "../../util/auth";
import Toaster from "../../common/Toaster";
import { useDispatch } from "react-redux";
import { setTokenRefresh } from "../../redux/slice/tokenSlice";
import "../host/host.scss";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { getCountries } from "../../services/api/site-info.api";

function BecomeHost({ langList, countryData, prevHookType, nextHookType }) {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(null);
  const [entity, setEntity] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const schema = yup.object().shape({
    firstName: yup.string(),
    lastName: yup.string(),
    phoneNumber: yup
      .string()
      .matches(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        "Phone number is not valid"
      )
      .required("Phone number is required"),
    brandName: yup.string(),
    // nativeLanguage: yup.number(),
    city: yup.string().required("City is required"),
    country: yup.string().required("Country is required"),
    isEntity: yup.boolean(),
    countryCode: yup.string(),
  });
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      nativeLanguage: 3,
      firstName: "",
      lastName: "",
    },
  });
  const getUser = async () => {
    try {
      const user = await getProfile();
      if (user) {
        setUserData(user);
        const field = ["firstName", "lastName", "phoneNumber", "countryCode"];
        field.forEach((field) => setValue(field, user[field]));
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getHost = async () => {
    try {
      const hostData = await getHostDetails();
      if (hostData) {
        await saveHostId(hostData.id);
        const field = [
          "brandName",
          "nativeLanguage",
          "isEntity",
          "city",
          "country",
        ];
        field.forEach((field) => setValue(field, hostData[field]));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const transformFormValues = (formValues) => {
    return Object.keys(formValues).map((key) => ({
      operationType: "Add",
      path: `/${key}`,
      op: "replace",
      value: formValues[key],
    }));
  };
  const nextSubmit = async (formData) => {
    console.log("formData");
    const { firstName, lastName, ...apiData } = formData;
    const stepId = 1;
    try {
      const hostId = await getHostId();
      if (hostId) {
        const transformedData = transformFormValues(apiData);
        await patchHost(transformedData, stepId, hostId);
        await getHost();
        nextHookType();
      } else {
        apiData.userId = userData.userId;
        const transformedData = transformFormValues(apiData);
        await createHost(transformedData, stepId);
        await getHost();
        dispatch(setTokenRefresh(true));
        nextHookType();
      }
    } catch (error) {
      Toaster("Error occured while saving the data", "error");
    }
  };
  useEffect(() => {
    const token_val = getAccessToken();
    if (token_val) {
      getUser();
      getHost();
    }
  }, []);
  useEffect(() => {
    const getEntity = () => {
      try {
        const data = ["Individual", "Entity"];
        const list = data.map((option) => ({
          label: option,
          value: option === "Entity" ? true : false,
        }));
        setEntity(list);
      } catch (err) {
        console.log(err);
      }
    };
    getEntity();
    getCountryData();
  }, []);
  const getCountryData = async () => {
    try {
      const res = await getCountries();
      setCountryList(res);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit(nextSubmit)}>
        <div className="host-wrapper">
          <div className="become-host-sec">
            <div className="container h-100">
              <div className="row h-100 align-items-center">
                <div className="col-lg-6 col-md-12 my-auto">
                  <div className="becomehost-img">
                    <img src={img2} alt="meet-at-table" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="sign-in-form contact-form becomehost-txt shadow">
                    <div>
                      <h3>Let's get to know each other</h3>
                      <p>
                        We want to know more about you! <br /> First, fill in
                        your basic contact details. This information will be
                        private and will only be visible to the Eatwith team.
                        Your contact information will be shared with guests only
                        after the bookings are confirmed.
                      </p>
                    </div>
                    <div>
                      <div className="row gy-3">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label input-label">
                              First name<sup className="req">*</sup>
                            </label>
                            <input
                              type="text"
                              readOnly={true}
                              className="form-control"
                              placeholder="First name"
                              {...register("firstName")}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label input-label">
                              Last name<sup className="req">*</sup>
                            </label>
                            <input
                              type="text"
                              readOnly={true}
                              className="form-control"
                              placeholder="Last name"
                              {...register("lastName")}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 mt-0 mb-3">
                          <label className="form-label input-label">
                            Your Phone number<sup className="req">*</sup>
                          </label>
                          <InputGroup className="mb-3 phone-block phone__block">
                            <InputGroup.Text id="basic-addon1">
                              <MdOutlinePhone />
                            </InputGroup.Text>
                            <div className="selectbox-block border">
                              {countryList?.length && (
                                <Controller
                                  name="countryCode"
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      options={countryList.map((code) => ({
                                        value: code.countryCode,
                                        label: `${code.name} ${code.countryCode}`,
                                      }))}
                                      value={countryList
                                        .map((code) => ({
                                          value: code.countryCode,
                                          label: code.countryCode,
                                        }))
                                        .find(
                                          (option) =>
                                            option.value === field.value
                                        )}
                                      onChange={(selectedOption) =>
                                        field.onChange(selectedOption?.value)
                                      }
                                    />
                                  )}
                                />
                              )}
                            </div>
                            <Form.Control
                              placeholder=""
                              aria-label=""
                              aria-describedby="basic-addon1"
                              type="number"
                              readOnly={true}
                              {...register("phoneNumber")}
                              onKeyDown={(e) =>
                                ["e", "E", "+", "."].includes(e.key) &&
                                e.preventDefault()
                              }
                            />
                          </InputGroup>
                          {/* <div className="input-group ">
                                                        <span className="input-group-text" id="basic-addon1">
                                                            <MdOutlinePhone />
                                                            <HiMiniChevronDown />
                                                        </span>
                                                    <input type="number" className="form-control" aria-label="Username" aria-describedby="basic-addon1" placeholder="+89 561 300 00" {...register('phoneNumber')} /> 
                                                       
                                                    </div> */}
                          {errors?.phoneNumber && (
                            <span className="form-error">
                              {errors.phoneNumber.message}
                            </span>
                          )}
                        </div>
                        <div className="col-md-12 mt-0">
                          <div className="mb-3">
                            <label className="form-label input-label">
                              Choose any one (Individual / Entity)
                              <sup className="req">*</sup>
                            </label>
                            <div className="selectbox-block">
                              {entity?.length && (
                                <Controller
                                  name="isEntity"
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      options={entity}
                                      value={entity.find(
                                        (option) => option.value === field.value
                                      )}
                                      onChange={(selectedOption) =>
                                        field.onChange(selectedOption?.value)
                                      }
                                    />
                                  )}
                                />
                              )}
                            </div>
                            {errors?.isEntity && (
                              <span className="form-error">
                                {errors.isEntity.message}
                              </span>
                            )}
                          </div>
                        </div>
                        {/* <div className="col-md-12 mt-0">
                          <div className="mb-3">
                            <label className="form-label input-label">
                              What is your native language?
                            </label>
                            <p>You can speak, read write in this language</p>
                            <div className="selectbox-block">
                              {langList?.length && (
                                <Controller
                                  name="nativeLanguage"
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      options={langList}
                                      value={langList.find(
                                        (option) => option.value === field.value
                                      )}
                                      defaultValue={langList.find(
                                        (option) => option.value === 3
                                      )} // Setting the default language to English (ID: 3)
                                      onChange={(selectedOption) =>
                                        field.onChange(selectedOption?.value)
                                      }
                                    />
                                  )}
                                />
                              )}
                            </div>
                          </div>
                        </div> */}
                        <div className="col-md-12 mt-0">
                          <div className="mb-3">
                            <label className="form-label input-label">
                              Your Brand Name (if you have one)
                            </label>
                            <p>
                              Your brand name will be displayed for other users
                              and guests to see
                            </p>
                            <input
                              type="text"
                              placeholder="Your Brand Name"
                              className="form-control"
                              {...register("brandName")}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mt-0">
                          <div className="mb-3">
                            <label className="form-label input-label">
                              City<sup className="req">*</sup>
                            </label>
                            <input
                              type="text"
                              placeholder="City"
                              className="form-control"
                              {...register("city")}
                            />
                            {errors?.city && (
                              <span className="form-error">
                                {errors.city.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 mt-0">
                          <div className="mb-3">
                            <label className="form-label input-label">
                              Country<sup className="req">*</sup>
                            </label>
                            <div className="selectbox-block">
                              {countryData?.length && (
                                <Controller
                                  name="country"
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      options={countryData}
                                      value={countryData.find(
                                        (option) => option.value === field.value
                                      )}
                                      onChange={(selectedOption) =>
                                        field.onChange(selectedOption?.value)
                                      }
                                    />
                                  )}
                                />
                              )}
                            </div>
                            {errors?.country && (
                              <span className="form-error">
                                {errors.country.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 mt-0">
                          <div className="submit-btn next-btn text-end">
                            <div className="next-btn1" onClick={prevHookType}>
                              <FaArrowLeft />
                            </div>
                            <div>
                              <button className="btn" type="submit">
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default BecomeHost;
