import React from 'react';
import './pagination.scss';
import { GoChevronRight } from "react-icons/go";
import { GoChevronLeft } from "react-icons/go";
const Pagination = ({ currentPage, totalCount, pageSize, onPageChange }) => {
  const totalPages = Math.ceil(totalCount / pageSize);
 
  const handlePageClick = (page) => {
    if (typeof page === "number" && page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };
 
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxDisplayedPages = 5;
    let startPage = Math.max(currentPage - Math.floor(maxDisplayedPages / 2), 1);
    let endPage = startPage + maxDisplayedPages - 1;
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(endPage - maxDisplayedPages + 1, 1);
    }
 
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };
 
  return (
    <div className="pagination pt-3 mt-auto">
    <a
      onClick={() => handlePageClick(currentPage - 1)}
      className={`arrow arrow-left ${currentPage === 1 ? 'disabled' : ''}`}
    >
      <GoChevronLeft />
    </a>
 
    {getPageNumbers().map((page) => (
      <a
        key={page} // Use page as the key for better performance
        onClick={() => handlePageClick(page)}
        className={`pagination-button ${page === currentPage ? 'active' : ''}`}
      >
        {page}
      </a>
    ))}
 
    <a
      onClick={() => handlePageClick(currentPage + 1)}
      className={`arrow arrow-right ${currentPage === totalPages ? 'disabled' : ''}`}
    >
     <GoChevronRight />
    </a>
  </div>
  );
};
 
export default Pagination;