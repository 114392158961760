import React, { useState, useEffect } from "react";
import WelcomeHost from "./WelcomeHost";
import HostInfo from "./HostInfo";
import TableHost from "./TableHost";
import HostingExperience from "./HostingExperience";
import HostingType from "./HostingType";
import HostingPlace from "./HostingPlace";
import HostDoc from "./HostDoc";
import HostPlan from "./HostPlan";
import { getHostId } from "../../util/auth";
import {
  patchHost,
  getHostDetails,
  refreshhostform,
} from "../../services/api/user-auth.api";
import HostStatus from "./HostStatus";
import Toaster from "../../common/Toaster";
import "../host/host.scss";
import { getAccessToken } from "../../util/auth";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessPaymentModal from "./modal/SuccessPaymentModal";
import HostTypeModal from "../host/modal/HostTypeModal";

function Host() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const [modalShow1, setModalShow1] = React.useState(false);
  const [hostId, setHostId] = useState(getHostId());
  const [hostDetails, setHostDetails] = useState(null);
  const [step, setStep] = useState(0);
  const [modalShow2, setModalShow2] = React.useState(false);
  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => prev - 1);
  const transformFormValues = (formValues) => {
    return Object.keys(formValues).map((key) => ({
      operationType: "Add",
      path: `/${key}`,
      op: "replace",
      value: formValues[key],
    }));
  };
  const ChangeEvent = async (data, stepId) => {
    const transformedData = transformFormValues(data);
    try {
      const hostId = await getHostId();
      await patchHost(transformedData, stepId, hostId);
      getHost("update");
      nextStep();
    } catch (error) {
      Toaster("Error occured while saving the data", "error");
    }
  };
  const getHost = async (type) => {
    try {
      const host = await getHostDetails();
      if (host) {
        setHostDetails(host);
        checkToken(host);
        if (type === "initial") {
          const id = host.stepId === 7 ? host.stepId + 1 : host.stepId;
          await setStep(id);
          if (host?.verificationStatus === 0) {
            setModalShow1(true);
          }
        }
      }
    } catch (error) {
      console.log("error");
    }
  };
  const checkToken = (host) => {
    const user = getAccessToken();
    if (token && user && host?.verificationStatus === 27) {
      bookRazorpay(host);
    }
  };
  useEffect(() => {
    if (hostId) {
      getHost("initial");
    } else {
      if (token) {
        const user = getAccessToken();
        if (!user) {
          navigate(
            `/login?redirect=${encodeURIComponent(
              location.pathname + location.search
            )}`
          );
        }
      }
    }
  }, []);

  const bookRazorpay = (hostInfo) => {
    const options = {
      key: "rzp_test_B8PBhV3c69dj3L",
      amount: hostInfo.menbershipAmount * 100,
      currency: "INR",
      name: "Meet@Table",
      description: "Test Transaction",
      handler: function (response) {
        setModalShow2(true);
      },
      prefill: {
        name: "Test User",
        email: "testuser@example.com",
        contact: "9999999999",
      },
      notes: {
        membershipId: hostInfo.membershipId,
        hostId: hostInfo.id,
      },
      theme: {
        color: "#ff7903",
      },
    };
    const pay = new window.Razorpay(options);
    pay.open();
  };
  const closeSuccessPayment = async (data) => {
    setModalShow2(false);
    if (data === "redirect") {
      getHost();
    }
  };

  const getHostStatusForm = async (hostStatus) => {
    if (hostId) {
      if (hostStatus === 1) {
        try {
          const refreshData = await refreshhostform(hostId);
          if (refreshData) {
            setStep(1);
            setModalShow1(false);
          }
        } catch (error) {
          Toaster("Error occured while saving the data", "error");
        }
      } else {
        getHost();
      }
    }
  };

  return (
    <>
      {(() => {
        switch (step) {
          case 0:
            return <WelcomeHost nextHook={nextStep} />;
          case 1:
            return (
              <HostInfo
                nextHook={nextStep}
                prevHook={prevStep}
                InputEventHook={ChangeEvent}
              />
            );
          case 2:
            return (
              <TableHost
                prevHook={prevStep}
                InputEventHook={ChangeEvent}
                hostData={hostDetails}
              />
            );
          case 3:
            return (
              <HostingExperience
                prevHook={prevStep}
                InputEventHook={ChangeEvent}
                hostData={hostDetails}
              />
            );
          case 4:
            return (
              <HostingType
                prevHook={prevStep}
                InputEventHook={ChangeEvent}
                hostData={hostDetails}
              />
            );
          case 5:
            return (
              <HostingPlace
                prevHook={prevStep}
                InputEventHook={ChangeEvent}
                hostData={hostDetails}
              />
            );
          case 6:
            return (
              <HostDoc
                nextHook={nextStep}
                prevHook={prevStep}
                InputEventHook={ChangeEvent}
              />
            );
          case 7:
            return (
              <HostPlan
                nextHook={nextStep}
                prevHook={prevStep}
                InputEventHook={ChangeEvent}
                hostData={hostDetails}
                refresh={getHost}
              />
            );
          case 8:
            return (
              <HostStatus
                nextHook={nextStep}
                prevHook={prevStep}
                hostData={hostDetails}
                InputEventHook={ChangeEvent}
              />
            );
          default:
            return <h1>No project match</h1>;
        }
      })()}
      <SuccessPaymentModal show={modalShow2} onHide={closeSuccessPayment} />
      <HostTypeModal
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        selectedOption={getHostStatusForm}
      />
    </>
  );
}

export default Host;
