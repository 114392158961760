import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { createHostEventById } from "../../../services/api/events-auth.api";
import Toaster from "../../../common/Toaster";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

function LocationTab({ event, refreshDetails, inActive }) {
  const [showAddress] = useState([
    { label: "Yes", value: true },
    { label: "No", value: false },
  ]);
  const schema = yup.object().shape({
    primaryAddress: yup
      .string()
      .nullable()
      .required("Address is required")
      .typeError("Number of guest is required"),
    secondaryAddress: yup.string(),
    city: yup
      .string()
      .nullable()
      .required("City is required")
      .typeError("City is required"),
    state: yup
      .string()
      .nullable()
      .required("State is required")
      .typeError("State is required"),
    zipCode: yup
      .number()
      .nullable()
      .required("Zipcode is required")
      .typeError("Zipcode is required"),
    country: yup
      .string()
      .nullable()
      .required("Country is required")
      .typeError("Country is required"),
    privateAddress: yup.boolean(),
  });
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      privateAddress: event?.privateAddress || false,
    },
  });
  useEffect(() => {
    if (event) {
      const field = [
        "primaryAddress",
        "secondaryAddress",
        "city",
        "state",
        "zipCode",
        "country",
        "privateAddress",
      ];
      field.forEach((field) => {
        if (field !== null) {
          setValue(field, event[field]);
        }
      });
    }
  }, [event]);
  const nextSubmit = (formData) => {
    const stepId = 2;
    updateEvent(formData, stepId);
  };
  const updateEvent = async (formData, stepId) => {
    const transformedData = transformFormValues(formData);
    try {
      if (event?.id) {
        const res = await createHostEventById(
          transformedData,
          event?.id,
          stepId
        );
      }
      Toaster("Updated successfully", "success");
      refreshDetails();
    } catch (error) {
      console.log(error);
    }
  };
  const transformFormValues = (formValues) => {
    return Object.keys(formValues).map((key) => ({
      operationType: "Add",
      path: `/${key}`,
      op: "replace",
      value: formValues[key],
    }));
  };
  return (
    <form onSubmit={handleSubmit(nextSubmit)}>
      <div className="row">
        <div className="col-md-12 mx-auto">
          <div className="create-event-tab">
            <div className="row">
              <div className="col-lg-12">
                <div className="mb-3">
                  <label className="form-label input-label">
                    Primary Address
                    <sup className="req">*</sup>
                  </label>
                  <textarea
                    className="form-control textarea-ht"
                    readOnly={inActive}
                    placeholder="Leave a comment here"
                    {...register("primaryAddress")}
                    id="floatingTextarea2"
                  ></textarea>
                  {errors?.primaryAddress && (
                    <span className="form-error">
                      {errors.primaryAddress.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="mb-3">
                  <label className="form-label input-label">
                    Secondary Address
                  </label>
                  <textarea
                    className="form-control textarea-ht"
                    readOnly={inActive}
                    placeholder="Leave a comment here"
                    {...register("secondaryAddress")}
                    id="floatingTextarea2"
                  ></textarea>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="mb-3">
                  <label className="form-label input-label">
                    Country
                    <sup className="req">*</sup>
                  </label>
                  <input
                    type="text"
                    readOnly={inActive}
                    className="form-control"
                    {...register("country")}
                    placeholder="Enter your country"
                  />
                  {errors?.country && (
                    <span className="form-error">{errors.country.message}</span>
                  )}
                </div>
              </div>
              <div className="col-lg-3">
                <div className="mb-3">
                  <label className="form-label input-label">
                    State
                    <sup className="req">*</sup>
                  </label>
                  <input
                    type="text"
                    readOnly={inActive}
                    className="form-control"
                    {...register("state")}
                    placeholder="Enter your state"
                  />
                  {errors?.state && (
                    <span className="form-error">{errors.state.message}</span>
                  )}
                </div>
              </div>
              <div className="col-lg-3">
                <div className="mb-3">
                  <label className="form-label input-label">
                    City
                    <sup className="req">*</sup>
                  </label>
                  <input
                    type="text"
                    readOnly={inActive}
                    className="form-control"
                    {...register("city")}
                    placeholder="Enter your city"
                  />
                  {errors?.city && (
                    <span className="form-error">{errors.city.message}</span>
                  )}
                </div>
              </div>
              <div className="col-lg-3">
                <div className="mb-3">
                  <label className="form-label input-label">
                    Zipcode
                    <sup className="req">*</sup>
                  </label>
                  <input
                    type="number"
                    readOnly={inActive}
                    className="form-control"
                    {...register("zipCode")}
                    placeholder="Enter your zipcode"
                  />
                  {errors?.zipCode && (
                    <span className="form-error">{errors.zipCode.message}</span>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="form-label input-label">
                    Do You Want To Show The Address On The Event Page or Not ?
                  </label>
                  <p className="guest__Label">
                    in case you are hosting at your home / private space, the
                    exact address details will only be emailed to confirmed
                    guests
                  </p>
                  <div className="radio-btn">
                    <ButtonGroup className="mb-2 gap-3">
                      {showAddress.map((item, idx) => (
                        <ToggleButton
                          key={idx}
                          id={`radio-${idx}`}
                          type="radio"
                          name="privateAddress"
                          variant="outline-primary toggle-btn"
                          {...register("privateAddress")}
                          checked={watch("privateAddress") === item.value}
                          onChange={() =>
                            setValue("privateAddress", item.value)
                          }
                          value={item.value}
                        >
                          {item.label}
                        </ToggleButton>
                      ))}
                    </ButtonGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mx-auto">
          <div className="submit-btn next-btn justify-content-end">
            <div className="submit-btn next-btn text-end">
              <div>
                <button className="btn" type="submit" disabled={inActive}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default LocationTab;
