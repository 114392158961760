import React from "react";
import Banner from "../homepage/banner/Banner";

import HowItWorks from "../homepage/howItWorks/HowItWorks";
import HandPicked from "../homepage/hand-picked/HandPicked";
import EventsNear from "../homepage/events-near/EventsNear";
import PopularEvents from "../homepage/popular-events/PopularEvents";
import PopularDestination from "../homepage/popular-destination/PopularDestination";
import Faq from "../homepage/faq/Faq";
import AboutUs from "../homepage/aboutUs/AboutUs";

function Homepage() {
  return (
    <>
      {/* navigation-menu */}
      <div className="banner-sec position-relative banner-sec-ch">
        <Banner />
      </div>
      <div className="home-wrapper-bg">
        <div className="home-bg-overlay"></div>
        <div className="home-bg-content">
          {/* Popular Events  */}
          <PopularEvents />
          {/* Events near */}
          <EventsNear />
          {/* aboutUs */}
          <AboutUs />
          {/* how it works */}
          <HowItWorks />
          {/* FAQ */}
          <Faq />
        </div>
      </div>
    </>
  );
}

export default Homepage;
