import React from "react";
import { Modal } from "react-bootstrap";
import { FaCheck } from "react-icons/fa6";
import Button from "react-bootstrap/Button";
import '../edit-event.scss'
function RejectBookingModal(props) {
    
   const rejectApproval = () => {
    props.onConfirm();
   }
  return (
    <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Body>
      <div className="row delete-section">
          <div className="col-lg-12">
              <div className="delete-text">
                <span>
                <FaCheck />
                </span>
                  <p>Are you sure you want to reject the booking !</p>
              </div>
          </div>
          <div className="col-12">
          <div className="text-center submit-btn w-100 d-flex mt-2 justify-content-center create-event-modal-btn">
            <Button onClick={()=> props.onHide()} className="delete-btn">
            Cancel
            </Button>
            <Button onClick={rejectApproval} className="btn">
                  Reject
            </Button>
          </div>
          </div>
      </div>
    </Modal.Body>
  </Modal>
  )
}

export default RejectBookingModal