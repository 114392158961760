import React, { useState, useEffect, useRef } from "react";
import img2 from "../../assets/image/location.png";
import img3 from "../../assets/image/calender.png";
import img4 from "../../assets/image/guest.png";
import "../search/search.scss";
import "../../index.scss";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { getSearchEvents } from "../../services/api/events-auth.api";
import AutocompleteSearch from "../../shared/autocomplete-search/autocomplete-search";
import { FiSearch } from "react-icons/fi";
import { useDispatch } from "react-redux";
import {
  searchBoolean,
  sendEvents,
  sendEventValues,
} from "../../redux/slice/searchSlice";
import { HANDPICKED_EVENTS } from "../../const/route-url.const";
import { RiCloseLine } from "react-icons/ri";
function Search() {
  const [hasActiveSearch, setHasActiveSearch] = useState(false);
  const [values, setValues] = useState([new DateObject(), new DateObject()]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState();
  const [guests, setGuests] = useState(1);
  const autocompleteRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const incrementGuests = () => {
    setGuests((prevGuests) => prevGuests + 1);
  };
  const decrementGuests = () => {
    setGuests((prevGuests) => (prevGuests > 1 ? prevGuests - 1 : 1));
  };
  const [searchEvents, setSearchEvents] = useState({
    Address: null,
    PageId: 1,
    PageSize: 10,
  });
  const selectDate = (dates) => {
    const [start, end] = dates || [];
    const formattedStart = start
      ? format(new Date(start), "MM/dd/yyyy")
      : "Not selected";
    const formattedEnd = end
      ? format(new Date(end), "MM/dd/yyyy")
      : "Not selected";
    setSearchEvents((prev) => ({
      ...prev,
      FromDate: formattedStart,
      ToDate: formattedEnd,
    }));
  };
  const handleAddressSelect = (address) => {
    setSearchEvents((prev) => ({ ...prev, Address: address }));
  };
  const resetSearch = () => {
    setValues([new DateObject(), null]);
    setGuests(1);
    setSearchEvents({ Address: null, PageId: 1, PageSize: 10 });
    if (autocompleteRef.current) {
      autocompleteRef.current.clear();
    }
    setHasActiveSearch(false);
  };

  const formatStartOfDayISO = (date) => {
    const d = new Date(date);
    if (!(d instanceof Date) || isNaN(d.getTime())) {
      throw new Error("Invalid date value");
    }
    d.setHours(0, 0, 0, 0);
    return new Date(
      Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0)
    ).toISOString();
  };

  const formatEndOfDayISO = (date) => {
    const d = new Date(date);
    if (!(d instanceof Date) || isNaN(d.getTime())) {
      throw new Error("Invalid date value");
    }
    d.setHours(23, 59, 59, 999);
    return new Date(
      Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59, 999)
    ).toISOString();
  };
  const handleSearch = async () => {
    if (searchEvents.Address === null) return;
    const currentDate = new Date();
    const isValidDate = (date) => date instanceof Date && !isNaN(date);

    const fromDate = isValidDate(new Date(searchEvents.FromDate))
      ? formatStartOfDayISO(searchEvents.FromDate)
      : formatStartOfDayISO(currentDate);

    const toDate = isValidDate(new Date(searchEvents.ToDate))
      ? formatEndOfDayISO(searchEvents.ToDate)
      : formatEndOfDayISO(currentDate);

    const updatedSearchEvents = {
      ...searchEvents,
      FromDate: fromDate,
      ToDate: toDate,
      ...(guests >= 1 && { NoOfGuest: guests }),
    };
    setIsLoading(true);
    try {
      const data = await getSearchEvents(updatedSearchEvents);
      dispatch(sendEvents(data));
      dispatch(searchBoolean(true));
      dispatch(sendEventValues(updatedSearchEvents));
      navigate(`/${HANDPICKED_EVENTS}`);
      // setFilteredEvents(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setSearchEvents((prev) => ({ ...prev, NoOfGuest: guests }));
  }, [guests]);

  useEffect(() => {
    if (searchEvents.Address || guests > 1) {
      setHasActiveSearch(true);
    } else {
      setHasActiveSearch(false);
    }
  }, [searchEvents, guests, startDate, endDate]);
  return (
    <>
      {/* search bar */}
      <div className="search-wrapper ad-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-lg-4 ad-col ad_col my-auto">
                  <div className="d-flex align-items-center">
                    <div className="ad-icon">
                      <img src={img2} alt="meet-at-table" />
                    </div>
                    <div className="autocomplete-search  position-relative">
                      <AutocompleteSearch
                        ref={autocompleteRef}
                        onSelectAddress={handleAddressSelect}
                      />
                      {hasActiveSearch && (
                        <div className="loc-close-btn" onClick={resetSearch}>
                          <RiCloseLine />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col ad-col ad_col2 my-auto">
                  <div className="d-flex align-items-center">
                    <div className="ad-icon">
                      <img src={img3} alt="meet-at-table" />
                    </div>
                    <div className="rmdp-container-main">
                      <DatePicker
                        value={values}
                        selected={startDate}
                        onChange={selectDate}
                        startDate={startDate}
                        endDate={endDate}
                        format="DD MMM YYYY"
                        range
                        inline
                        minDate={new Date(new Date().setHours(0, 0, 0, 0))}
                      />
                      {/* <ul>
                        <li>
                          19 July <br />
                          <span className="hgt">To</span> <br />
                          19 Aug
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </div>
                <div className="col my-auto ad_col3">
                  <div className="d-flex align-items-center guest-count-block-main">
                    <div className="ad-icon">
                      <img src={img4} alt="meet-at-table" />
                    </div>
                    <div className="guest-count-block">
                      <ul>
                        <li>
                          <div
                            id="guestCount"
                            className="d-flex align-items-cente guest-count"
                          >
                            <span className="me-3">{guests}</span>
                            <button
                              className="btn me-2 "
                              onClick={decrementGuests}
                              disabled={guests === 1}
                            >
                              -
                            </button>
                            <button
                              className="btn btn-minus"
                              onClick={incrementGuests}
                            >
                              +
                            </button>
                          </div>
                          <span className="hgt">Guest</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="w-auto my-auto col">
                  <div className="text-end">
                    <button
                      className="btn"
                      onClick={() => handleSearch()}
                      disabled={!searchEvents.Address}
                      style={{ opacity: !searchEvents.Address ? 0.5 : 1 }}
                    >
                      <FiSearch />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Search;
