import React from "react";
import img1 from "../../assets/image/becmehost8-img.png";
import { Link } from "react-router-dom";
import "../host/host.scss";

function HostStatus({ hostData }) {
  return (
    <div className="host-wrapper">
      <div className="become-host-sec becomehost-8">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-lg-6 col-md-12 my-auto">
              <div className="becomehost-img">
                <img src={img1} alt="meet-at-table" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="becomehost-txt">
                <div>
                  <div>
                    <h2>
                      Congratulations! Your application to become an meet@table
                      host has been sent!
                    </h2>
                    <p>
                      Our Community Team will review your application and keep
                      you updated via email.
                    </p>
                  </div>
                  <h4>What's next?</h4>
                  <div className="bcm-list position-relative">
                    <div>
                      <ul>
                        <li>
                          <div className="form-check">
                            <input
                              className="form-check-input checkbox-round"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              checked
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Plan Selected
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check">
                            <input
                              className="form-check-input checkbox-round"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              checked={
                                hostData?.verificationStatus === 3 ||
                                hostData?.verificationStatus === 27 ||
                                hostData?.verificationStatus === 1
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Submitted
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check">
                            <input
                              className="form-check-input checkbox-round"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              checked={
                                hostData?.verificationStatus === 27 ||
                                hostData?.verificationStatus === 1
                              }
                            />
                            {(hostData?.verificationStatus === 3 ||
                              hostData?.verificationStatus === 27) && (
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckDefault"
                              >
                                Wait for Payment
                              </label>
                            )}
                            {hostData?.verificationStatus === 1 && (
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckDefault"
                              >
                                Payment Completed
                              </label>
                            )}
                          </div>
                        </li>
                        <li>
                          <div className="form-check ">
                            <input
                              className="form-check-input checkbox-round"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              checked={hostData?.verificationStatus === 1}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Completed
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="st-btn text-center">
                    <Link to="/">
                      <button type="button">Go to my profile</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HostStatus;
