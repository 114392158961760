import { MdOutlineStar } from "react-icons/md";
const StarRating = ({ category, selectedStar, setSelectedStar }) => {
    const handleStarClick = (starIndex) => {
      setSelectedStar(starIndex);
    };
  
    return (
      <div className="star-rating d-flex">
        <label>{category}</label>
        <div  className="ms-auto">
        {[1, 2, 3, 4, 5].map((star) => (
          <MdOutlineStar
            key={star}
            size={30}
            onClick={() => handleStarClick(star)}
            style={{
              cursor: "pointer",
              color: star <= selectedStar ? "rgb(255, 121, 3)" : "gray", 
            }}
          />
        ))}
        </div>
      </div>
    );
  };
  export default StarRating;