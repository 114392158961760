import React, {useState,useEffect} from 'react'
import { createHostEventById } from '../../../services/api/events-auth.api';
import Toaster from '../../../common/Toaster'

function ChefHostTab({event,refreshDetails,inActive}) {
  const [isCertified,setIsCertified]  = useState(false);
  const [isHostSame,setIsHostSame]  = useState(false);
  const [hostExp,setHostExp]= useState('');
  const [formValues, setFormValues] = useState({
      cookExperience: null,
      foodCertified: false,
      isChefHost: false
  })
  const [error, setError] = useState(false);
  const [certify] = useState([
      {label: 'Yes', value: true},
      {label: 'No', value: false},
  ])
  const handleInputChange = (name,val) => {
    if(name === 'foodCertified'){
        setIsCertified(val);
    }
    if(name === 'isChefHost'){
        setIsHostSame(val);
    }
    setFormValues(prevValues => ({
        ...prevValues,
        [name]: val
    }));
};
  const handleChefExp = (e) => {
      setFormValues(prevValues => ({
          ...prevValues,
          cookExperience: e.target.value
      }));
      formValues.cookExperience ? setError(false) : setError(true);
  };
  const nextSubmit = () => {
    if (!isHostSame && (formValues.cookExperience === null || formValues.cookExperience === '')) {
        setError(true)
        return;
    }
    const stepId = 4;
    updateEvent(formValues,stepId);
  }
  const updateEvent = async(formData,stepId) => {
    const transformedData = transformFormValues(formData)
    try{
      if(event?.id){
        const res = await createHostEventById(transformedData,event?.id,stepId);
      }
        Toaster('Updated successfully', 'success');
        refreshDetails();
      }
    catch(error){
        console.log(error)
    }
  }
  const transformFormValues = (formValues) => {
    return Object.keys(formValues).map((key) => ({
      operationType: "Add",
      path: `/${key}`,
      op: "replace",
      value: formValues[key],
    }));
  }
  useEffect(()=>{
    if(event){
        setIsCertified(event?.foodCertified || false);
        setIsHostSame(event?.isChefHost || false);
        setHostExp(event?.hostingExperience);
        setFormValues(prev => ({
            ...prev,
            cookExperience: event?.cookExperience || null,
            foodCertified: event?.foodCertified || false,
            isChefHost: event?.isChefHost || false,
        }))
    }
},[event])
  return (
    <>
        <div className="row">
            <div className="col-md-12 mx-auto">
                <div className="create-event-tab">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="mb-3">
                                <label className="form-label input-label">Is the chef officially certified ?</label>
                                <div className="radio-btn">
                                    {
                                        certify.map((item,index)=> (
                                            <div className="form-check" key={index}>
                                                <input className="form-check-input" 
                                                    type="radio"
                                                    disabled={inActive}
                                                    name="foodCertified"
                                                    onChange={()=> handleInputChange('foodCertified',item.value)}
                                                    value={item.value}
                                                    checked={isCertified === item.value}
                                                    id={`flexRadio${item.value}`} />
                                                <label className="form-check-label"
                                                    htmlFor={`flexRadio${item.value}`}>
                                                    {item.label}
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="mb-3">
                                <label className="form-label input-label">Is The Host Is Same As The Chef ?</label>
                                <div className="radio-btn">
                                    {
                                        certify.map((item,index)=> (
                                            <div className="form-check" key={index}>
                                                <input className="form-check-input" 
                                                    type="radio"
                                                    disabled={inActive}
                                                    name="isChefHost"
                                                    onChange={()=> handleInputChange('isChefHost',item.value)}
                                                    value={item.value}
                                                    checked={isHostSame === item.value}
                                                    id={`flexRadio${item.value}`} />
                                                <label className="form-check-label"
                                                    htmlFor={`flexRadio${item.value}`}>
                                                    {item.label}
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            !isHostSame && (
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label className="form-label input-label">Chef Experience
                                            <sup className="req">*</sup>
                                        </label>
                                        <textarea className="form-control textarea-ht"
                                                name="cookExperience"
                                                readOnly={inActive}
                                                onChange={handleChefExp}
                                                value={formValues.cookExperience}
                                                placeholder="Healthy and delicious French dishes made from high quality, seasonal, and fresh ingredients"
                                                id="floatingTextarea2">
                                        </textarea>
                                        {error && (<span className="form-error">Description is required</span>)}
                                    </div>
                                </div>
                            )
                        }
                        <div className="col-lg-12">
                            <div className="mb-3">
                                <label className="form-label input-label">Host Experience</label>
                                <textarea className="form-control textarea-ht"
                                            placeholder="I’m a professional host. I have hosted a similar experience before and I have received professional training."
                                            id="floatingTextarea2"
                                            readOnly
                                            value={hostExp}>
                                </textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
              <div className="col-md-12 mx-auto">
                <div className="submit-btn next-btn justify-content-end">
                  <div className="submit-btn next-btn text-end">
                    <div>
                      <button className="btn" type="submit" onClick={nextSubmit} disabled={inActive}>Save</button>
                    </div>
                  </div>
                </div>
              </div>
          </div>
    </>
  )
}

export default ChefHostTab