import { createSlice } from "@reduxjs/toolkit";

const tokenSlice = createSlice({
  name: "tokenRefresh",
  initialState: {
    value: false,
    googleLogin: false,
    facebookLogin: false,
    loginToken: "",
  },
  reducers: {
    setTokenRefresh: (state, action) => {
      state.value = action.payload;
    },
    setGoogleLogin: (state, action) => {
      state.googleLogin = action.payload;
    },
    setFacebookLogin: (state, action) => {
      state.facebookLogin = action.payload;
    },
    setLoginToken: (state, action) => {
      state.loginToken = action.payload;
    },
  },
});

export const { setTokenRefresh } = tokenSlice.actions;
export const { setGoogleLogin } = tokenSlice.actions;
export const { setFacebookLogin } = tokenSlice.actions;
export const { setLoginToken } = tokenSlice.actions;

export default tokenSlice.reducer;
