import React, { useRef, useState, useEffect } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import img3 from "../../../assets/image/photo.png";
import { v4 as uuidv4 } from "uuid";
import { FaRegTrashCan } from "react-icons/fa6";
import Toaster from "../../../common/Toaster";
import { getEventId, removeEventId } from "../../../util/auth";
import {
  uploadEventDoc,
  deleteEventDoc,
  getEventById,
} from "../../../services/api/events-auth.api";
import { transformImageUrl } from "../../../util/dataTransform";
import Loading from "../../../common/Loading";

function EventGallery({ nextHook, prevHook, eventInfoDetails }) {
  const docUploadRef = useRef(null);
  const [eventDocs, setEventDocs] = useState([]);
  const [storeDocs, setStoreDocs] = useState([]);
  const [loading, setLoading] = useState(false);
  const prev = () => {
    prevHook();
  };
  const next = () => {
    nextHook();
  };
  const navigate = useNavigate();
  const handleDocUpload = (event) => {
    event.preventDefault();
    docUploadRef.current.click();
  };
  const uploadDocDisplay = async () => {
    const files = Array.from(docUploadRef.current.files);
    const validFiles = [];
    const cachedURLs = [];

    for (const file of files) {
      if (file.size > 1024 * 1024) {
        Toaster("File size should not exceed 1 MB.", "error");
      } else {
        const fileData = {
          file: file,
          id: uuidv4(),
        };
        validFiles.push(fileData); // Add valid file to the array
        cachedURLs.push({
          url: URL.createObjectURL(file),
          type: "preview",
          id: fileData.id,
        });
      }
    }
    setEventDocs((prevState) => [...prevState, ...cachedURLs]);
    setStoreDocs((prevState) => [...prevState, ...validFiles]);
  };
  const handleUpload = async () => {
    if (eventDocs.length === 0) {
      Toaster(
        "Please upload at least one document before proceeding.",
        "warning"
      );
      return;
    }
    if (storeDocs.length > 0) {
      if (storeDocs.length < 0) {
        return Toaster("Upload minimum of 1 photo", "error");
      }
      if (storeDocs.length > 15) {
        return Toaster("Upload maximum of 15 photos", "error");
      }
      const formData = new FormData();
      storeDocs.forEach((data) => {
        formData.append(`eventDocuments`, data.file);
      });
      if (storeDocs.length > 1) {
        try {
          setLoading(true);
          const eventId = await getEventId();
          await uploadEventDoc(formData, eventId);
          Toaster("Photos uploaded successfully", "success");
          setLoading(false);
          navigate("/all-events");
          await removeEventId();
        } catch (error) {
          Toaster("Creation failed", "error");
        }
      } else {
        Toaster("Upload minimum of 2 photos", "error");
      }
    }
  };
  const removeDocument = async (doc) => {
    if (doc.type === "preview") {
      setEventDocs((prev) => prev.filter((item) => item.id !== doc.id));
      setStoreDocs((prev) => prev.filter((item) => item.id !== doc.id));
    } else if (doc.type === "existing") {
      deleteDocs(doc.id);
    }
  };

  const deleteDocs = async (id) => {
    try {
      await deleteEventDoc(id);
      setEventDocs((prev) => prev.filter((item) => item.id !== id));
      Toaster("Photo deleted successfully", "success");
    } catch (error) {
      Toaster("failed", "error");
    }
  };
  const getEventDetails = async () => {
    const eventId = await getEventId();
    try {
      const details = await getEventById(eventId);
      if (details) {
        const docUrl = details.eventDocuments.map((file) => ({
          url: transformImageUrl(file.eventDocumentPath),
          type: "existing",
          id: file.eventDocumentId,
        }));
        setEventDocs(docUrl);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getEventDetails();
  }, []);
  return (
    <div>
      <div id="pills-tab7">
        {/* <!-- tab7 --> */}
        <div className="row">
          <div className="col-md-12 mx-auto">
            <div className="create-event-tab check-facility">
              <p className="text-dark event-facility-title">Event documents</p>
              <div className="mt-3">
                <p>Add a minimum of 1 photos</p>
              </div>
              <button
                className="drag-image text-center"
                onClick={handleDocUpload}
                type="button"
              >
                <div className="blk-img">
                  <img src={img3} alt="meet-at-table" />
                </div>
                <div>
                  <p>click to upload or drag and drop a new file</p>
                </div>
              </button>
              <input
                type="file"
                ref={docUploadRef}
                onChange={uploadDocDisplay}
                hidden
                multiple
                id="event-photo-input"
                name="event_documents"
                accept="image/png, image/jpeg"
              />
              <div className="row g-3 mt-3">
                {eventDocs.map((doc, index) => (
                  <div className="col-md-3 position-relative" key={index}>
                    <div className="uploaded-img">
                      <img src={doc.url} alt={`Uploaded ${index}`} />
                    </div>
                    <div
                      className="trash-icon"
                      onClick={() => removeDocument(doc)}
                    >
                      <FaRegTrashCan />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mx-auto">
          <div className="submit-btn next-btn justify-content-end">
            <div className="submit-btn next-btn text-end">
              <div>
                <button className="prev-arrow me-3" onClick={prev}>
                  <FaArrowLeftLong />
                </button>
              </div>
              <div>
                <button className="btn" type="submit" onClick={handleUpload}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loading />}
    </div>
  );
}

export default EventGallery;
