import { createSlice } from '@reduxjs/toolkit';

const logoutSlice = createSlice({
  name: 'logoutRefresh',
  initialState: {
    value: false, 
  },
  reducers: {
    setLogout: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setLogout } = logoutSlice.actions;

export default logoutSlice.reducer;
