import { axiosInstance } from '../../axios';
import { SITE_INFO, COMMON } from '../../const/api-url.const'

export const getfaqs = async () => {
    return await axiosInstance.get(`${SITE_INFO.FAQS}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}

export const getaboutus = async () => {
    return await axiosInstance.get(`${SITE_INFO.ABOUT_US}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}

export const getHostPreference = async () => {
    return await axiosInstance.get(`${COMMON.GET_PREFERENCE}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}

export const getQualityExperience = async () => {
    return await axiosInstance.get(`${COMMON.GET_QUALITY_EXPERIENCE}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}

export const getExperienceType = async () => {
    return await axiosInstance.get(`${COMMON.GET_EXPERIENCE_TYPE}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}

export const getAmbiance = async () => {
    return await axiosInstance.get(`${COMMON.GET_AMBIANCE}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const getWowFactor = async () => {
    return await axiosInstance.get(`${COMMON.GET_WOW_FACTOR}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const getCountries = async () => {
    return await axiosInstance.get(`${COMMON.GET_COUNTRY}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const getLanguage = async () => {
    return await axiosInstance.get(`${COMMON.GET_LANGUAGE}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const getCurrencies = async () => {
    return await axiosInstance.get(`${COMMON.GET_CURRENCIES}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const getTermsConditions = async () => {
    return await axiosInstance.get(`${COMMON.GET_TERMS_AND_CONDITIONS}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const getPrivacyPolicy = async () => {
    return await axiosInstance.get(`${COMMON.GET_PRIVACY_POLICY}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const getHowItsWork = async () => {
    return await axiosInstance.get(`${COMMON.GET_HOW_IT_WORKS}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const getCuisines = async () => {
    return await axiosInstance.get(`${COMMON.GET_CUISINES}`).then(({ data }) => {
        return data;
    }, error => {
        return new Promise((resolve, reject) => {
            reject(error);
        })
    })
}
export const getFooterContent = async () => {
    return await axiosInstance.get(`${SITE_INFO.FOOTER_CONTENT}`).then(
        ({ data }) => {
            return data;
        },
        (error) => {
            return new Promise((resolve, reject) => {
                reject(error);
            });
        }
    );
};

