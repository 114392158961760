import React, { useEffect, useState } from "react";
import "../howItWorks/HowItWorks.scss";
import banner from "../../../assets/image/new-banner-video.mp4";
import img3 from "../../../assets/image/login-pattern2.png";
import { getHowItsWork } from "../../../services/api/site-info.api";

function HowItWorks() {
  const [content, setContent] = useState("");
  const [guestContent, setGuestContent] = useState("");
  const [hostContent, setHostContent] = useState("");

  const getData = async () => {
    try {
      const res = await getHowItsWork();
      setContent(res);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content.text, "text/html");
    const element = doc.querySelector(".guest");
    const element1 = doc.querySelector(".host");
    if (element) {
      setGuestContent(element.outerHTML);
    }
    if (element1) {
      setHostContent(element1.outerHTML);
    }
  }, [content]);
  return (
    <>
      <div className="howitworks-wrapper">
        <div className="breadcrumb-image">
          <video autoPlay muted loop>
            <source src={banner} type="video/mp4" />
          </video>
          <div className="breadcrumb-overlay"></div>
          <div className="breadcrumb-title">
            <div className="breadcrumb-header">
              <h4>How It Works</h4>
            </div>
            <div>
              <p dangerouslySetInnerHTML={{ __html: content?.subTitle }}></p>
            </div>
          </div>
        </div>
        <div className="pattern-row">
          <div className="col-12">
            <div className="pattern-2">
              <img src={img3} alt="meet-at-table" />
            </div>
          </div>
        </div>
        <div className="position-relative overflow-hidden p-s howitworks-bg">
          <div className="howitworks-bg-overlay"></div>
          <div className="howitworks-bg-content">
            <div className="shape-1"></div>
            <div className="container">
              <div className="row mt-4">
                <div className="col-12 py-5 guest-show">
                  <h2>As a Guest</h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: guestContent }}
                    className="mb-0"
                  ></div>
                </div>
                <div className="col-12 py-5 host-show">
                  <h2>As a Host</h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: hostContent }}
                    className="mb-0"
                  ></div>
                </div>
              </div>
            </div>
            <div className="shapefaq"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowItWorks;
