import { axiosInstance } from "../../axios";
import {
  AUTH,
  USER,
  COMMON,
  HOST,
  EVENTS,
  HOSTS,
} from "../../const/api-url.const";

export const login = async (data) => {
  return await axiosInstance
    .post(`${AUTH.LOG_IN}`, data, { skipAuth: true })
    .then(
      ({ data }) => {
        return data;
      },
      (error) => {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    );
};
export const registerForm = async (data) => {
  return await axiosInstance.post(`${AUTH.REGISTER}`, data).then(
    ({ data }) => {
      return data;
    },
    (error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  );
};
export const validPassword = async (email) => {
  return await axiosInstance.post(`${AUTH.VALID_PASSWORD}?email=${email}`).then(
    ({ data }) => {
      return data;
    },
    (error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  );
};
export const resetPassword = async (email) => {
  return await axiosInstance.post(`${AUTH.RESET_PASSWORD}?email=${email}`).then(
    ({ data }) => {
      return data;
    },
    (error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  );
};
export const updatePassword = async (data) => {
  return await axiosInstance
    .post(
      `${AUTH.UPDATE_PASSWORD}?newPassword=${data.newPassword}&confirmPassword=${data.confirmPassword}&token=${data.token}`
    )
    .then(
      ({ data }) => {
        return data;
      },
      (error) => {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    );
};

export const changePassword = async (data) => {
  return await axiosInstance
    .post(
      `${AUTH.CHANGE_PASSWORD}?currentPassword=${data.currentPassword}&newPassword=${data.newPassword}&confirmPassword=${data.confirmPassword}`
    )
    .then(
      ({ data }) => {
        return data;
      },
      (error) => {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    );
};

export const getProfile = async () => {
  return await axiosInstance.get(`${USER.GET_PROFILE}`).then(
    ({ data }) => {
      return data;
    },
    (error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  );
};
export const updateProfile = async (data) => {
  return await axiosInstance.put(`${USER.UPDATE_PROFILE}`, data).then(
    ({ data }) => {
      return data;
    },
    (error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  );
};

export const getSpeakLanguages = async () => {
  return await axiosInstance.get(`${COMMON.GET_SPEACK_LANGUAGE}`).then(
    ({ data }) => {
      return data;
    },
    (error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  );
};
export const createHost = async (formData, stepId) => {
  return await axiosInstance
    .patch(`${HOST.PATCH}?stepId=${stepId}`, formData)
    .then(
      ({ data }) => {
        return data;
      },
      (error) => {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    );
};
export const patchHost = async (formData, stepId, hostId) => {
  return await axiosInstance
    .patch(`${HOST.PATCH}?id=${hostId}&stepId=${stepId}`, formData)
    .then(
      ({ data }) => {
        return data;
      },
      (error) => {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    );
};
export const becomeHost = async (formData, stepId) => {
  return await axiosInstance
    .post(`${HOST.BECOME_HOST}?stepId=${stepId}`, formData)
    .then(
      ({ data }) => {
        return data;
      },
      (error) => {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    );
};

export const getHostDetails = async () => {
  return await axiosInstance.get(`${HOST.GET_HOST}`).then(
    ({ data }) => {
      return data;
    },
    (error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  );
};
export const uploadHostDoc = async (data, stepId) => {
  return await axiosInstance
    .put(`${HOST.HOST_UPLOAD}?stepId=${stepId}`, data)
    .then(
      ({ data }) => {
        return data;
      },
      (error) => {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    );
};
export const uploadProfilePicture = async (data) => {
  return await axiosInstance.put(`${USER.PROFILE_PICTURE_UPLOAD}`, data).then(
    ({ data }) => {
      return data;
    },
    (error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  );
};

export const getAllPlans = async (data) => {
  return await axiosInstance.get(`${USER.MEMBERSHIP_PLANS}`, data).then(
    ({ data }) => {
      return data;
    },
    (error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  );
};

export const updateHostPlan = async (hostId, membershipId) => {
  return await axiosInstance
    .post(
      `${EVENTS.MEMBERSHIP_PLANS}?hostId=${hostId}&membershipId=${membershipId}`
    )
    .then(
      ({ data }) => {
        return data;
      },
      (error) => {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    );
};

export const deleteHostDoc = async (docId) => {
  return await axiosInstance.delete(`${HOST.DELETE_HOST_DOC}?id=${docId}`).then(
    ({ data }) => {
      return data;
    },
    (error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  );
};
export const getUserByToken = async () => {
  return await axiosInstance.get(`${USER.USER_BY_TOKEN}`).then(
    ({ data }) => {
      return data;
    },
    (error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  );
};
export const getWishlist = async () => {
  return await axiosInstance.get(`${USER.USER_WISHLIST}`).then(
    ({ data }) => {
      return data;
    },
    (error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  );
};
export const addWishList = async (data) => {
  return await axiosInstance.post(`${USER.ADD_WISHLIST}`, data).then(
    ({ data }) => {
      return data;
    },
    (error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  );
};
export const removeEventWishList = async (data) => {
  return await axiosInstance.post(`${USER.REMOVE_EVENT_WISHLIST}`, data).then(
    ({ data }) => {
      return data;
    },
    (error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  );
};
export const removeWishlist = async (id) => {
  return await axiosInstance.delete(`${USER.REMOVE_WISHLIST}?id=${id}`).then(
    ({ data }) => {
      return data;
    },
    (error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  );
};
export const getAllNotification = async (params, userId) => {
  const queryString = new URLSearchParams(params).toString();
  return await axiosInstance
    .get(
      `${USER.GET_NOTIFICATION_LIST}?userId
=${userId}`
    )
    .then(
      ({ data }) => {
        return data;
      },
      (error) => {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    );
};
export const deleteUserProfile = async (password) => {
  return await axiosInstance
    .delete(`${AUTH.DELETE_USER_PROFILE}?password=${password}`)
    .then(
      ({ data }) => {
        return data;
      },
      (error) => {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    );
};

export const getGoogleAuth = async (email, name) => {
  return await axiosInstance
    .get(`${USER.GOOGLEAUTH}?email=${email}&userName=${name}`)
    .then(
      ({ data }) => {
        return data;
      },
      (error) => {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    );
};
export const googleProfileDetails = async (data) => {
  return await axiosInstance.post(`${USER.SAVE_GOOGLE_PROFILE}`, data).then(
    ({ data }) => {
      return data;
    },
    (error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  );
};
export const getCheckProfileStatus = async () => {
  return await axiosInstance.get(`${USER.CHECK_USER_PROFILE}`).then(
    ({ data }) => {
      return data;
    },
    (error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  );
};

export const refreshhostform = async (hostId) => {
  return await axiosInstance
    .post(`${HOST.REFRESH_HOST_FORM}?hostId=${hostId}`)
    .then(
      ({ data }) => {
        return data;
      },
      (error) => {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    );
};
export const acceptedtermsandconditions = async (data) => {
  return await axiosInstance.post(`${USER.ACCEPT_TERMS_CONDITIONS}`, data).then(
    ({ data }) => {
      return data;
    },
    (error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  );
};

export const sendNotification = async (data) => {
  return await axiosInstance.post(`${USER.SEND_NOTIFICATION}`).then(
    ({ data }) => {
      return data;
    },
    (error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  );
};
