import { Outlet } from 'react-router-dom';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './shared/header/Header'
import Footer from './shared/footer/Footer'
import { useEffect, useState, useRef, useContext } from 'react';
import { getAccessToken } from './util/auth';
import { useLocation, useNavigate } from "react-router-dom";
import { setupAxiosInterceptors } from './axios';
import chatIcon from './assets/image/chatbox-icon.png'
import { collection, onSnapshot } from 'firebase/firestore';
import { onMessage } from 'firebase/messaging';
import { AuthContext } from "./context/AuthContext";
import { db, messaging } from "./firebase";
import { USER, CHAT } from './const/route-url.const'

function App() {
  const Hide_Header = ['forgot-password', 'reset-password', 'success-activation-request', 'failed-activation-request']
  const [token, setToken] = useState();
  const [show, setShow] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const [unreadCount, setUnreadCount] = useState(0);
  const processedMessagesRef = useRef(new Set());
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let path = location.pathname.replace(/^\//, '');
    setShow(Hide_Header.includes(path));
  }, [location]);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'chats'), (snapshot) => {
      const newProcessedMessages = new Set(processedMessagesRef.current);

      snapshot.docs.forEach((doc) => {
        const chatData = doc.data();
        const messages = chatData?.messages;

        messages.forEach((message) => {
          if (message.senderId !== currentUser.uid && !newProcessedMessages.has(message.id)) {
            newProcessedMessages.add(message?.id);
            setUnreadCount(prevCount => prevCount + 1);
          }
        });
      });

      processedMessagesRef.current = newProcessedMessages;
    });

    const handleNewMessage = (payload) => {
      const senderId = payload?.data?.senderId || payload?.senderId;
      const messageId = payload?.data?.messageId || payload?.messageId;

      if (senderId && senderId !== currentUser.uid && !processedMessagesRef.current.has(messageId)) {
        processedMessagesRef.current.add(messageId);
        setUnreadCount(prevCount => prevCount + 1);
      }
    };

    onMessage(messaging, handleNewMessage);

    return () => {
      unsubscribe();
    };
  }, [currentUser?.uid]);

  const resetUnreadCount = () => {
    setUnreadCount(0);
    navigate(`${USER}/${CHAT}`)
  };

  return (
    <>
      <div className="app">
        {!show &&
          <div className="banner-sec position-relative">
            <Header />
          </div>
        }
        <Outlet />
        {!show &&
          <Footer />
        }
      </div>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {(unreadCount > 0 && !!getAccessToken()) && (<div className='chatbox-icon' onClick={resetUnreadCount}>
        <img src={chatIcon} alt='loading' />
        <div>
          <span className="unreadCount">{unreadCount}</span>
        </div>
      </div>)}
    </>

  );
}

export default App;
